@charset "UTF-8";

.data {
  .intro {
    margin-bottom: 75px;
    @include sp {
      margin-bottom: 40px;
    }
  }
  .data-area {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .data-block {
    width: 400px;
    margin-bottom: 75px;
    @include sp {
      margin-bottom: 40px;
    }
    @include pc {
      &:nth-child(7),
      &:nth-child(8) {
        margin-bottom: 0;
      }
    }
    @include sp {
      &:last-child {
        margin-bottom: 0;
      }
    }
    &__img {
      margin-bottom: 30px;
      @include sp {
        margin-bottom: 25px;
        img {
          width: 100%;
        }
      }
    }
    &__text {
      font-size: 16px;
      line-height: 1.6;
    }
  }
}
