@charset "UTF-8";

body {
  position: relative;
  min-width: 1024px;
  text-align: justify;
  text-justify: inter-ideograph;
  height:100%;
  @include sp {
    min-width: inherit;
    width: 100%;
    &.is-fixed {
      position: fixed;
    }
  }
}

.pc-on {
  display: block;
  @include sp {
    display: none;
  }
}
.sp-on {
  display: none;
  @include sp {
    display: block;
  }
}

.f-raleway {
  font-family: 'Raleway', sans-serif;
}
.f-lato {
  font-family: 'Lato', sans-serif;
}

a {
  &:hover {
    text-decoration: underline;
    img {
      opacity: .7;
    }
  }
}

.btn {
  position: relative;
  display: inline-block;
  font-size: 12px;
  padding: 11px 47px 9px 30px;
  box-sizing: border-box;
  letter-spacing: 0.1em;
  &::before {
    content: "";
    position: absolute;
    top: 5px;
    bottom: 0;
    right: 10px;
    margin: auto;
    display: block;
    width: 22px;
    height: 1px;
  }
  &::after {
    content: "";
    position: absolute;
    top: -5px;
    bottom: 0;
    right: 10px;
    margin: auto;
    display: block;
    width: 6px;
    height: 1px;
    transform: rotate(45deg);
    transform-origin: 0;
  }
  &--white {
    color: #fff;
    border: solid 1px #fff;
    &::before,
    &::after {
      background: #fff;
    }
  }
  &--blue {
    color: #006098;
    border: solid 1px #006098;
    &::before,
    &::after {
      background: #006098;
    }
  }
  &--light-blue {
    color: #00b9ef;
    border: solid 1px #00b9ef;
    &::before,
    &::after {
      background: #00b9ef;
    }
  }
  &--blank {
    min-width: 250px;
    font-size: 15px;
    text-align: center;
    @include sp {
      min-width: 168px;
      font-size: 12px;
    }
  }
  &:hover {
    opacity: .7;
    text-decoration: none;
  }
}

// ===========================
// btn-big-wrap
// ------------------------------
.btn-big-wrap {
  padding-top: 50px;
  @include sp {
    padding-top: 25px;
  }
}
.btn-big {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 60%;
  max-width: 600px;
  height: 90px;
  padding: 0;
  vertical-align: middle;
  margin: 0 auto 50px;
  background: #00b9ef;
  border-radius: 8px;
  font-size: 27px;
  color: #fff;
  letter-spacing: 0;
  line-height: 1.4;
  box-shadow:0px 2px 4px rgba(102,102,102,0.4);
  @include sp {
    width: 295px;
    height: 45px;
    font-size: 16px;
    border-radius: 4px;
    margin: 0 auto 25px;
  }
  span {
    font-size: 20px;
    @include sp {
      font-size: 12px;
    }
  }
  @include pc {
    &:hover {
      opacity: .7;
      text-decoration: none;
    }
  }
}

// ===========================
// lower
// ------------------------------

.main {
  position: relative;
}
.bg-gray {
  background: #eee;
}
.bg-blue {
  background: #edf6fc;
}
.low-wrap {
  width: 860px;
  margin: 0 auto;
  @include sp {
    width: calc(100% - 40px);
  }
}

// ===========================
// header
// ------------------------------
.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 999;
  background: #fff;
  box-sizing: border-box;
  @include sp {
    height: 55px;
  }
  &__logo {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 30px;
    margin: auto;
    display: flex;
    align-items: center;
    width: 140px;
    img {
      width: 100%;
    }
    @include sp {
      width: 80px;
      img {
        width: 100%;
      }
    }
    a {
      display: block;
    }
  }
  .menu {
    position: absolute;
    width: 100px;
    right: 10px;
    top: 2px;
    &__ttl {
      font-weight: bold;
      font-size: 11px;
      letter-spacing: .4em;
      color: #006da6;
      line-height: 50px;
    }
    &__trigger {
      @include menu-trigger(transparant,0,50px);
      right: 0;
      top: 0;
      span:nth-child(1) {
        display: none;
      }
      span:nth-child(3) {
        transform: rotate(90deg);
        bottom: 24px;
      }
    }
  }
  &.active {
    .menu {
      &__trigger {
        span:nth-child(3) {
          opacity: 0;
        }
      }
    }
  }
  &__inner {
    @include sp {
      display: none;
      position: absolute;
      top: 55px;
      left: 0;
      width: 100%;
      &.active {
        background: rgba(255,255,255,0.5);
        display: block;
        overflow: scroll;
      }
    }
  }
  &__link {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 20px 0 15px;
    @include sp {
      display: none;
    }
    &__btn,
    &__sns {
      display: flex;
      align-items: center;
    }
    &__btn {
      margin-right: 5px;
      .btn {
        margin: 0 15px;
        padding: 7px 13px;
        border: solid 1px #00b9ef;
        border-radius: 4px;
        color: #00b9ef;
        letter-spacing: 0;
        &::before,
        &::after {
          display: none;
        }
      }
    }
    &__sns {
      margin-right: 30px;
      a {
        display: block;
        padding: 10px;
      }
    }
  }
  &__nav {
    position: relative;
    display: flex;
    justify-content: flex-end;
    width: 100%;
    padding: 0 1% 0 0;
    box-sizing: border-box;
    font-size: 12px;
    @include sp {
      flex-direction: column;
      padding: 0;
      font-size: 14px;
    }
    .cate {
      width: 110px;
      line-height: 40px;
      text-align: center;
      @include sp {
        width: 100%;
        background: #00b9ef;
        font-weight: bold;
        color: #fff;
        line-height: 45px;
        border-bottom: solid 1px #fff;
        position: relative;
        &::before,
        &::after {
          position: absolute;
          content: "";
          width: 6px;
          height: 1px;
          background: #fff;
        }
        &::before {
          transform: rotate(45deg);
          right: 31px;
          top: 20px;
        }
        &::after {
          transform: rotate(-45deg);
          right: 31px;
          bottom: 20px;
        }
        a {
          color: #fff;
        }
        &.ac__ttl {
          position: relative;
          &::before,
          &::after {
            position: absolute;
            content: "";
            width: 12px;
            height: 1px;
            background: #fff;
          }
          &::before {
            transform: rotate(0deg);
            right: 28px;
            top: 22px;
          }
          &::after {
            transform: rotate(90deg);
            right: 28px;
            top: 22px;
          }
        }
      }
      @include pc {
        &:hover {
          background: #00b9ef;
          color: #fff;
          a {
            color: #fff;
          }
        }
      }
    }
    @include pc {
      .ac__ttl {
        &:hover {
          .ac__detail {
            display: block;
          }
        }
      }
    }
    .ac__detail {
      display: none;
      position: absolute;
      top: 40px;
      left: 0;
      width: 100%;
      height: 100px;
      padding: 10px 15%;
      box-sizing: border-box;
      background: #00b9ef;
      color: #fff;
      z-index: 30;
      @include sp {
        padding: 0;
        position: static;
        height: auto;
      }
      ul {
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
        width: 670px;
        margin: 0 auto;
        @include sp {
          flex-direction: column;
          width: 100%;
          text-align: center;
        }
        li {
          margin-right: 70px;
          text-align: left;
          @include pc {
            &.length4 {
              width: 4em;
            }
            &.length5 {
              width: 5em;
            }
            &.length6 {
              width: 6em;
            }
            &.length7 {
              width: 7em;
            }
            &.length10 {
              width: 10em;
            }
            &.length16 {
              width: 16em;
            }
          }
          @include sp {
            margin: 0 0 1px;
            background: #dff2fc;
            text-align: center;
            a {
              color: #444;
              display: block;
            }
          }
        }
      }
      a {
        color: #fff;
      }
    }
  }
}
.lower {
  .header {
    box-shadow: 0 2px 4px rgba(102, 102, 102, 0.2);
  }
}

// ===========================
// pankuzu
// ------------------------------
.pankuzu {
  width: 700px;
  margin: 0 auto;
  display: flex;
  padding: 20px 0 30px;
  li {
    font-size: 12px;
    a {
      color: #00b9ef;
      text-decoration: underline;
    }
    &::after {
      content: ">";
      display: inline-block;
      margin: 0 1em;
      color: #999;
    }
    &:last-child {
      &::after {
        display: none;
      }
    }
  }
  @include sp {
    display: none;
  }
}

// ===========================
// lower kv
// ------------------------------
.lower {
  .kv {
    position: relative;
    margin-bottom: 50px;
    @include sp {
      margin-bottom: 25px;
    }
    &__img {
      width: 100%;
      display: block;
    }
    &__ttl {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      margin: auto;
      text-align: center;
      height: 1em;
      letter-spacing: 0.25em;
      font-size: 45px;
      color: #fff;
      &--2 {
        line-height: 1.3;
        height: 2.6em;
      }
      @include sp {
        font-size: 25px;
      }
      &--black {
        color: #444;
      }
    }
  }
}

// ===========================
// lower intro
// ------------------------------
.lower {
  .intro {
    width: 860px;
    margin: 0 auto 45px;
    @include sp {
      width: calc(100% - 40px);
      margin: 0 auto 20px;
    }
    &__ttl {
      margin-bottom: 30px;
      font-size: 30px;
      color: #00b9ef;
      line-height: 1.5;
      font-feature-settings : "palt";
      @include sp {
        margin-bottom: 25px;
        font-size: 20px;
      }
    }
    &__text {
      font-size: 18px;
      line-height: 1.6;
      @include sp {
        font-size: 16px;
      }
    }
  }
}

// ===========================
// service-link
// ------------------------------
.service-link {
  padding: 50px 0;
  box-sizing: border-box;
  text-align: right;
  @include sp {
    padding: 25px 0;
  }
  &--mb {
    margin-bottom: 30px;
  }
  &--pt0 {
    padding-top: 0px;
  }
  &--pt10 {
    @include pc {
      padding-top: 10px;
    }
  }
  &--pb30 {
    @include pc {
      padding-bottom: 30px;
    }
  }
}

// ===========================
// lower subnav
// ------------------------------
.subnav {
  width: 700px;
  padding: 50px 0 10px;
  margin: 0 auto;
  @include sp {
    width: calc(100% - 40px);
    padding: 25px 0 10px;
  }
  &__inner {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  &__block {
    width: 320px;
    margin-bottom: 40px;
    @include sp {
      width: 48%;
      margin-bottom: 25px;
    }
    &__ttl {
      margin-bottom: 10px;
      text-align: center;
      background: #fff;
      font-size: 14px;
      font-weight: bold;
      line-height: 40px;
      color: #666;
      letter-spacing: 0.05em;
      @include sp {
        line-height: 25px;
        font-size: 11px;
      }
    }
    &__img {
      margin-bottom: 20px;
      @include sp {
        margin-bottom: 10px;
      }
      img {
        width: 100%;
      }
    }
    &__text {
      text-align: left;
      .list {
        display: inline-block;
        @include sp {
          font-size: 11px;
        }
        &::after {
          content: "|";
          display: inline-block;
          margin: 0 1em;
        }
        &:last-child {
          &::after {
            display: none;
          }
        }
        a {
          line-height: 2;
        }
      }
    }
  }
}

// ===========================
// footer
// ------------------------------
.pagetop-area {
  @include sp {
    width: 100%;
    position: fixed;
    bottom: 0;
    background: #ccc;
    padding: 5px 0;
    display: flex;
    z-index: 30;
  }
}
.btn-recruit {
  @include pc {
    display: none;
  }
  @include sp {
    width: calc(100% - 105px);
    background: #00b9ef;
    font-size: 20px;
    text-align: center;
    border-radius: 3px;
    line-height: 50px;
    margin: 0 15px 0 20px;
    a {
      display: block;
      color: #fff;
      text-decoration: none;
      &:hover {
        text-decoration: none;
      }
    }
  }
}
.pagetop {
  right: 20px;
  z-index: 30;
  width: 40px;
  height: 40px;
  background: #006da6;
  img {
    display: block;
    width: 100%;
  }
  @include sp {
    width: 50px;
    height: 50px;
    background: none;
  }
}
.footer {
  width: 100%;
  &__link {
    background: #eeeeee;
    &__sns {
      display: flex;
      width: 830px;
      margin: 0 auto;
      padding-bottom: 20px;
      background: #eeeeee;
      @include sp {
        padding: 5px 0 60px;
        width: 100%;
        background: #fff;
        justify-content: center;
      }
      a {
        display: block;
        padding: 10px;
        @include sp {
          padding: 14px;
        }
      }
    }
  }
  &__nav {
    width: 830px;
    margin: 0 auto;
    padding: 50px 0 30px;
    display: flex;
    justify-content: space-between;
    font-size: 12px;
    line-height: 30px;
    color: #666;
    background: #eeeeee;
    @include sp {
      width: 100%;
      flex-direction: column;
      padding: 0;
      text-align: center;
      font-size: 14px;
    }
    .cate {
      margin-bottom: 10px;
      font-weight: bold;
      @include sp {
        width: 100%;
        background: #ddd;
        font-weight: bold;
        color: #444;
        line-height: 45px;
        border-bottom: solid 1px #fff;
        position: relative;
        margin-bottom: 0;
        &::before,
        &::after {
          position: absolute;
          content: "";
          width: 6px;
          height: 1px;
          background: #aaa;
        }
        &::before {
          transform: rotate(45deg);
          right: 31px;
          top: 20px;
        }
        &::after {
          transform: rotate(-45deg);
          right: 31px;
          bottom: 20px;
        }
        a {
          color: #444;
        }
        &.ac__ttl {
          position: relative;
          &::before,
          &::after {
            position: absolute;
            content: "";
            width: 12px;
            height: 1px;
            background: #aaa;
          }
          &::before {
            transform: rotate(0deg);
            right: 28px;
            top: 22px;
          }
          &::after {
            transform: rotate(90deg);
            right: 28px;
            top: 22px;
          }
        }
      }
    }
    .ac__detail {
      font-weight: normal;
      @include sp {
        display: none;
        background: #eeeeee;
        li {
          border-bottom: solid 1px #fff;
          a {
            display: block;
          }
        }
      }
    }
    a {
      color: #666;
    }
  }
  &__bottom {
    @include sp {
      padding-top: 20px;
    }
  }
  .policy {
    font-size: 12px;
    padding: 30px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    @include sp {
      padding: 0 0 20px;
      flex-wrap: wrap;
      line-height: 2;
      font-size: 11px;
    }
    li {
      &::after {
        content: "|";
        display: inline-block;
        margin: 0 1em;
      }
      &:last-child {
        &::after {
          display: none;
        }
      }
    }
    a {
      color: #666;
    }
  }
  .copy {
    text-align: center;
    padding: 30px 0;
    font-size: 10px;
    background: #006194;
    color: #fff;
    @include sp {
      padding: 18px 0;
    }
  }
}

