@charset "UTF-8";

.seminar {
  text-align: left;
  .kv {
    width: 100%;
    height: 150px;
    background: #006098;
  }
  .sec {
    margin-bottom: 50px;
    @include sp {
      margin-bottom: 25px;
    }
  }
  .pd-wrap {
    padding: 50px 0;
    @include sp {
      padding: 25px 0;
    }
  }
  .mainInner {
    border-bottom: solid 1px #ccc;
    a {
      color: #00b9ef;
    }
    p {
      margin-bottom: 30px;
      font-size: 16px;
      line-height: 1.6;
      @include sp {
        margin-bottom: 20px;
        font-size: 14px;
      }
    }
  }
  .fs-big {
    font-size: 18px;
    @include sp {
      font-size: 16px;
    }
  }
  .fw-bold {
    font-weight: bold;
  }
  .ta-center {
    text-align: center;
  }
  .txtNrml {
    font-size: 12px;
  }
  .imgBox {
    img {
      display: block;
      width: 100%;
    }
  }
  .ttlCopy02 {
    margin-bottom: 30px;
    font-size: 24px;
    line-height: 1.4;
    color: #333;
    padding-left: 19px;
    box-sizing: border-box;
    border-left: solid 6px #00b9ef;
    @include sp {
      margin-bottom: 25px;
      font-size: 16px;
      padding-left: 10px;
      border-left: solid 3px #00b9ef;
    }
  }
  .boderwrapBlue {
    p {
      &:last-child {
        margin-bottom: 0;
      }
    }
    .imgBox {
      img {
        display: block;
        max-width: 600px;
        margin: 20px auto;
      }
    }
  }
  .txtStrngBlue {
    margin-bottom: 30px;
    padding: 10px 20px;
    color: #fff;
    font-size: 18px;
    background: #00b9ef;
    @include sp {
      margin-bottom: 20px;
      font-size: 14px;
    }
    &--tag {
      display: inline-block;
      margin: 0 10px 0 0;
      padding: 5px 10px;
      font-size: 14px;
      @include sp {
        font-size: 12px;
      }
    }
  }
  .flexbox {
    margin-bottom: 30px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    @include sp {
      flex-direction: column;
    }
    .list {
      @include sp {
        margin-bottom: 20px;
      }
      li {
        position: relative;
        padding-left: 1em;
        font-size: 16px;
        margin-bottom: 20px;
        line-height: 1.6;
        @include sp {
          margin-bottom: 10px;
          font-size: 14px;
        }
        &::before {
          position: absolute;
          content: "";
          width: 10px;
          height: 10px;
          left: 0;
          top: 6px;
          display: inline-block;
          background-color: #00b9ef;
          border-radius: 50%;
        }
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
    .imgBox {
      width: 180px;
      padding: 0 20px;
      box-sizing: border-box;
      @include sp {
        margin: 0 auto;
      }
      img {
        width: 100%;
      }
    }
  }
  .lineTable01 {
    width: 100%;
    background: #fff;
    margin-bottom: 20px;
    &.end {
      position: relative;
      &::after {
        display: flex;
        align-items: center;
        justify-content: center;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        position: absolute;
        content: "終了しました。";
        color: #fff;
        background: rgba(0, 0, 0, 0.5);
        font-size: 20px;
      }
    }
    &__ttl {
      padding: 30px;
      font-size: 20px;
      font-weight: bold;
      border-bottom: solid 1px #ccc;
      vertical-align: middle;
      @include sp {
        display: block;
        padding: 20px 20px 0;
        border-bottom: none;
        font-size: 14px;
      }
    }
    &__text {
      padding: 20px 20px 20px 0;
      font-size: 16px;
      line-height: 1.6;
      border-bottom: solid 1px #ccc;
      vertical-align: middle;
      @include sp {
        display: block;
        padding: 20px;
        font-size: 14px;
      }
    }
  }
  .contactBtn {
    a {
      -webkit-appearance: none;
      display: block;
      border-radius: 3px;
      border: none;
      background-color: #ff3574;
      box-shadow: 0 3px #d02d60;
      width: 40%;
      height: auto;
      padding: 10px 0;
      margin: 0 auto;
      font-size: 115%;
      color: #fff;
      font-weight: bold;
      text-align: center;
      letter-spacing: 0.05em;
      cursor: pointer;
      @include sp {
        width: 80%;
      }
    }
  }
}


