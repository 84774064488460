@charset "UTF-8";

.history {
  .kv {
    margin-bottom: 0;
  }
  .slide-area {
    padding: 50px 0 120px;
    width: 960px;
    margin: 0 auto 90px;
    @include sp {
      padding: 25px 0 60px;
      width: calc(100% - 40px);
      margin: 0 auto 25px;
    }
    &__ttl {
      margin-bottom: 15px;
      font-size: 24px;
      font-weight: bold;
      color: #676767;
      letter-spacing: 0.2em;
      @include sp {
        font-size: 12px;
      }
    }
    &__text {
      margin-bottom: 35px;
      font-size: 30px;
      font-weight: 600;
      color: #00b9ef;
      letter-spacing: 0.1em;
      @include sp {
        margin-bottom: 25px;
        font-size: 20px;
      }
    }
  }
  .slides {
    width: 820px;
    margin: 0 auto;
    @include sp {
      width: 100%;
    }
    img {
      width: 100%;
    }
  }
  .slick-prev.slick-disabled,
  .slick-prev.slick-disabled:before,
  .slick-next.slick-disabled,
  .slick-next.slick-disabled:before {
    display: none !important;
  }
  .slick-prev,
  .slick-next {
    width: 32px;
    height: 32px;
    z-index: 10;
  }
  .slick-next {
    right: -55px;
    @include sp {
      right: -10px;
    }
  }
  .slick-prev {
    left: -55px;
    @include sp {
      left: -10px;
    }
  }
  .slick-prev:before {
    content: "";
    display: block;
    width: 16px;
    height: 16px;
    border-right: solid 2px #676767;
    border-bottom: solid 2px #676767;
    transform: rotate(135deg);
    right: 0;
    left: 0;
    margin: auto;
    @include sp {
      width: 8px;
      height: 8px;
    }
  }
  .slick-next:before {
    content: "";
    display: block;
    width: 16px;
    height: 16px;
    border-right: solid 2px #676767;
    border-bottom: solid 2px #676767;
    transform: rotate(-45deg);
    right: 0;
    left: 0;
    margin: auto;
    @include sp {
      width: 8px;
      height: 8px;
    }
  }
  .slick-dots {
    bottom: -70px;
    @include sp {
      bottom: -50px;
    }
  }
  .slick-dots li {
    margin: 0 50px;
    @include sp {
      margin: 0 20px;
    }
  }
  .slick-dots li button:before {
    font-size: 20px;
    @include sp {
      font-size: 10px;
    }
  }
  .slick-dots li.slick-active button:before {
    opacity: 1;
    color: #00b9ef;
  }
  .slick-dots li button:before {
    opacity: 1;
    color: #dcdddd;
  }
  .intro {
    width: 700px;
    margin: 0 auto 50px;
    @include sp {
      width: calc(100% - 40px);
      margin: 0 auto 5px;
    }
  }
  .text-area {
    padding: 35px 0 10px;
    width: 700px;
    margin: 0 auto;
    box-sizing: border-box;
    @include sp {
      width: calc(100% - 40px);
    }
    &__block {
      margin-bottom: 50px;
      border-bottom: solid 1px #bfbfbf;
      @include sp {
        margin-bottom: 40px;
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
    &__ttl {
      padding-left: 18px;
      box-sizing: border-box;
      margin-bottom: 30px;
      font-size: 24px;
      font-weight: bold;
      color: #00b9ef;
      line-height: 1.4;
      border-left: solid 4px #b5b5b5;
      @include sp {
        margin-bottom: 25px;
        padding-left: 14px;
        font-size: 16px;
      }
    }
    &__text {
      margin-bottom: 20px;
      max-height: 4.8em;
      overflow: hidden;
      @include sp {
        margin-bottom: 5px;
      }
      &.active {
        max-height: inherit;
        overflow: auto;
      }
      p {
        margin-bottom: 20px;
        font-size: 14px;
        line-height: 1.6;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
    &__more {
      position: relative;
      color: #666;
      letter-spacing: 0.15em;
      font-size: 12px;
      padding: 18px 25px;
      text-align: right;
      box-sizing: border-box;
      &:hover {
        cursor: pointer;
        opacity: .7;
      }
      &::before {
        content: "";
        position: absolute;
        top: 0;
        bottom: 0px;
        right: 8px;
        margin: auto;
        display: block;
        width: 1px;
        height: 22px;
        background: #666;
      }
      &::after {
        content: "";
        position: absolute;
        top: 0;
        bottom: -20px;
        right: 2px;
        margin: auto;
        display: block;
        width: 6px;
        height: 1px;
        transform: rotate(-45deg);
        transform-origin: 0;
        background: #666;
      }
      &.active {
        &::after {
          top: -20px;
          bottom: 0;
          transform: rotate(45deg);
        }
      }
    }
  }
}


