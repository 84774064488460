@charset "UTF-8";

// ===========================
// lower intro
// ------------------------------
.lower {
  .intro_short {
    width: 700px;
    margin: 0 auto;
    @include sp {
      width: calc(100% - 40px);
      margin: 0 auto;
    }
    &__ttl {
      font-size: 30px;
      color: #00b9ef;
      line-height: 1.5;
      font-feature-settings : "palt";
      @include sp {
        margin-bottom: 25px;
        font-size: 19px;
      }
    }
    &__text {
      font-size: 18px;
      line-height: 1.6;
      @include sp {
        font-size: 16px;
      }
    }
  }
}

// ===========================
// lower kv
// ------------------------------
.lower {
  .kv {
    position: relative;
    margin-bottom: 50px;
    @include sp {
      margin-bottom: 25px;
    }
    &__ttl--2l {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      margin: auto;
      text-align: center;
      height: 3em;
      letter-spacing: 0.3em;
      font-size: 45px;
      color: #fff;
      line-height: 1.4;
      @include sp {
        font-size: 25px;
      }
    }
  }
  .kv2 {
    position: relative;
    margin: 0;
  }
}



.is-block {
  display: block;
  width: 250px;
  margin: 30px 0 0 calc(100% - 250px);
    @include sp {
      width: 200px;
      margin: 30px 20px 0 calc(100% - 200px);
    }
}


.company {
  .books {
    &__ttl {
      margin: 0 0 30px calc(100% - 460px);
      font-size: 26px;
      line-height: 1.5;
      color: #00b9ef;
      @include sp {
        font-feature-settings : "palt";
        margin: 0 auto 18px;
        text-align: center;
        font-size: 20px;
      }
      &--light-blue {
        color: #00b9ef;
      }
      &--mb0 {
        margin-bottom: 0;
      }
    }
    &__sub-ttl {
      margin: 10px 0 70px;
      text-align: center;
      font-size: 24px;
      font-weight: bold;
      line-height: 1.6;
      @include sp {
        margin: 5px 0 30px;
        font-size: 16px;
      }
    }
    &__detail {
      display: flex;
      margin-bottom: 130px;
      @include sp {
        flex-direction: column;
        margin-bottom: 40px;
      }
      &__img {
        width: 400px;
        text-align: center;
        @include sp {
          width: 100%;
          margin: 0 auto 20px;
          img {
            width: 60%;
          }
        }
      }
      &__auth {
        width: 400px;
        text-align: left;
        font-size: 16px;
        line-height: 1.6;
        @include sp {
          width: 100%;
          margin: 0 auto 20px;
        }
        & dl {
          display: flex;
          dt {
            width: 40px;
          }
          dd {
            width: 300px;
            &:before {
              content: ":";
              margin: 0 0.2em;
            }
          }
        }
        & li:first-child {
          margin-top: 45px;
        }
        & li a {
          color: #0e49bd;
        }
      }
      &__desc {
        width: 460px;
        text-align: left;
        font-size: 16px;
        line-height: 1.6;
        @include sp {
          width: 100%;
          margin: 0 auto;
        }
        & a {
          color: #0e49bd;
        }
        & h3 {
          font-size: 20px;
          color: #00b9ef;
          margin: 35px 0 30px;
        }
        & ul {
          width: 460px;
          list-style-position: inside;
          @include sp {
            width: 100%;
          }
          li {
            list-style: none;
            width: 400px;
            padding-left: 1em;
            text-indent: -1em;
            @include sp {
              width: 100%;
            }
            &:before {
              content:"";
              width: 8px;
              height: 8px;
              display: inline-block;
              background-color: #000;
              border-radius: 50%;
              margin-right: 1em;
            }
          }
        }
        & dl {
          width: 340px;
          display: flex;
          @include sp {
            width: 100%;
          }
          dt {
            width: 80px;
          }
          dd {
            width: 260px;
          }
        }
      }
      &__block {
        width: 400px;
        margin-bottom: 50px;
        &--mb0 {
          margin-bottom: 0;
        }
        @include sp {
          width: auto;
        }
      }
      &__ttl {
        padding-left: 15px;
        margin-bottom: 30px;
        font-size: 24px;
        font-weight: bold;
        font-feature-settings : "palt";
        line-height: 1.4;
        @include sp {
          margin-bottom: 25px;
          padding-left: 10px;
          font-size: 16px;
        }
      }
      &__text {
        font-size: 16px;
        line-height: 1.6;
        .indent {
          display: inline-block;
          padding-left: 2em;
          text-indent: -2em;
        }
      }
      &__note {
        margin-top: 1.6em;
        font-size: 14px;
        line-height: 1.6;
      }
    }
  }
  .main {
    margin: 0;
    padding: 0;
    position: relative;
    .first {
      
    }
    .second {
      margin: 0 0 30px;
    }
    .third {
      margin: 0 0 50px;
      @include sp {
        margin: 0 0 18px;
      }
    }
  }
  .bg-gray {
    background: #eee;
  }
  .low-wrap {
    width: 700px;
    margin: 0 auto;
    @include sp {
      width: calc(100% - 40px);
    }
  }
  .low-wrap--long {
    width: 860px;
    margin: 0 auto;
    @include sp {
      width: calc(100% - 40px);
    }
  }
  }
  .ttl {
    margin-bottom: 40px;
    color: #fff;
    font-size: 22px;
    letter-spacing: 0.1em;
    text-align: center;
    line-height: 50px;
    @include sp {
      margin-bottom: 25px;
      font-size: 16px;
      line-height: 30px;
    }
    &--light-blue {
      background: #00b9ef;
    }
    &--orange {
      background: #f39800;
    }
    &--pink {
      background: #ea596c;
    }
    &--light-green {
      background: #62c44c;
    }
    &--green {
      background: #0ca491;
    }
  }
  .figure {
    padding-top: 20px;
    @include sp {
      padding: 25px 0 0;
    }
    .inner {
      width: 100%;
        img {
          width: 100%;
        }
      @include sp {
        width: 100%;
        img {
          width: 100%;
        }
      }
    }
  }
  .message {
    padding-top: 50px;
    @include sp {
      padding: 35px 0 0;
    }
  .inner {
    width: 100%;
    font-size: 16px;
    line-height: 1.6;
    p {
      margin-bottom: 40px;
    @include sp {
      margin-bottom: 32px;
    }
    }
  }
}
  .mission {
    padding-top: 0;
    @include sp {
      padding: 0 0 0;
    }
  .inner {
    width: 100%;
    font-size: 16px;
    line-height: 1.6;
    p {
      margin-bottom: 40px;
    @include sp {
      margin-bottom: 32px;
      }
    }
    .decla {
      width: 100%;
      font-weight: bold;
      font-feature-settings : "palt";
      text-align: center;
      padding-bottom: 50px;
      @include sp {
        padding-bottom: 22px;
      }
      h2 {
        color: #00b9ef;
        font-size: 27px;
        margin: 0;
        padding: 55px 0 20px;
        border-bottom: 1px solid #bbbbbb;
        @include sp {
          font-size: 16px;
          padding: 22px 0 12px;
        }
      }
      .desc {
        color: #444444;
        font-size: 34px;
        line-height: 1.5;
        margin: 50px 0 0;
        padding: 0 0 45px;
        @include sp {
          font-size: 18px;
          margin: 0;
          padding: 27px 0 20px;
        }
      }
      .desc__short {
        color: #444444;
        font-size: 34px;
        line-height: 1;
        margin: 50px 0 0;
        padding: 0 0 45px;
          span {
            font-size: 24px;
          }
        @include sp {
          font-size: 18px;
          margin: 0;
          padding: 27px 0 20px;
          span {
            margin: -5px 0 0;
            font-size: 12px;
          }
        }
      }
      dl {
        width: 700px;
        margin-bottom: 30px;
        color: #fff;
        background-color: #00b9ef;
        vertical-align: middle;
        border-radius: 5px;
        font-family: "Raleway","游ゴシック体", YuGothic, "游ゴシック", "Yu Gothic", "メイリオ", sans-serif;
        @include sp {
          width: 100%;
          margin-bottom: 15px;
        }
      }
      dt {
        width: auto;
        margin: 0 20px 0 5px;
        font-size: 26px;
        text-align: center;
        display: inline-block;
        font-weight: normal;
        @include sp {
          margin: 0 8px 0 0;
          font-size: 13px;
        }
      }
      .is-long {
        width: auto;
        margin: 0 12px 0 0;
        font-size: 26px;
        text-align: center;
        display: inline-block;
        font-weight: normal;
        @include sp {
          margin: 0 5px 0 0;
          font-size: 13px;
        }
      }
      dd:before {
        content: "";
        width: 1px;
        height: 40px;
        background: #fff;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        margin: auto;
        display: block;
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
        -webkit-transform-origin: 0;
        transform-origin: 0;
        @include sp {
          height: 20px;
        }
      }
      dd {
        position: relative;
        width: calc(100% - 60px - 1em);
        margin: 0;
        padding: 6px 0 10px 0px;
        font-size: 20px;
        text-align: left;
        display: inline-block;
        font-weight: normal;
        @include sp {
          width: calc(100% - 30px - 1em);
          padding: 8px 0 7px 0;
          font-size: 10px;
        }
        span {
          margin: 0 0.5em 0 1em;
          font-size: 27px;
          font-weight: bold;
          font-family: "游ゴシック体", YuGothic, "游ゴシック", "Yu Gothic", "メイリオ", sans-serif;
          @include sp {
            font-size: 13px;
          }
        }
      }
    }
  }
}

.profile {
  padding-bottom: 35px;
    .intro__ttl {
      margin: 0;
      padding: 50px 0;
      @include sp {
        padding: 25px 0;
      }
    }
    .inner {
      width: 100%;
      background-color: #fff;
      img {
        width: 100%;
      }
      .table {
        width: 600px;
        margin: 0 auto;
        line-height: 1.6;
        @include sp {
          width: 88%;
        }
        tr {
          font-size: 16px;
          border-bottom: 1px solid #e5e5e5;
          }
        tr:last-child {
          border-bottom: none;
        }
        th {
          width: 130px;
          margin: 30px 0;
          font-weight: bold;
          vertical-align: top;
          display: inline-block;
          @include sp {
            width: 100%;
            margin: 20px 0;
          }
        }
        td {
          font-feature-settings : "palt";
          width: 460px;
          margin: 30px 0;
          display: inline-block;
          @include sp {
            width: 100%;
            margin: 0 0 20px;
            & > div > a {
              color: #00b9ef;
            }
          }
            .bus__cont--first li {
              line-height: 1.6;
              font-size: 16px;
              margin: 0 0 30px 0;
              padding: 0 0 0 1em;
              & a {
                color: #00b9ef;
              }
            }
            .bus__cont--first li:last-child {
              margin: 0;
            }
            .bus__cont--first li:before {
              content: "";
              width: 10px;
              height: 10px;
              display: inline-block;
              background-color: #bfbfbf;
              border-radius: 50%;
              margin: 0 5px 0 -1em;
            }
            .bus__cont--second li {
              line-height: 1.6;
              font-size: 16px;
              margin: 0;
              padding: 0 0 0 1em;
            }
            .bus__cont--second li:before {
              content: "";
              width: 8px;
              height: 8px;
              display: inline-block;
              background-color: #aadeed;
              border-radius: 50%;
              margin: 0 5px 0 -1em;
            }
          }
    }
  }
  .capt {
    width: 100%;
    height: auto;
    line-height: 1.6;
    font-size: 16px;
    margin: 30px 0 100px;
    @include sp{
      margin: 25px 0 50px;
    }
  }
  .inner_cont {
    padding: 55px 0;
    @include sp {
      padding: 20px 0;
    }
  }
  .root {
    width: 600px;
    margin: 0 auto;
    border-bottom: 1px solid #e5e5e5;
    @include sp {
      width: 88%;
    }
    h2 {
      font-size: 18px;
      line-height: 1.6;
      color: #00b9ef;
      margin: 30px 0 25px;
    }
    .desc {
      font-size: 16px;
      line-height: 1.6;
      margin-bottom: 30px;
    }
  }
  .root:last-child {
    margin: 0 auto;
    border-bottom: none;
  }
}

.act_date {
  width: 100%;
  font-size: 15px;
  text-align: right;
  margin-bottom: 150px;
  @include sp {
    margin-bottom: 70px;
  }
}
.map {
  position: relative;
  width: 32px;
  height: 20px;
  margin: 15px 0 0;
  padding: 2px 24px 4px;
  color: #fff;
  font-weight: bold;
  border-radius: 3px;
  background-color: #00b9ef;
  display: inline-block;
  @include sp {
    width: 28px;
    padding: 5px 12px 4px;
    font-size: 14px;
  }
}
.map:after {
  position: absolute;
  top: 5px;
  left: 90px;
  width: 15px;
  height: 14px;
  content: url('../img/company/link.png');
  display: inline-block;
  @include sp {
  left: 60px;
  }
}

.access {
  position: relative;
  width: 100%;
  div {
    position: absolute;
    left: 352px;
    bottom: 0;
    color: #00b9ef;
    font-size: 12px;
  }
  div:hover {
    text-decoration: underline solid #00b9ef;;
  }
}

  .item {
    display: -webkit-box;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    .card {
      width: 320px;
        @include sp {
         width: 48%;
        }
       img {
        width: 320px;
        @include sp {
         width: 100%;
        }
      }
    }
  }

.message {
  .rel {
    width: 320px;
    margin: 20px 0 50px 390px;
    @include sp {
    width: 235px;
    margin: 25px 0 25px  calc(100% - 235px);
    }
    a {
      text-decoration: none;
      .item {
        margin: 30px 0 0;
        padding: 0;
        @include sp {
          margin: 20px 0 0;
        }
        p {
          font-size: 14px;
          line-height: 1.4;
          color: #00b9ef;
          padding: 7px 12px 6px 42px;
          border: 1px solid #00b9ef;
          @include sp {
            font-size: 12px;
            line-height: 1.2;
            padding: 8px 5px 7px 15px;
          }
        }
        p:after {
          width: 30px;
          margin:0 0 0 1em;
          content: url('../img/company/btn.png');
          @include sp {
            width: 21px;
            margin:0 0 0 0.5em;
            content: url('../img/company/btn_sp.png');
          }
        }
      }
    }
  }
}
.mission {
  .rel {
    width: 171px;
    margin: 20px 0 50px 529px;
    @include sp {
    width: 235px;
    margin: 25px 0 25px  calc(100% - 235px);
    }
    a {
      text-decoration: none;
      .item {
        margin: 30px 0 0;
        padding: 0;
        @include sp {
          margin: 20px 0 0;
        }
        p {
          font-size: 14px;
          line-height: 1.4;
          color: #00b9ef;
          padding: 7px 12px 6px 42px;
          border: 1px solid #00b9ef;
          @include sp {
            font-size: 12px;
            line-height: 1.2;
            padding: 8px 5px 7px 15px;
          }
        }
        p:after {
          width: 30px;
          margin:0 0 0 1em;
          content: url('../img/company/btn.png');
          @include sp {
            width: 21px;
            margin:0 0 0 0.5em;
            content: url('../img/company/btn_sp.png');
          }
        }
      }
    }
  }
}

