@charset "UTF-8";

.business {
  .ttl {
    margin-bottom: 40px;
    color: #fff;
    font-size: 22px;
    letter-spacing: 0.1em;
    text-align: center;
    line-height: 50px;
    @include sp {
      margin-bottom: 25px;
      font-size: 16px;
      line-height: 30px;
    }
    &--naby {
      background: #006194;
    }
    &--light-blue {
      background: #00b9ef;
    }
    &--orange {
      background: #f39800;
    }
    &--pink {
      background: #ea596c;
    }
    &--light-green {
      background: #62c44c;
    }
    &--green {
      background: #0ca491;
    }
  }
  .figure {
    padding: 50px 0px;
    @include sp {
      padding: 25px 0;
    }
    .row {
      width: 690px;
      margin: 0 auto;
      display: flex;
      justify-content: flex-end;
      &--1 {
        box-sizing: border-box;
        padding-left: 100px;
        @include sp {
          padding-left: 48px;
        }
      }
      @include sp {
        width: 100%;
      }
    }
  }
  .cell-block-wrap {
    display: flex;
    color: #00b9ef;
    width: calc(100% - 100px);
    @include sp {
      width: calc(100% - 48px);
    }
  }
  .cell {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    color: #00b9ef;
    text-align: center;
    border: solid 1px #00b9ef;
    box-sizing: border-box;
    margin: 10px;
    padding: 13px 20px;
    line-height: 1.6;
    width: 100%;
    @include sp {
      margin: 4px;
      font-size: 14px;
      min-width: inherit;
      padding: 10px 5px;
      img {
        width: 100%;
      }
    }
    &--rl {
      writing-mode: vertical-rl;
      min-width: inherit;
      padding: 48px 0;
      width: 80px;
      @include sp {
        padding: 80px 0;
        width: 40px;
      }
    }
    &--btn {
      background: #fff;
      border: solid 2px #00b9ef;
      border-radius: 8px;
      font-weight: bold;
      color: #00b9ef;
      box-shadow: 0 2px 4px rgba(102,102,102,0.2);
      &--orange {
        color: #f39800;
        border: solid 2px #f39800;
      }
      &--pink {
        color: #ea596c;
        border: solid 2px #ea596c;
      }
      &--light-green {
        color: #62c44c;
        border: solid 2px #62c44c;
      }
      &--green {
        color: #0ca491;
        border: solid 2px #0ca491;
      }
    }
    img {
      display: block;
    }
  }
  .cell-block {
    display: flex;
    flex-wrap: wrap;
    width: 50%;
  }
  .sec-index-wrap {
    padding: 50px 0 30px;
    @include sp {
      padding: 25px 0 0;
    }
  }
  .sec-index {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
    .cell-block {
      width: 400px;
      margin-bottom: 40px;
      @include sp {
        margin-bottom: 25px;
        width: 100%;
      }
      &__text {
        font-size: 16px;
        line-height: 1.6;
      }
    }
    .cell--btn {
      margin: 0 0 30px;
      padding: 0;
      width: 100%;
      min-width: inherit;
      height: 70px;
      position: relative;
      &::after {
        content: "";
        position: absolute;
        right: 18px;
        width: 8px;
        height: 8px;
        border-top: 2px solid #00b9ef;
        border-right: 2px solid #00b9ef;
        transform: rotate(45deg);
        @include sp {
          right: 8px;
          width: 5px;
          height: 5px;
        }
      }
      @include sp {
        height: 50px;
        img {
          width: auto;
          max-width: 220px;
          max-height: 37px;
        }
      }
      &--orange {
        &::after {
          border-top: 2px solid #f39800;
          border-right: 2px solid #f39800;
        }
      }
      &--pink {
        &::after {
          border-top: 2px solid #ea596c;
          border-right: 2px solid #ea596c;
        }
      }
      &--light-green {
        &::after {
          border-top: 2px solid #62c44c;
          border-right: 2px solid #62c44c;
        }
      }
      &--green {
        &::after {
          border-top: 2px solid #0ca491;
          border-right: 2px solid #0ca491;
        }
      }
    }
  }

  .case {
    &__ttl {
      margin-bottom: 50px;
      font-size: 30px;
      line-height: 1.5;
      text-align: center;
      @include sp {
        font-feature-settings : "palt";
        margin-bottom: 25px;
        font-size: 20px;
      }
      &--orange {
        color: #f39800;
      }
      &--pink {
        color: #ea596c;
      }
      &--light-green {
        color: #62c44c;
      }
      &--green {
        color: #0ca491;
      }
      &--mb0 {
        margin-bottom: 0;
      }
    }
    &__sub-ttl {
      margin: 10px 0 70px;
      text-align: center;
      font-size: 24px;
      font-weight: bold;
      line-height: 1.6;
      @include sp {
        margin: 5px 0 30px;
        font-size: 16px;
      }
    }
    &__detail {
      display: flex;
      @include sp {
        flex-direction: column;
      }
      &--hiroshima {
        @include sp {
          flex-direction: column-reverse;
        }
      }
      &__img {
        width: 460px;
        text-align: center;
        @include sp {
          width: 252px;
          margin: 0 auto 20px;
          img {
            width: 100%;
          }
        }
        &--hiroshima {
          text-align: left;
          margin-bottom: 45px;
          @include sp {
            width: 100%;
          }
          .case__detail__cap {
            width: 300px;
            margin: 10px 25px 25px;
            font-size: 14px;
            line-height: 2;
            @include sp {
              width: calc(100% - 40px);
              margin: 16px auto 20px;
              line-height: 1.5;
              font-size: 16px;
            }
          }
          .case__detail__photo {
            width: 300px;
            margin: 0 25px;
            @include sp {
              display: block;
              width: calc(100% - 40px);
              margin: 0 auto;
            }
          }
        }
      }
      &__block {
        width: 400px;
        margin-bottom: 50px;
        &--mb0 {
          margin-bottom: 0;
        }
        @include sp {
          width: auto;
        }
      }
      &__ttl {
        padding-left: 15px;
        margin-bottom: 30px;
        font-size: 24px;
        font-weight: bold;
        font-feature-settings : "palt";
        line-height: 1.4;
        &--orange {
          font-size: 20px;
          border-left: solid 4px #f39800;
        }
        &--pink {
          font-size: 20px;
          border-left: solid 4px #ea596c;
        }
        &--light-green {
          font-size: 20px;
          border-left: solid 4px #62c44c;
        }
        &--green {
          border-left: solid 4px #0ca491;
        }
        @include sp {
          margin-bottom: 25px;
          padding-left: 10px;
          font-size: 16px;
        }
      }
      &__text {
        font-size: 16px;
        line-height: 1.6;
        .indent {
          display: inline-block;
          padding-left: 2em;
          text-indent: -2em;
        }
      }
      &__note {
        margin-top: 1.6em;
        font-size: 14px;
        line-height: 1.6;
      }
    }
  }
  .point {
    width: 700px;
    margin: 0 auto;
    padding: 50px 0;
    box-sizing: border-box;
    &--mb {
      margin-bottom: 80px;
      @include sp {
        margin-bottom: 25px;
      }
    }
    @include sp {
      width: calc(100% - 40px);
      padding: 25px 0;
    }
    &__ttl {
      margin-bottom: 32px;
      font-size: 24px;
      font-weight: bold;
      text-align: center;
      @include sp {
        margin-bottom: 25px;
        font-size: 16px;
      }
      &--pink {
        color: #ea596c;
      }
      &--light-green {
        color: #62c44c;
      }
      &--green {
        color: #0ca491;
      }
    }
    &__detail {
      background: #fff;
      padding: 30px 0;
      box-sizing: border-box;
      @include sp {
        padding: 25px 0;
      }
      &__list {
        width: 590px;
        margin: 0 auto 30px;
        // padding: 16px 0;
        height: 90px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 24px;
        font-weight: bold;
        line-height: 1.4;
        border-radius: 8px;
        text-align: center;
        box-sizing: border-box;
        color: #fff;
        font-feature-settings : "palt";
        @include sp {
          width: calc(100% - 40px);
          height: auto;
          min-height: 60px;
          margin: 0 auto 25px;
          padding: 8px;
          font-size: 14px;
        }
        &--pink {
          background: #ea596c;
        }
        &--light-green {
          background: #62c44c;
        }
        &--green {
          background: #0ca491;
        }
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
  .point-summary {
    position: relative;
    padding: 130px 0 50px;
    text-align: center;
    font-size: 24px;
    line-height: 1.4;
    font-weight: bold;
    @include sp {
      padding: 75px 20px 25px;
      font-size: 16px;
    }
    &::before {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      margin: auto;
      width: 50px;
      height: 83px;
      background: url(../img/business/icon_arrow_lg.png) no-repeat center;
      background-size: contain;
      @include sp {
        width: 31px;
        height: 52px;
      }
    }
    &__big {
      display: inline-block;
      margin-top: 15px;
      font-size: 30px;
      @include sp {
        margin-top: 10px;
        font-size: 20px;
      }
      &--light-green {
        color: #62c44c;
      }
    }
  }
  .scroll-link {
    margin-bottom: 80px;
    padding: 0 10px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    @include sp {
      margin-bottom: 30px;
      padding: 0;
      flex-direction: column;
    }
    .btn {
      &--scroll {
        width: 400px;
        color: #fff;
        text-align: center;
        font-size: 20px;
        background: #b5b5b5;
        border-radius: 6px;
        letter-spacing: 0;
        font-feature-settings : "palt";
        padding-bottom: 28px;
        @include sp {
          width: 100%;
          margin-bottom: 14px;
          font-size: 16px;
          padding: 8px 8px 28px;
          &:last-child {
            margin-bottom: 0;
          }
        }
        &::before {
          display: none;
        }
        &::after {
          content: "";
          position: absolute;
          right: 0;
          left: 0;
          bottom: -20px;
          width: 8px;
          height: 8px;
          border-top: 2px solid #fff;
          border-right: 2px solid #fff;
          transform: rotate(135deg);
        }
        &--3 {
          @include pc {
            width: 260px;
            padding-left: 0;
            padding-right: 0;
          }
        }
      }
    }
  }
  .merit {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    &__block {
      width: 400px;
      margin-bottom: 50px;
      @include sp {
        margin-bottom: 25px;
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
    &__ttl {
      margin-bottom: 30px;
      padding: 16px;
      text-align: center;
      font-size: 20px;
      font-weight: bold;
      color: #fff;
      @include sp {
        margin-bottom: 20px;
        font-size: 16px;
        padding: 8px;
      }
      &--orange {
        background: #fff;
        color: #f39800;
        border: 1px solid #f39800;
      }
      &--light-green {
        background: #62c44c;
      }
    }
    &__detail {
      margin-bottom: 40px;
      font-size: 18px;
      line-height: 1.4;
      font-feature-settings : "palt";
      @include sp {
        margin-bottom: 25px;
        font-size: 16px;
        .note {
          font-size: 12px;
        }
      }
      &:last-child {
        margin-bottom: 0;
      }
      &__ttl {
        margin-bottom: 20px;
        @include sp {
          margin-bottom: 10px;
        }
      }
      &__text {
        padding-left: 1em;
      }
    }
    &__list {
      margin-bottom: 20px;
      padding: 18px;
      font-size: 18px;
      line-height: 1.4;
      font-weight: bold;
      background: #eee;
      border-radius: 8px;
      text-align: center;
      font-feature-settings : "palt";
      @include sp {
        padding: 14px;
        font-size: 16px;
        .note {
          font-size: 12px;
        }
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
    &__note {
      margin-top: 20px;
      font-size: 14px;
      line-height: 1.7;
      @include sp {
        font-size: 12px;
      }
    }
    .fc-blue {
      color: #006194;
    }
    .fc-orange {
      color: #f39800;
    }
  }
  .illust {
    text-align: center;
    &__ttl {
      margin-bottom: 40px;
      font-size: 24px;
      font-weight: bold;
      @include sp {
        margin-bottom: 20px;
        font-size: 16px;
      }
      &--pink {
        color: #ea596c;
      }
      &--light-green {
        color: #62c44c;
      }
    }
    &__text {
      width: 670px;
      margin: 0 auto;
      font-size: 24px;
      line-height: 1.5;
      text-align: left;
      @include sp {
        width: 100%;
        font-size: 14px;
      }
    }
    &__img {
      margin-bottom: 50px;
      @include sp {
        margin-bottom: 25px;
      }
      img {
        @include sp {
          width: 100%;
        }
      }
    }
  }
  .city {
    width: 700px;
    margin: 0 auto 80px;
    padding: 50px 0;
    @include sp {
      width: calc(100% - 40px);
      margin: 0 auto 40px;
      padding: 25px 0;
    }
    &__ttl {
      margin-bottom: 32px;
      text-align: center;
      font-size: 24px;
      font-weight: bold;
      @include sp {
        margin-bottom: 20px;
        font-size: 16px;
      }
      &--light-green {
        color: #62c44c;
      }
    }
    &__detail {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }
    &__list {
      width: 320px;
      margin-bottom: 20px;
      text-align: center;
      color: #fff;
      font-size: 20px;
      line-height: 50px;
      font-weight: bold;
      @include sp {
        width: 48%;
        margin-bottom: 15px;
        font-size: 16px;
        line-height: 30px;
      }
      &--light-green {
        background: #62c44c;
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}


