@mixin clearfix {
  &:after {
    content: ".";
    visibility: hidden;
    height: auto;
    line-height: 0;
    clear: both;
    display: block;
  }
}

@mixin SP {
  @media (max-width: 767px) {
    @content;
  }
}


@mixin pc(){
  @media screen and (min-width: 768px) {
    @content;
  }
}
@mixin sp(){
  @media screen and (max-width: 767px) {
    @content;
  }
}

@mixin content($w:100,$h:100){
  content: "";
  display: block;
  position: absolute;
  width: $w + px;
  height: $h + px;
  background-repeat: no-repeat;
  background-size: cover;
}

@mixin rem-font-size($size) {
  font-size: $size + px;
  font-size: ($size / 10) * 1rem;
}

@mixin scaleImg(){
  overflow: hidden;
  display: block;
  img{
    -moz-transition: -moz-transform 0.1s linear;
    -webkit-transition: -webkit-transform 0.1s linear;
    -o-transition: -o-transform 0.1s linear;
    -ms-transition: -ms-transform 0.1s linear;
    transition: transform 0.1s linear;
  }
  &:hover{
    img{
      transform: scale(1.1,1.1);
    }
  }
}

@mixin placeholderColor($color) {
  &:placeholder-shown {
      color: $color;
  }
  &::-webkit-input-placeholder {
      color:$color;
  }
  &:-moz-placeholder {
      color:$color;
      opacity: 1;
  }
  &::-moz-placeholder {
      color:$color;
      opacity: 1;
  }
  &:-ms-input-placeholder {
      color:$color;
  }
}

@mixin triangle-maker($size, $color, $direct){
  display: block;
  width: 0;
  height: 0;
  border-top: $size solid transparent;
  border-right: $size solid transparent;
  border-bottom: $size solid transparent;
  border-left: $size solid transparent;
  @if $direct == top{
    border-top: $size solid $color;
  } @else if $direct == left{
    border-left: $size solid $color;
  } @else if $direct == right{
    border-right: $size solid $color;
  } @else if $direct == bottom{
    border-bottom: $size solid $color;
  }
}

@mixin centercenter($side:-50%,$long:-50%){
  top: 50%;
  left: 50%;
  -webkit-transform: translate($side,$long);
  -ms-transform: translate($side,$long);
  transform: translate($side,$long);
}

@mixin menu-trigger($bg:#424242,$round:0,$size:50px){
  display: inline-block;
  transition: all .4s;
  box-sizing: border-box;
  position: absolute;
  width: $size;
  height: $size;
  background-color: $bg;
  padding: 10px;
  z-index: 100;
  @if $round == circle{
    border-radius: 50%;
  } @else if $round == square{
    border-radius: 0;
  }
  span{
    display: inline-block;
    transition: all .4s;
    box-sizing: border-box;
    position: absolute;
    left: 0;
    width: 100%;
    height: 3px;
    background-color: #006da6;
    border-radius: 20px;
    &:nth-of-type(1) {
      top: 16px;
      left: 25%;
      width: 50%;
    }
    &:nth-of-type(2) {
      top: 46%;
      left: 25%;
      width: 50%;
    }
    &:nth-of-type(3) {
      bottom: 17px;
      left: 25%;
      width: 50%;
    }
  }
  &.active{
    span{
      &:nth-of-type(1) {
        -webkit-transform: translateY(7px) rotate(-315deg);
        transform: translateY(7px) rotate(-315deg);
      }
      &:nth-of-type(2) {
        opacity: 0;
      }
      &:nth-of-type(3) {
        -webkit-transform: translateY(-7px) rotate(315deg);
        transform: translateY(-7px) rotate(315deg);
      }
    }
  }
}



