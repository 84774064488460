@charset "UTF-8";

.management {
  .kv {
    margin-bottom: 0;
  }
  &-wrap {
    width: 800px;
    margin: 0 auto;
    padding: 50px 0;
    @include sp {
      width: calc(100% - 40px);
      padding: 25px 0;
    }
  }
  &__ttl {
    padding: 0 50px 50px;
    font-size: 30px;
    font-weight: bold;
    color: #00b9ef;
    @include sp {
      padding: 0 0 25px;
      font-size: 20px;
    }
  }
  &__block {
    margin-bottom: 50px;
    display: flex;
    justify-content: space-between;
    padding: 30px;
    background: #fff;
    box-sizing: border-box;
    @include sp {
      flex-direction: column;
      margin-bottom: 25px;
      padding: 20px;
    }
    &:last-child {
      margin-bottom: 0;
    }
    .img {
      width: 350px;
      @include sp {
        width: 100%;
        margin-bottom: 15px;
      }
      img {
        width: 100%;
      }
    }
    .profile {
      width: 360px;
      @include sp {
        width: 100%;
      }
      &__ttl {
        margin-bottom: 30px;
        font-size: 18px;
        font-weight: bold;
        @include sp {
          font-size: 16px;
          margin-bottom: 15px;
        }
      }
      &__text {
        font-size: 16px;
        line-height: 1.6;
      }
    }
  }
  .management-list {
    width: 800px;
    margin: 0 auto;
    padding: 50px 0 0;
    @include sp {
      width: calc(100% - 40px);
      padding: 25px 0 0;
    }
    &__ttl {
      margin-bottom: 30px;
      padding: 10px 20px;
      color: #fff;
      font-size: 18px;
      background: #00b9ef;
      @include sp {
        margin-bottom: 20px;
        font-size: 14px;
      }
    }
    &__block-area {
      margin-bottom: 20px;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      @include sp {
        margin-bottom: 0;
        flex-direction: column;
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
    .block {
      margin-bottom: 30px;
      width: 50%;
      font-size: 16px;
      line-height: 1.6;
      @include sp {
        margin-bottom: 20px;
        width: 100%;
        font-size: 14px;
      }
      &__name {
        font-weight: bold;
      }
    }
  }
}


