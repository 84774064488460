@charset "UTF-8";

.page-news {
  .news-kv {
    background: #eee;
    margin-bottom: 0px;
    padding-bottom: 50px;
    @include sp {
      padding-bottom:25px;
    }
  }
  .news_detail-kv {
    margin-bottom: 0px;
    padding-bottom: 10px;
    @include sp {
      padding-bottom:25px;
    }
  }
  .content {
    margin-bottom: 50px;
    padding-bottom: 50px;
    background: #eee;
    @include sp {
      padding: 0 20px;
      margin-bottom: 30px;
    }
    &__inner {
      width: 900px;
      margin: 0 auto;
      overflow: hidden;
      @include sp {
        width: 100%;
        padding: 0 0 0;
      }
    }
    &__title {
      font-size: 30px;
      font-weight: bold;
      color: #00b9ef;
      margin-bottom: 50px;
      line-height: 1.5;
      @include sp {
        text-align: left;
        font-size: 20px;
        margin-bottom: 25px;
      }
    }
    .newsArea {
      width: 660px;
      float: left;
      background: #fff;
      @include sp {
        width: auto;
        padding: 25px 20px 0;
        float: none;
        padding-bottom: 30px;
        /* margin-bottom: 40px; */
      }
      &__title{
        @include sp {
          font-size: 16px;
          font-weight: bold;
          line-height: 1.5;
          margin-bottom: 25px;
          color: #444;
        }
      }
      ul {
        overflow: hidden;
        li {
          float: left;
          font-size: 13px;
          color: #fff;
          width: 110px;
          height: 40px;
          line-height: 40px;
          text-align: center;
          cursor: pointer;
          @include sp {
            font-size: 16px;
            margin-bottom: 10px;
            &:nth-child(1){
              width: 100%;
            }
            &:nth-child(2){
              width: 48.5%;
              margin-right: 3%;
            }
            &:nth-child(3){
              width: 48.5%;
            }
            &:nth-child(4){
              width: 48.5%;
              margin-right: 3%;
            }
            &:nth-child(5){
              width: 48.5%;
            }
            &:nth-child(6){
              width: 48.5%;
            }
          }
          &#all {
            background: #00b9ef;
            @include sp {
              background: #006da6;
            }
          }
          &#seminar {
            background: #e76762;
          }
          &#release {
            background: #00be75;
          }
          &#media {
            background: #f1be0d;
          }
          &#update {
            background: #f1975a;
          }
          &#announce {
            background: #91c0de;
          }
        }
      }
      &__inner{
        width: 560px;
        margin: 40px 50px 50px;
        @include sp {
          width: 100%;
          margin: 30px 0 0;
        }
        &--2{
          @include sp {

            margin: 0;
          }
        }
      }
      .newsList{
        overflow: hidden;
        font-size: 12px;
        border-bottom: 2px solid #e5e5e5;
        padding-bottom: 25px;
        margin-bottom: 25px;
        @include sp {
          &.p-nomg{
            margin: 0;
          }
        }
        &--2{
          @include sp {
            padding-top: 25px;
          }
        }
        &--3{
          @include sp {
            padding-top: 0px;
          }
        }
        &__date{
          float: left;
          width: 100px;
          margin: 6px 25px 0 0;
          @include sp {
            display: inline-block;
            width: auto;
            font-size: 16px;
            padding-top: 2px;
            float: none;
            margin:0 20px 10px 0;
          }
        }
        &__cat{
          float: left;
          width: 120px;
          color: #fff;
          text-align: center;
          margin:0 20px 0 0;
          padding: 7px 0;
          border-radius: 5px;
          &.bg-seminar{
            background: #e76762;
          }
          &.bg-release{
            background: #00be75;
          }
          &.bg-media{
            background: #f1be0d;
          }
          &.bg-update{
            background: #f1975a;
          }
          &.bg-announce{
            background: #91c0de;
          }
          @include sp {
            display: inline-block;
            width: 55%;
            font-size: 12px;
            float: none;
            padding: 5px 0px;
            margin: 0 0 4px 0;
            vertical-align: top;
          }
        }
        &__text{
          float: left;
          width: 286px;
          padding-top: 2px;
          line-height: 1.5;
          @include sp {
            width: 100%;
            font-size: 16px;
            line-height: 1.5;
            float:none;
            margin:0 0 0 0;
          }
        }
      }
    }/* newsArea */
    .sideNav{
      float: right;
      width: 160px;
      font-size: 12px;
      padding: 30px 25px 40px;
      background: #fff;
      @include sp {
        width: auto;
        float: none;
        display: block;
        padding: 0 20px 14px;
        margin-bottom: 30px;
      }
      &__title{
        font-size: 14px;
        font-weight: bold;
        color: #000;
        margin-bottom: 20px;
        @include sp {
          font-size: 16px;
        }
      }
      ul{
        margin-bottom: 30px;
        @include sp {
          &.p-nomg{
            margin: 0;
          }
        }
        li{
          margin-bottom: 16px;
          @include sp {
            font-size: 16px;
          }
          a{
            color: #00b9ef;
            text-decoration: underline;
          }
        }
      }
    }
  }/* content */
}/* page-news */





