@charset "UTF-8";

.en {
  .header {
    &__logo {
      z-index: 30;
    }
    &__inner {
      padding: 20px 0 0;
    }
  }
  .footer__bottom {
    padding-top: 0;
  }
  .footer__nav {
    @include pc {
      width: 500px;
    }
  }
  .pagetop-area {
    @include sp {
      width: 50px;
      right: 20px;
      background: none;
    }
  }

  .kv--en-index {
    background: url(../img/en/mainVisua01_bg.jpg) no-repeat center;
    background-size: cover;
    height: 470px;
    .kv__ttl {
      width: 351px;
      height: 366px;
      position: absolute;
      top: 52px;
      left: 50%;
      right: inherit;
      bottom: inherit;
      margin-left: 80px;
      @include sp {
        width: 80%;
        height: auto;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
          width: 100%;
          max-width: 351px;
        }
      }
    }
  }
  .kv--ps {
    .kv__ttl {
      @include sp {
        height: 3em;
      }
    }
  }
  .en-menu {
    width: 860px;
    margin: 0 auto 80px;
    @include sp {
      width: calc(100% - 40px);
      margin-bottom: 40px;
    }
    ul {
      display: flex;
      justify-content: space-between;
      margin-bottom: 30px;
      @include sp {
        flex-wrap: wrap;
      }
    }
    li {
      width: 30%;
      text-align: center;
      background: #eee;
      font-size: 18px;
      font-weight: bold;
      background: #00b9ef;
      border-radius: 6px;
      border: solid 1px #00b9ef;
      @include sp {
        width: 100%;
        margin-bottom: 10px;
      }
      a {
        display: block;
        color: #fff;
        padding: 30px;
        box-sizing: border-box;
        @include sp {
          padding: 20px;
        }
        &:hover {
          text-decoration: none;
        }
      }
    }
    .jp {
      text-align: center;
    }
  }


  .sec {
    padding: 50px 0;
    margin: 0 auto;
    @include sp {
      padding: 25px 0;
    }
  }

  .intro {
    text-align: left;
    text-justify: inherit;
  }

  .list {
    li {
      margin-bottom: 20px;
      padding-left: 1em;
      text-indent: -1em;
      &::before {
        content: "";
        width: 10px;
        height: 10px;
        display: inline-block;
        background-color: #bfbfbf;
        border-radius: 50%;
        margin: 0 4px;
      }
      a {
        text-indent: 0;
      }
    }
  }
  .list-s {
    li {
      position: relative;
      padding-left: 1em;
      &::before {
        position: absolute;
        top: 8px;
        left: 0;
        content: "";
        width: 8px;
        height: 8px;
        display: inline-block;
        background-color: #aadeed;
        border-radius: 50%;
        margin: 0 4px;
      }
      a {
        text-indent: 0;
      }
    }
  }

  .listbox {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    li {
      margin-bottom: 1.25%;
      padding: 20px;
      box-sizing: border-box;
      font-size: 14px;
      color: #00b9ef;
      font-weight: bold;
      width: 19%;
      background: #edf6fc;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      @include sp {
        width: 49%;
        margin-bottom: 2%;
      }
    }
  }

  .text-area {
    width: 860px;
    margin: 0 auto 50px;
    text-align: left;
    text-justify: inherit;
    @include sp {
      width: calc(100% - 40px);
      margin: 0 auto 25px;
    }
    &:last-child {
      margin: 0 auto;
    }
    .wrap {
      display: flex;
      justify-content: space-between;
      margin-bottom: 30px;
      @include sp {
        flex-direction: column;
      }
      .logo-btn {
        width: 250px;
        background: #fff;
        border: solid 2px #00b9ef;
        border-radius: 8px;
        font-weight: bold;
        color: #00b9ef;
        box-shadow: 0 2px 4px rgba(102, 102, 102, 0.2);
        box-sizing: border-box;
        padding: 10px;
        @include sp {
          margin-bottom: 10px;
          width: 100%;
        }
        a {
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
      .text {
        width: 580px;
        @include sp {
          width: 100%;
        }
      }
    }
    &__ttl {
      padding-left: 18px;
      box-sizing: border-box;
      margin-bottom: 30px;
      font-size: 24px;
      font-weight: bold;
      color: #00b9ef;
      line-height: 1.4;
      border-left: solid 4px #b5b5b5;
      @include sp {
        margin-bottom: 25px;
        font-size: 16px;
        padding-left: 10px;
        border-left: solid 3px #b5b5b5;
      }
    }
    &__detail {
      margin-bottom: 1em;
      font-size: 14px;
      line-height: 1.6;
      &:last-child {
        margin-bottom: 0;
      }
      &.highlight {
        color: #00b9ef;
        font-size: 16px;
        font-weight: bold;
      }
      a {
        color: #00b9ef;
        &.blank {
          display: inline-block;
          &::after {
            width: 15px;
            height: 14px;
            content: url(../img/company/link.png);
            display: inline-block;
            margin: 0 8px;
            font-size: 0;
          }
        }
      }
    }
  }

  .table {
    width: 860px;
    margin: 0 auto;
    @include sp {
      width: calc(100% - 40px);
      display: block;
    }
    th {
      padding: 30px 0;
      font-size: 16px;
      width: 200px;
      font-weight: bold;
      vertical-align: top;
      border-bottom: 1px solid #e5e5e5;
      @include sp {
        width: 100%;
        display: block;
        border: none;
        padding: 20px 0;
      }
    }
    td {
      line-height: 1.6;
      vertical-align: top;
      padding: 30px 0;
      font-size: 16px;
      border-bottom: 1px solid #e5e5e5;
      @include sp {
        width: 100%;
        display: block;
        padding: 0 0 20px;
      }
      a {
        color: #00b9ef;
      }
      .map {
        margin-bottom: 30px;
        font-size: 14px;
        color: #fff;
        width: auto;
        &::after {
          left: inherit;
          right: -25px;
          top: 0;
          bottom: 0;
          margin: auto;
          font-size: 0;
        }
        &:hover {
          text-decoration: none;
        }
      }
      .mb15 {
        margin-bottom: 15px;
      }
    }
  }
  .service {
    li {
      margin-bottom: 20px;
      font-size: 16px;
      font-weight: bold;
      li {
        margin-bottom: 0px;
      }
      a {
        font-size: 14px;
        font-weight: normal;
      }
    }
  }
  &.contact {
    .select {
      overflow: hidden;
      width: 100%;
      margin: 0 auto 1em;
      text-align: center;
      box-sizing: border-box;
      select {
        width: 100%;
        cursor: pointer;
        text-indent: 0.01px;
        font-size: 16px;
        text-overflow: ellipsis;
        border: none;
        outline: none;
        background: transparent;
        background-image: none;
        box-shadow: none;
        -webkit-appearance: none;
        appearance: none;
        padding: 16px 38px 16px 16px;
        box-sizing: border-box;
        color: #666;
        &::-ms-expand {
          display: none;
        }
      }
      &.select01 {
        position: relative;
        border: 1px solid #bbbbbb;
        border-radius: 3px;
        background: #ffffff;
        &::before {
          position: absolute;
          top: 1.4em;
          right: 0.9em;
          width: 0;
          height: 0;
          padding: 0;
          content: '';
          border-left: 6px solid transparent;
          border-right: 6px solid transparent;
          border-top: 6px solid #666666;
          pointer-events: none;
        }
      }
    }
    .contact-table .telbox input {
      width: 100%;
    }
  }
}


