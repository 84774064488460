@charset "UTF-8";

.platform {
  .platform-block-wrap {
    width: 700px;
    margin: 0 auto;
    @include sp {
      width: calc(100% - 40px);
    }
  }
  .platform-block {
    padding: 50px 0 0;
    margin-bottom: 50px;
    @include sp {
      padding: 25px 0 0;
      margin-bottom: 25px;
    }
    &--movie {
      background: #fffeee;
    }
    &--line {
      background: #eff5ed;
    }
    &--lower{
      padding: 50px 0;
      margin-top: -50px;
      @include sp {
        padding: 25px 0;
        margin-top: -25px;
      }
      .platform-block__text {
        font-size: 30px;
        margin-bottom: 30px;
        text-align: left;
        @include sp {
          font-size: 20px;
          margin-bottom: 25px;
        }
      }
      .platform-block__img {
        margin-bottom: 0;
      }
      .platform-block__img--line {
        margin-bottom: 15px;
      }
    }
    &__ttl {
      margin-bottom: 30px;
      padding: 13px;
      font-size: 24px;
      font-weight: bold;
      color: #fff;
      text-align: center;
      @include sp {
        margin-bottom: 20px;
        font-size: 16px;
        padding: 7px;
      }
      &--movie {
        background: #f39800;
      }
      &--line {
        background: #62c44c;
      }
    }
    &__img {
      position: relative;
      width: 665px;
      margin: 0 auto 40px;
      @include sp {
        width: 100%;
        margin: 0 auto 25px;
      }
      &--2 {
        margin: 0 auto 20px;
        @include sp {
          margin: 0 auto 17px;
        }
      }
      img {
        width: 100%;
      }
    }
    &__cap {
      position: absolute;
      text-align: center;
      bottom: -15px;
      left: 0;
      right: 0;
      font-size: 16px;
      line-height: 1.6;
    }
    &__text {
      font-size: 24px;
      font-weight: bold;
      line-height: 1.6;
      text-align: center;
      @include sp {
        font-size: 16px;
        font-feature-settings: "palt";
      }
      &--movie {
        color: #f39800;
      }
      &--line {
        color: #62c44c;
      }
    }
  }
  .platform-case {
    width: 700px;
    margin: 0 auto 50px;
    @include sp {
      width: calc(100% - 40px);
      margin: 0 auto 40px;
    }
    &__ttl {
      margin-bottom: 30px;
      font-size: 24px;
      line-height: 1.4;
      padding-left: 20px;
      box-sizing: border-box;
      @include sp {
        margin-bottom: 25px;
        font-size: 16px;
        padding-left: 10px;
      }
      &--movie {
        border-left: solid 6px #f39800;
        @include sp {
          border-left: solid 4px #f39800;
        }
      }
      &--line {
        border-left: solid 6px #62c44c;
        @include sp {
          border-left: solid 4px #62c44c;
        }
      }
    }
    &__text {
      font-size: 16px;
      line-height: 1.6;
      .list {
        li {
          &::before {
            content: "";
            display: inline-block;
            width: 10px;
            height: 10px;
            margin: 0 3px;
            border-radius: 20px;
            background: #bfbfbf;
          }
        }
      }
    }
    &__img {
      width: 500px;
      margin: 30px auto 0;
      @include sp {
        width: 100%;
        margin: 25px auto 0;
      }
      img {
        width: 100%;
      }
    }
    &__note {
      margin-top: 20px;
      font-size: 14px;
      line-height: 1.8;
      font-feature-settings: "palt";
      &--line {
        li {
          padding-left: 2.5em;
          text-indent: -2.5em;
        }
      }
    }
  }
  .platform-list {
    width: 700px;
    margin: 0 auto 50px;
    @include sp {
      width: calc(100% - 40px);
      margin: 0 auto 25px;
    }
    &__ttl {
      margin-bottom: 30px;
      padding: 10px 20px;
      color: #fff;
      font-size: 18px;
      &--movie {
        background: #f39800;
      }
      &--movie-black {
        background: #fff;
        color: #000;
        border: 1px solid #f39800;
      }
    }
    &__text {
      font-size: 16px;
      line-height: 1.6;
    }
    .service-link {
      width: 700px;
      padding: 30px 0 0;
      @include sp {
        width: 100%;
        padding: 25px 0 0;
      }
    }
  }
  .point {
    margin-bottom: 50px;
    padding: 50px 0;
    @include sp {
      padding: 25px 0;
      margin-bottom: 25px;
    }
    &--movie {
      background: #fffeee;
    }
    &__wrap {
      width: 700px;
      margin: 0 auto;
      @include sp {
        width: calc(100% - 40px);
      }
    }
    &__inner {
      background: #fff;
      padding: 30px;
      box-sizing: border-box;
      @include sp {
        padding: 25px 20px;
      }
    }
    &__ttl {
      text-align: center;
      font-size: 24px;
      color: #f39800;
      margin: 0 auto 30px;
      @include sp {
        margin-bottom: 25px;
      }
    }
    &__list {
      width: 590px;
      margin: 0 auto;
      @include sp {
        width: 100%;
      }
      &__ttl {
        margin-bottom: 30px;
        background: #f39800;
        color: #fff;
        text-align: center;
        font-size: 24px;
        line-height: 1.8;
        border-radius: 20px;
        @include sp {
          margin-bottom: 20px;
          font-size: 16px;
        }
      }
      &__text {
        width: 495px;
        margin: 0 auto 25px;
        font-size: 16px;
        line-height: 1.6;
        @include sp {
          width: 100%;
        }
      }
      &:last-child {
        .point__list__text {
          margin-bottom: 0;
        }
      }
    }
  }
  .flow {
    width: 700px;
    margin: 0 auto;
    @include sp {
      width: calc(100% - 40px);
    }
    &__block {
      margin-bottom: 50px;
      @include sp {
        margin-bottom: 40px;
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
    &__img {
      display: block;
      margin-bottom: 30px;
      @include sp {
        margin-bottom: 15px;
        img {
          width: 100%;
        }
      }
    }
    &__detail {
      width: 670px;
      margin: 0 auto;
      display: flex;
      align-items: center;
      justify-content: space-between;
      @include sp {
        width: 100%;
        flex-direction: column;
      }
      &__ttl {
        box-sizing: border-box;
        width: 230px;
        height: 40px;
        border: solid 1px #f39800;
        text-align: center;
        font-size: 18px;
        color: #f39800;
        line-height: 2.2;
        @include sp {
          width: 100%;
          margin-bottom: 15px;
          font-size: 16px;
          height: 30px;
          line-height: 1.8;
        }
      }
      &__text {
        width: 425px;
        font-size: 15px;
        line-height: 1.8;
        @include sp {
          width: 100%;
          font-size: 16px;
        }
        &--center {
          width: 100%;
          text-align: center;
          @include sp {
            text-align: left;
          }
        }
      }
    }
  }
  .sample {
    width: 700px;
    margin: 0 auto;
    text-align: center;
    @include sp {
      width: calc(100% - 40px);
    }
    &__img {
      img {
        @include sp {
          width: 100%;
        }
      }
    }
    &__text {
      margin-top: 20px;
      font-size: 16px;
      line-height: 1.6;
      @include sp {
        text-align: left;
      }
    }
  }
}


