@charset "UTF-8";

.contentsWrap {
  width: 1000px;
  margin: 0 auto;
  background: #eee;
  @include sp {
    width: 100%;
    margin: 0 auto;
  }
  .news-kv {
    background: #eee;
    margin-bottom: 0px;
    padding-bottom: 50px;
    @include sp {
      padding-bottom: 40px;
    }
  }
  .mainBlock {
    width: 720px;
    float: left;
    margin-right: 25px;
    @include sp {
      width: 95%;
      float: none;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 20px;
    }
    .blockTtl2 {
      width: 640px;
      height: 80px;
      padding: 0 40px;
      display: table-cell;
      border-bottom: 3px solid #f3f3f3;
      background: #fff;
      font-size: 172%;
      font-weight: normal;
      line-height: 1.3;
      color: #00568d;
      text-align: left;
      vertical-align: middle;
    }
    .mainInner {
      margin: 0 auto 30px;
      padding: 30px 40px 40px;
      background: #fff;
      font-size: 1.2em;
      p {
        line-height: 2;
      }
      .dateBlock {
        margin: 0 auto 15px;
        text-align: right;
        color: #333333;
        font-size: 93%;
      }
    }
  }/* mainBlock */
}/* contentsWrap */

.clearfix {
  min-height: 1px;
  display: block;
  &:before {
    content: " ";
    display: table;
  }
  &:after {
    content: " ";
    display: table;
  }
}





