@charset "UTF-8";

.index {
  // ===========================
  // ページャー
  // ------------------------------
  .main {
    position: relative;
  }
  // ===========================
  // section
  // ------------------------------
  .section {
    width: 100%;
    min-height: 100vh;
    &.is-show {
      .section-content {
        opacity: 1;
      }
    }
    &__catch {
      font-size: 7vh;
      letter-spacing: 0.4em;
      line-height: 1.6;
      font-feature-settings: "palt";
      box-sizing: border-box;
      padding-left: 0.4em;
    }
  }
  .section-content {
    margin: 0 auto;
    text-align: center;
  }
  .wrap {
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  // ===========================
  // kv
  // ------------------------------
  .sec-top {
    position: relative;
    top: 0px;
    margin-bottom: 50px;
  }
  .kv {
    position: relative;
    width: 100%;
    height: calc(100vh - 100px - 8vh);
    padding-top: 8vh;
    z-index: 10;
    color: #fff;
    &__ttl {
      text-align: center;
      font-size: 3vh;
      letter-spacing: 0.2em;
      margin: 0 0 8vh;
      font-weight: bold;
      @include sp {
        font-size: 14px;
      }
    }
    &__catch {
      margin-bottom: 7vh;
      font-size: 7vh;
      letter-spacing: 0.4em;
      line-height: 1.6;
      font-feature-settings: "palt";
      box-sizing: border-box;
      padding-left: 0.4em;
      @include sp {
        font-size: 30px;
      }
    }
    &__scroll {
      position: absolute;
      bottom: 132px;
      left: 0;
      right: 0;
      margin: auto;
      font-size: 12px;
      font-weight: 600;
      letter-spacing: 0.15em;
      &::after {
        content: "";
        display: block;
        width: 2px;
        height: 50px;
        background: #fff;
        position: absolute;
        top: 22px;
        left: 0;
        right: 0;
        margin: auto;
      }
      @include sp {
        display: none;
      }
    }
  }
  .kv-slide {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    .slide-bg__inner{
      width: 100%;
      height: calc(100vh - 100px);
      // padding-top: 66.8%;
      position: relative;
      background-repeat: no-repeat;
      background-position: center center;
      -webkit-background-size: cover;
      background-size: cover;
    }
  }
  @for $i from 1 through 4 {
    .slide-item#{$i} {
      background: url(../img/index/kv0#{$i}_pc.png) no-repeat center;
    }
    @include sp {
      .slide-item#{$i} {
        background: url(../img/index/kv0#{$i}_sp.png) no-repeat center;
      }
    }
  }
  .news {
    width: 700px;
    margin: -70px auto 50px;
    position: relative;
    z-index: 10;
    padding: 27px 40px 32px;
    box-sizing: border-box;
    background: #00b9ef;
    color: #fff;
    @include sp {
      width: calc(100% - 40px);
      margin: 25px auto;
      padding: 20px;
    }
    &__ttl {
      margin-bottom: 30px;
      font-size: 18px;
      font-weight: bold;
      letter-spacing: 0.1em;
      @include sp {
        margin-bottom: 0;
        padding-bottom: 17px;
        font-size: 16px;
        text-align: left;
        border-bottom: solid 2px rgba(255,255,255,0.4);
      }
    }
    .more {
      text-align: right;
      @include sp {
        margin-top: 30px;
      }
      a {
        position: relative;
        display: inline-block;
        font-size: 12px;
        color: #fff;
        letter-spacing: 0.15em;
        padding-right: 27px;
        @include sp {
          padding: 0;
        }
        &::before {
          content: "";
          position: absolute;
          bottom: -7px;
          right: 6px;
          display: block;
          width: 1px;
          height: 22px;
          background: #fff;
          @include sp {
            display: none;
          }
        }
        &::after {
          content: "";
          position: absolute;
          bottom: -7px;
          right: 0px;
          display: block;
          width: 6px;
          height: 1px;
          transform: rotate(-45deg);
          transform-origin: 0;
          background: #fff;
          @include sp {
            display: none;
          }
        }
      }
    }
  }
  .news-block {
    display: flex;
    text-align: left;
    padding: 20px 0;
    box-sizing: border-box;
    border-top: solid 2px rgba(255,255,255,0.4);
    @include sp {
      flex-direction: column;
      padding: 20px 0 0;
      border: none;
    }
    &__ttl {
      margin-right: 38px;
      @include sp {
        margin: 0 0 10px;
      }
      .date {
        display: block;
        margin-bottom: 8px;
        font-size: 15px;
        letter-spacing: 0.1em;
        @include sp {
          display: inline-block;
          font-size: 12px;
        }
      }
      .cate {
        display: block;
        width: 120px;
        font-size: 12px;
        line-height: 20px;
        text-align: center;
        color: #fff;
        border-radius: 6px;
        @include sp {
          display: inline-block;
          width: 80px;
          margin-left: 10px;
          font-size: 10px;
        }
        &--seminar {
          background: #e76762;
        }
        &--release {
          background: #00be75;
        }
        &--media {
          background: #f1be0d;
        }
        &--update {
          background: #f1975a;
        }
        &--announce {
          background: #91c0de;
        }
      }
    }
    &__text {
      line-height: 1.6;
      @include sp {
        font-size: 16px;
      }
      a {
        color: #fff;
      }
    }
  }
  // ===========================
  // section2
  // ------------------------------
  .section2 {
    display: flex;
    .wrap {
      width: 100%;
      background: url(../img/index/bg_sec02_pc.png) no-repeat center;
      background-size: cover;
      @include sp {
        background: url(../img/index/bg_sec02_sp.png) no-repeat center;
        background-size: cover;
      }
    }
    .section__catch {
      margin-bottom: 7vh;
      color: #006098;
      @include sp {
        font-size: 30px;
      }
    }
  }
  // ===========================
  // section3
  // ------------------------------
  .section3 {
    display: flex;
    .wrap {
      width: 100%;
      background: url(../img/index/bg_sec03_pc.png) no-repeat center;
      background-size: cover;
      @include sp {
        background: url(../img/index/bg_sec03_sp.png) no-repeat center;
        background-size: cover;
      }
    }
  }
  .potential {
    color: #fff;
    @include sp {
      padding: 25px 0;
    }
    &__ttl {
      margin-bottom: 60px;
      font-size: 20px;
      letter-spacing: 0.2em;
      font-weight: bold;
      @include sp {
        margin-bottom: 30px;
        font-size: 12px;
      }
    }
  }
  .potential-block-wrap {
    display: flex;
    flex-wrap: wrap;
    width: calc(100vw - 100px);
    max-width: 960px;
    margin: 0 auto;
    @include sp {
      width: 100%;
      flex-direction: column;
    }
  }
  .potential-block {
    width: 50%;
    margin-bottom: 80px;
    @include sp {
      width: 100%;
      margin-bottom: 40px;
    }
    &__ttl {
      margin-bottom: 24px;
      font-size: 24px;
      font-weight: bold;
      @include sp {
        margin-bottom: 20px;
        font-size: 16px;
      }
    }
    &__text {
      font-size: 35px;
      font-weight: 600;
      @include sp {
        font-size: 25px;
      }
      .f-raleway {
        font-size: 54px;
        @include sp {
          font-size: 37px;
        }
      }
    }
    &:nth-of-type(1) {
      .potential-block__ttl {
        &:before {
          content: "";
          display: inline-block;
          width: 36px;
          height: 36px;
          margin-right: 10px;
          vertical-align: middle;
          background: url(../img/index/icon_money.png) no-repeat center;
          background-size: contain;
          @include sp {
            width: 23px;
            height: 23px;
          }
        }
      }
    }
    &:nth-of-type(2) {
      .potential-block__ttl {
        &:before {
          content: "";
          display: inline-block;
          width: 36px;
          height: 36px;
          margin-right: 10px;
          vertical-align: middle;
          background: url(../img/index/icon_company.png) no-repeat center;
          background-size: contain;
          @include sp {
            width: 23px;
            height: 23px;
          }
        }
      }
    }
    &:nth-of-type(3) {
      .potential-block__ttl {
        &:before {
          content: "";
          display: inline-block;
          width: 36px;
          height: 36px;
          margin-right: 10px;
          vertical-align: middle;
          background: url(../img/index/icon_man.png) no-repeat center;
          background-size: contain;
          @include sp {
            width: 23px;
            height: 23px;
          }
        }
      }
    }
    &:nth-of-type(4) {
      .potential-block__ttl {
        &:before {
          content: "";
          display: inline-block;
          width: 36px;
          height: 36px;
          margin-right: 10px;
          vertical-align: middle;
          background: url(../img/index/icon_entry.png) no-repeat center;
          background-size: contain;
          @include sp {
            width: 23px;
            height: 23px;
          }
        }
      }
    }
  }
  // ===========================
  // section4
  // ------------------------------
  .section4 {
    display: flex;
    .wrap {
      width: 100%;
      background: url(../img/index/bg_sec04_pc.png) no-repeat center;
      background-size: cover;
      @include sp {
        background: url(../img/index/bg_sec04_sp.png) no-repeat center;
        background-size: cover;
      }
    }
    .section__catch {
      margin-bottom: 7vh;
      color: #006098;
      @include sp {
        font-size: 30px;
      }
    }
  }
  // ===========================
  // section5
  // ------------------------------
  .section5 {
    display: flex;
    .wrap {
      width: 100%;
      background: url(../img/index/bg_sec05_pc.png) no-repeat center;
      background-size: cover;
      @include sp {
        background: url(../img/index/bg_sec05_sp.png) no-repeat center;
        background-size: cover;
      }
    }
    .section__catch {
      margin-bottom: 7vh;
      color: #fff;
      @include sp {
        font-size: 30px;
      }
    }
  }
}


