@charset "UTF-8";

.contact {
  .bg-gray {
    margin-bottom: 50px;
    padding: 50px 0;
    @include sp {
      margin-bottom: 25px;
      padding: 25px 0;
    }
  }
  .contact-table {
    width: 100%;
    margin-bottom: 50px;
    text-align: left;
    @include sp {
      margin-bottom: 0;
    }
    &__ttl {
      width: 30%;
      max-width: 258px;
      font-size: 20px;
      font-weight: bold;
      padding: 30px;
      box-sizing: border-box;
      border-bottom: solid 1px #ccc;
      vertical-align: middle;
      @include sp {
        display: block;
        width: 100%;
        max-width: inherit;
        padding: 0;
        border: none;
        font-size: 16px;
      }
      .ex {
        display: inline-block;
        font-size: 12px;
        font-weight: normal;
        color: #ff0000;
      }
      .note {
        font-size: 14px;
      }
    }
    &__ttl-half {
      width: 30%;
      max-width: 258px;
      font-size: 20px;
      font-weight: bold;
      padding: 30px;
      box-sizing: border-box;
      border-bottom: solid 1px #ccc;
      vertical-align: middle;
      @include sp {
        display: block;
        width: 100%;
        max-width: inherit;
        padding: 0;
        border: none;
        font-size: 16px;
      }
      .ex {
        display: inline-block;
        font-size: 12px;
        font-weight: normal;
        color: #ff0000;
      }
      .note {
        font-size: 14px;
      }
    }
    &__detail {
      width: 70%;
      max-width: 602px;
      padding: 30px 0 30px;
      box-sizing: border-box;
      border-bottom: solid 1px #ccc;
      font-size: 16px;
      @include sp {
        margin-bottom: 40px;
        padding: 15px 0 15px;
        display: block;
        width: 100%;
        max-width: inherit;
      }
    }
    &__detail-half {
      width: 20%;
      max-width: 602px;
      padding: 30px 0 30px;
      box-sizing: border-box;
      border-bottom: solid 1px #ccc;
      font-size: 16px;
      @include sp {
        margin-bottom: 40px;
        padding: 15px 0 15px;
        display: block;
        width: 100%;
        max-width: inherit;
      }
    }
    .inputGroup-wrap {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
    }
    .inputGroup {
      width: 49%;
      background-color: #fff;
      display: block;
      margin: 1% 0;
      position: relative;
      @include sp {
        width: 100%;
      }

      label {
        padding: 16px 16px 16px 52px;
        width: 100%;
        display: block;
        text-align: left;
        color: #3C454C;
        cursor: pointer;
        position: relative;
        z-index: 2;
        transition: color 200ms ease-in;
        overflow: hidden;
        box-sizing: border-box;
        &:before {
          width: 10px;
          height: 10px;
          border-radius: 50%;
          content: '';
          background-color: #00bbf4;
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%) scale3d(1, 1, 1);
          transition: all 300ms cubic-bezier(0.4, 0.0, 0.2, 1);
          opacity: 0;
          z-index: -1;
        }

        &:after {
          width: 32px;
          height: 32px;
          content: '';
          border: 2px solid #D1D7DC;
          background-color: #fff;
          background-image: url("../img/common/check.png");
          background-repeat: no-repeat;
          background-position: center;
          border-radius: 50%;
          z-index: 2;
          position: absolute;
          left: 10px;
          top: 50%;
          transform: translateY(-50%);
          cursor: pointer;
          transition: all 200ms ease-in;
          box-sizing: border-box;
        }
      }

      input:checked ~ label {
        color: #fff;

        &:before {
          transform: translate(-50%, -50%) scale3d(56, 56, 1);
          opacity: 1;
        }

        &:after {
          background-color: #006194;
          border-color: #006194;
        }
      }

      input {
        margin: 0;
        width: 32px;
        height: 32px;
        order: 1;
        z-index: 2;
        position: absolute;
        left: 10px;
        top: 50%;
        transform: translateY(-50%);
        cursor: pointer;
        visibility: hidden;
        @include sp {
          width: 16px;
          height: 16px;
          left: 5px;
        }
      }
    }
    input[type=text],
    textarea {
      outline: none;
      border: 0;
      padding: 16px;
      font-size: 16px;
      color: #444;
      border: solid 1px #ccc;
      width: 100%;
      border-radius: 3px;
      box-shadow: inset 1px 4px 9px -6px rgba(0,0,0,0.5);
      box-sizing: border-box;
      &:focus {
        border: solid 1px #00bbf4;
      }
    }
    .name {   
      input {
        width: 80px;
      }
    }
    .telbox {
      input {
        width: 100%;
      }
    }
    .formsub {
      font-size: 12px;
      display: inline-block;
      margin-top: 10px;
    }
  }
  .submitBox input[type="submit"] {
    -webkit-appearance: none;
    display: block;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
    border: none;
    background-color: #ff3574;
    -webkit-box-shadow: 0 3px #d02d60;
    -moz-box-shadow: 0 3px #d02d60;
    box-shadow: 0 3px #d02d60;
    width: 40%;
    height: auto;
    padding: 10px 0;
    margin: 0 auto;
    font-size: 115%;
    color: #fff;
    font-weight: bold;
    text-align: center;
    letter-spacing: 0.05em;
    cursor: pointer;
    @include sp {
      width: 100%;
    }
  }
  .intro{
    a {
      color: #00b9ef;
    }
    p {
      line-height: 1.5;
      font-size: 16px;
    }
    ul {
      padding-left: 2em;
      line-height: 1.5;
    }
    ul li {
      font-size: 16px;
      list-style: disc;
    }
  }
  .bg-white {
    background-color: white;
    padding: 30px 40px 40px;
    font-size: 1.2em;
    @include sp {
      padding: 1px;
    }
    tr {
      border-bottom: 1px solid #e5e5e5;
    }
    th {
      width: 130px;
      margin: 30px 0;
      font-weight: bold;
      vertical-align: top;
      display: inline-block;
      text-align: left;
      @include sp {
        width: 100%;
        margin: 30px 0 0;
      }
    }
    td {
      font-feature-settings: "palt";
      width: 460px;
      margin: 30px 0;
      line-height: 1.5;
      display: inline-block;
      @include sp {
        width: 100%;
        word-break : break-all;
      }
    }
    a {
      color: #00b9ef;
    }
    .ttl--light-blue{
      margin: 50px 10px;
    }
  }
  .rogo-img {
    width: 100%;
    height: auto;
    margin: 50px 10px 100px;
    @include sp {
      margin: 50px 10px;
    }
  }
  .rogo-img img {
    width: auto;
    margin: 4px 15px;
    margin: 0;
    padding: 3% 12%;
    border: 2px solid #00b9ef;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(102, 102, 102, 0.2);
    @include sp {
      width: 130px;
      margin: 5px 0;
    }
  }
  .title {
    font-size: 20px;
  }
  .data {
    margin: 55px 21px;;
    line-height: 1.2em;
  }
  .title-2 {
    font-size: 20px;
    padding-top: 120px;
  }
  .hrs-data {
    margin: -45px 0 70px;
    @include sp {
      width: 100%;
    }
  }
  .list-data {
    margin: 55px 21px;
    padding: 0 0 55px 1em;
    font-size: 14px;
    line-height: 1.5;
    list-style: disc;
  }
}


.form-area-index{
  #name_box{
    font-size: 0;
  }
  #name_box .subttl{
    width: 4%;
    display: inline-block;
    font-size: 20px;
    font-weight: bold;
    box-sizing: border-box;
    vertical-align: middle;
    @include sp {
      width: 5%;
      font-size: 16px;
      margin-right: 3%;
    }
  }
  #name_box .subttl:last-of-type{
    padding-left: 3%;
  }
  #name_box input{
    width: 43%;
    @include sp {
      width: 40%;
    }
  }
  #name_box input:last-of-type{
    margin-left: 4%;
  }
  .contentValue{
    font-size: 16px;
  }
  .confirm-title{
    display: none;
    text-align: left;
    font-size: 26px;
    font-weight: bold;
    margin-bottom: 20px;
    margin-top: -30px;
  }
  .back-btn{
    -webkit-appearance: none;
    border-radius: 3px;
    border: none;
    background-color: #a9a9a9;
    box-shadow: 0 3px #797979;
    width: 40%;
    height: auto;
    padding: 14px 0;
    margin: 10px auto 0 auto;
    font-size: 115%;
    color: #fff;
    font-weight: bold;
    text-align: center;
    letter-spacing: 0.05em;
    cursor: pointer;
    display: none;
  }  
}
