@charset "UTF-8";
html, body, div, span, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
abbr, address, cite, code,
del, dfn, em, img, ins, kbd, q, samp,
small, strong, sub, sup, var,
b, i,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, figcaption, figure,
footer, header, hgroup, menu, nav, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent; }

html {
  -webkit-text-size-adjust: 100%; }

body {
  color: #333;
  font-family: "游ゴシック体", YuGothic, "游ゴシック", "Yu Gothic", "メイリオ", sans-serif;
  font-weight: 500;
  font-size: 14px;
  line-height: 1; }

article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block; }

ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none; }

a {
  margin: 0;
  padding: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
  text-decoration: none;
  color: #333; }

a:focus {
  outline: 0;
  border-color: orange; }

ins {
  background-color: #ff9;
  color: #000;
  text-decoration: none; }

mark {
  background-color: #ff9;
  color: #000;
  font-style: italic;
  font-weight: bold; }

del {
  text-decoration: line-through; }

abbr[title], dfn[title] {
  border-bottom: 1px dotted;
  cursor: help; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #cccccc;
  margin: 1em 0;
  padding: 0; }

input, select {
  vertical-align: middle; }

body {
  position: relative;
  min-width: 1024px;
  text-align: justify;
  text-justify: inter-ideograph;
  height: 100%; }
  @media screen and (max-width: 767px) {
    body {
      min-width: inherit;
      width: 100%; }
      body.is-fixed {
        position: fixed; } }

.pc-on {
  display: block; }
  @media screen and (max-width: 767px) {
    .pc-on {
      display: none; } }

.sp-on {
  display: none; }
  @media screen and (max-width: 767px) {
    .sp-on {
      display: block; } }

.f-raleway {
  font-family: 'Raleway', sans-serif; }

.f-lato {
  font-family: 'Lato', sans-serif; }

a:hover {
  text-decoration: underline; }
  a:hover img {
    opacity: .7; }

.btn {
  position: relative;
  display: inline-block;
  font-size: 12px;
  padding: 11px 47px 9px 30px;
  box-sizing: border-box;
  letter-spacing: 0.1em; }
  .btn::before {
    content: "";
    position: absolute;
    top: 5px;
    bottom: 0;
    right: 10px;
    margin: auto;
    display: block;
    width: 22px;
    height: 1px; }
  .btn::after {
    content: "";
    position: absolute;
    top: -5px;
    bottom: 0;
    right: 10px;
    margin: auto;
    display: block;
    width: 6px;
    height: 1px;
    transform: rotate(45deg);
    transform-origin: 0; }
  .btn--white {
    color: #fff;
    border: solid 1px #fff; }
    .btn--white::before, .btn--white::after {
      background: #fff; }
  .btn--blue {
    color: #006098;
    border: solid 1px #006098; }
    .btn--blue::before, .btn--blue::after {
      background: #006098; }
  .btn--light-blue {
    color: #00b9ef;
    border: solid 1px #00b9ef; }
    .btn--light-blue::before, .btn--light-blue::after {
      background: #00b9ef; }
  .btn--blank {
    min-width: 250px;
    font-size: 15px;
    text-align: center; }
    @media screen and (max-width: 767px) {
      .btn--blank {
        min-width: 168px;
        font-size: 12px; } }
  .btn:hover {
    opacity: .7;
    text-decoration: none; }

.btn-big-wrap {
  padding-top: 50px; }
  @media screen and (max-width: 767px) {
    .btn-big-wrap {
      padding-top: 25px; } }

.btn-big {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 60%;
  max-width: 600px;
  height: 90px;
  padding: 0;
  vertical-align: middle;
  margin: 0 auto 50px;
  background: #00b9ef;
  border-radius: 8px;
  font-size: 27px;
  color: #fff;
  letter-spacing: 0;
  line-height: 1.4;
  box-shadow: 0px 2px 4px rgba(102, 102, 102, 0.4); }
  @media screen and (max-width: 767px) {
    .btn-big {
      width: 295px;
      height: 45px;
      font-size: 16px;
      border-radius: 4px;
      margin: 0 auto 25px; } }
  .btn-big span {
    font-size: 20px; }
    @media screen and (max-width: 767px) {
      .btn-big span {
        font-size: 12px; } }
  @media screen and (min-width: 768px) {
    .btn-big:hover {
      opacity: .7;
      text-decoration: none; } }

.main {
  position: relative; }

.bg-gray {
  background: #eee; }

.bg-blue {
  background: #edf6fc; }

.low-wrap {
  width: 860px;
  margin: 0 auto; }
  @media screen and (max-width: 767px) {
    .low-wrap {
      width: calc(100% - 40px); } }

.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 999;
  background: #fff;
  box-sizing: border-box; }
  @media screen and (max-width: 767px) {
    .header {
      height: 55px; } }
  .header__logo {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 30px;
    margin: auto;
    display: flex;
    align-items: center;
    width: 140px; }
    .header__logo img {
      width: 100%; }
    @media screen and (max-width: 767px) {
      .header__logo {
        width: 80px; }
        .header__logo img {
          width: 100%; } }
    .header__logo a {
      display: block; }
  .header .menu {
    position: absolute;
    width: 100px;
    right: 10px;
    top: 2px; }
    .header .menu__ttl {
      font-weight: bold;
      font-size: 11px;
      letter-spacing: .4em;
      color: #006da6;
      line-height: 50px; }
    .header .menu__trigger {
      display: inline-block;
      transition: all .4s;
      box-sizing: border-box;
      position: absolute;
      width: 50px;
      height: 50px;
      background-color: transparant;
      padding: 10px;
      z-index: 100;
      right: 0;
      top: 0; }
      .header .menu__trigger span {
        display: inline-block;
        transition: all .4s;
        box-sizing: border-box;
        position: absolute;
        left: 0;
        width: 100%;
        height: 3px;
        background-color: #006da6;
        border-radius: 20px; }
        .header .menu__trigger span:nth-of-type(1) {
          top: 16px;
          left: 25%;
          width: 50%; }
        .header .menu__trigger span:nth-of-type(2) {
          top: 46%;
          left: 25%;
          width: 50%; }
        .header .menu__trigger span:nth-of-type(3) {
          bottom: 17px;
          left: 25%;
          width: 50%; }
      .header .menu__trigger.active span:nth-of-type(1) {
        -webkit-transform: translateY(7px) rotate(-315deg);
        transform: translateY(7px) rotate(-315deg); }
      .header .menu__trigger.active span:nth-of-type(2) {
        opacity: 0; }
      .header .menu__trigger.active span:nth-of-type(3) {
        -webkit-transform: translateY(-7px) rotate(315deg);
        transform: translateY(-7px) rotate(315deg); }
      .header .menu__trigger span:nth-child(1) {
        display: none; }
      .header .menu__trigger span:nth-child(3) {
        transform: rotate(90deg);
        bottom: 24px; }
  .header.active .menu__trigger span:nth-child(3) {
    opacity: 0; }
  @media screen and (max-width: 767px) {
    .header__inner {
      display: none;
      position: absolute;
      top: 55px;
      left: 0;
      width: 100%; }
      .header__inner.active {
        background: rgba(255, 255, 255, 0.5);
        display: block;
        overflow: scroll; } }
  .header__link {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 20px 0 15px; }
    @media screen and (max-width: 767px) {
      .header__link {
        display: none; } }
    .header__link__btn, .header__link__sns {
      display: flex;
      align-items: center; }
    .header__link__btn {
      margin-right: 5px; }
      .header__link__btn .btn {
        margin: 0 15px;
        padding: 7px 13px;
        border: solid 1px #00b9ef;
        border-radius: 4px;
        color: #00b9ef;
        letter-spacing: 0; }
        .header__link__btn .btn::before, .header__link__btn .btn::after {
          display: none; }
    .header__link__sns {
      margin-right: 30px; }
      .header__link__sns a {
        display: block;
        padding: 10px; }
  .header__nav {
    position: relative;
    display: flex;
    justify-content: flex-end;
    width: 100%;
    padding: 0 1% 0 0;
    box-sizing: border-box;
    font-size: 12px; }
    @media screen and (max-width: 767px) {
      .header__nav {
        flex-direction: column;
        padding: 0;
        font-size: 14px; } }
    .header__nav .cate {
      width: 110px;
      line-height: 40px;
      text-align: center; }
      @media screen and (max-width: 767px) {
        .header__nav .cate {
          width: 100%;
          background: #00b9ef;
          font-weight: bold;
          color: #fff;
          line-height: 45px;
          border-bottom: solid 1px #fff;
          position: relative; }
          .header__nav .cate::before, .header__nav .cate::after {
            position: absolute;
            content: "";
            width: 6px;
            height: 1px;
            background: #fff; }
          .header__nav .cate::before {
            transform: rotate(45deg);
            right: 31px;
            top: 20px; }
          .header__nav .cate::after {
            transform: rotate(-45deg);
            right: 31px;
            bottom: 20px; }
          .header__nav .cate a {
            color: #fff; }
          .header__nav .cate.ac__ttl {
            position: relative; }
            .header__nav .cate.ac__ttl::before, .header__nav .cate.ac__ttl::after {
              position: absolute;
              content: "";
              width: 12px;
              height: 1px;
              background: #fff; }
            .header__nav .cate.ac__ttl::before {
              transform: rotate(0deg);
              right: 28px;
              top: 22px; }
            .header__nav .cate.ac__ttl::after {
              transform: rotate(90deg);
              right: 28px;
              top: 22px; } }
      @media screen and (min-width: 768px) {
        .header__nav .cate:hover {
          background: #00b9ef;
          color: #fff; }
          .header__nav .cate:hover a {
            color: #fff; } }
    @media screen and (min-width: 768px) {
      .header__nav .ac__ttl:hover .ac__detail {
        display: block; } }
    .header__nav .ac__detail {
      display: none;
      position: absolute;
      top: 40px;
      left: 0;
      width: 100%;
      height: 100px;
      padding: 10px 15%;
      box-sizing: border-box;
      background: #00b9ef;
      color: #fff;
      z-index: 30; }
      @media screen and (max-width: 767px) {
        .header__nav .ac__detail {
          padding: 0;
          position: static;
          height: auto; } }
      .header__nav .ac__detail ul {
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
        width: 670px;
        margin: 0 auto; }
        @media screen and (max-width: 767px) {
          .header__nav .ac__detail ul {
            flex-direction: column;
            width: 100%;
            text-align: center; } }
        .header__nav .ac__detail ul li {
          margin-right: 70px;
          text-align: left; }
          @media screen and (min-width: 768px) {
            .header__nav .ac__detail ul li.length4 {
              width: 4em; }
            .header__nav .ac__detail ul li.length5 {
              width: 5em; }
            .header__nav .ac__detail ul li.length6 {
              width: 6em; }
            .header__nav .ac__detail ul li.length7 {
              width: 7em; }
            .header__nav .ac__detail ul li.length10 {
              width: 10em; }
            .header__nav .ac__detail ul li.length16 {
              width: 16em; } }
          @media screen and (max-width: 767px) {
            .header__nav .ac__detail ul li {
              margin: 0 0 1px;
              background: #dff2fc;
              text-align: center; }
              .header__nav .ac__detail ul li a {
                color: #444;
                display: block; } }
      .header__nav .ac__detail a {
        color: #fff; }

.lower .header {
  box-shadow: 0 2px 4px rgba(102, 102, 102, 0.2); }

.pankuzu {
  width: 700px;
  margin: 0 auto;
  display: flex;
  padding: 20px 0 30px; }
  .pankuzu li {
    font-size: 12px; }
    .pankuzu li a {
      color: #00b9ef;
      text-decoration: underline; }
    .pankuzu li::after {
      content: ">";
      display: inline-block;
      margin: 0 1em;
      color: #999; }
    .pankuzu li:last-child::after {
      display: none; }
  @media screen and (max-width: 767px) {
    .pankuzu {
      display: none; } }

.lower .kv {
  position: relative;
  margin-bottom: 50px; }
  @media screen and (max-width: 767px) {
    .lower .kv {
      margin-bottom: 25px; } }
  .lower .kv__img {
    width: 100%;
    display: block; }
  .lower .kv__ttl {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    text-align: center;
    height: 1em;
    letter-spacing: 0.25em;
    font-size: 45px;
    color: #fff; }
    .lower .kv__ttl--2 {
      line-height: 1.3;
      height: 2.6em; }
    @media screen and (max-width: 767px) {
      .lower .kv__ttl {
        font-size: 25px; } }
    .lower .kv__ttl--black {
      color: #444; }

.lower .intro {
  width: 860px;
  margin: 0 auto 45px; }
  @media screen and (max-width: 767px) {
    .lower .intro {
      width: calc(100% - 40px);
      margin: 0 auto 20px; } }
  .lower .intro__ttl {
    margin-bottom: 30px;
    font-size: 30px;
    color: #00b9ef;
    line-height: 1.5;
    font-feature-settings: "palt"; }
    @media screen and (max-width: 767px) {
      .lower .intro__ttl {
        margin-bottom: 25px;
        font-size: 20px; } }
  .lower .intro__text {
    font-size: 18px;
    line-height: 1.6; }
    @media screen and (max-width: 767px) {
      .lower .intro__text {
        font-size: 16px; } }

.service-link {
  padding: 50px 0;
  box-sizing: border-box;
  text-align: right; }
  @media screen and (max-width: 767px) {
    .service-link {
      padding: 25px 0; } }
  .service-link--mb {
    margin-bottom: 30px; }
  .service-link--pt0 {
    padding-top: 0px; }
  @media screen and (min-width: 768px) {
    .service-link--pt10 {
      padding-top: 10px; } }
  @media screen and (min-width: 768px) {
    .service-link--pb30 {
      padding-bottom: 30px; } }

.subnav {
  width: 700px;
  padding: 50px 0 10px;
  margin: 0 auto; }
  @media screen and (max-width: 767px) {
    .subnav {
      width: calc(100% - 40px);
      padding: 25px 0 10px; } }
  .subnav__inner {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between; }
  .subnav__block {
    width: 320px;
    margin-bottom: 40px; }
    @media screen and (max-width: 767px) {
      .subnav__block {
        width: 48%;
        margin-bottom: 25px; } }
    .subnav__block__ttl {
      margin-bottom: 10px;
      text-align: center;
      background: #fff;
      font-size: 14px;
      font-weight: bold;
      line-height: 40px;
      color: #666;
      letter-spacing: 0.05em; }
      @media screen and (max-width: 767px) {
        .subnav__block__ttl {
          line-height: 25px;
          font-size: 11px; } }
    .subnav__block__img {
      margin-bottom: 20px; }
      @media screen and (max-width: 767px) {
        .subnav__block__img {
          margin-bottom: 10px; } }
      .subnav__block__img img {
        width: 100%; }
    .subnav__block__text {
      text-align: left; }
      .subnav__block__text .list {
        display: inline-block; }
        @media screen and (max-width: 767px) {
          .subnav__block__text .list {
            font-size: 11px; } }
        .subnav__block__text .list::after {
          content: "|";
          display: inline-block;
          margin: 0 1em; }
        .subnav__block__text .list:last-child::after {
          display: none; }
        .subnav__block__text .list a {
          line-height: 2; }

@media screen and (max-width: 767px) {
  .pagetop-area {
    width: 100%;
    position: fixed;
    bottom: 0;
    background: #ccc;
    padding: 5px 0;
    display: flex;
    z-index: 30; } }

@media screen and (min-width: 768px) {
  .btn-recruit {
    display: none; } }

@media screen and (max-width: 767px) {
  .btn-recruit {
    width: calc(100% - 105px);
    background: #00b9ef;
    font-size: 20px;
    text-align: center;
    border-radius: 3px;
    line-height: 50px;
    margin: 0 15px 0 20px; }
    .btn-recruit a {
      display: block;
      color: #fff;
      text-decoration: none; }
      .btn-recruit a:hover {
        text-decoration: none; } }

.pagetop {
  right: 20px;
  z-index: 30;
  width: 40px;
  height: 40px;
  background: #006da6; }
  .pagetop img {
    display: block;
    width: 100%; }
  @media screen and (max-width: 767px) {
    .pagetop {
      width: 50px;
      height: 50px;
      background: none; } }

.footer {
  width: 100%; }
  .footer__link {
    background: #eeeeee; }
    .footer__link__sns {
      display: flex;
      width: 830px;
      margin: 0 auto;
      padding-bottom: 20px;
      background: #eeeeee; }
      @media screen and (max-width: 767px) {
        .footer__link__sns {
          padding: 5px 0 60px;
          width: 100%;
          background: #fff;
          justify-content: center; } }
      .footer__link__sns a {
        display: block;
        padding: 10px; }
        @media screen and (max-width: 767px) {
          .footer__link__sns a {
            padding: 14px; } }
  .footer__nav {
    width: 830px;
    margin: 0 auto;
    padding: 50px 0 30px;
    display: flex;
    justify-content: space-between;
    font-size: 12px;
    line-height: 30px;
    color: #666;
    background: #eeeeee; }
    @media screen and (max-width: 767px) {
      .footer__nav {
        width: 100%;
        flex-direction: column;
        padding: 0;
        text-align: center;
        font-size: 14px; } }
    .footer__nav .cate {
      margin-bottom: 10px;
      font-weight: bold; }
      @media screen and (max-width: 767px) {
        .footer__nav .cate {
          width: 100%;
          background: #ddd;
          font-weight: bold;
          color: #444;
          line-height: 45px;
          border-bottom: solid 1px #fff;
          position: relative;
          margin-bottom: 0; }
          .footer__nav .cate::before, .footer__nav .cate::after {
            position: absolute;
            content: "";
            width: 6px;
            height: 1px;
            background: #aaa; }
          .footer__nav .cate::before {
            transform: rotate(45deg);
            right: 31px;
            top: 20px; }
          .footer__nav .cate::after {
            transform: rotate(-45deg);
            right: 31px;
            bottom: 20px; }
          .footer__nav .cate a {
            color: #444; }
          .footer__nav .cate.ac__ttl {
            position: relative; }
            .footer__nav .cate.ac__ttl::before, .footer__nav .cate.ac__ttl::after {
              position: absolute;
              content: "";
              width: 12px;
              height: 1px;
              background: #aaa; }
            .footer__nav .cate.ac__ttl::before {
              transform: rotate(0deg);
              right: 28px;
              top: 22px; }
            .footer__nav .cate.ac__ttl::after {
              transform: rotate(90deg);
              right: 28px;
              top: 22px; } }
    .footer__nav .ac__detail {
      font-weight: normal; }
      @media screen and (max-width: 767px) {
        .footer__nav .ac__detail {
          display: none;
          background: #eeeeee; }
          .footer__nav .ac__detail li {
            border-bottom: solid 1px #fff; }
            .footer__nav .ac__detail li a {
              display: block; } }
    .footer__nav a {
      color: #666; }
  @media screen and (max-width: 767px) {
    .footer__bottom {
      padding-top: 20px; } }
  .footer .policy {
    font-size: 12px;
    padding: 30px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center; }
    @media screen and (max-width: 767px) {
      .footer .policy {
        padding: 0 0 20px;
        flex-wrap: wrap;
        line-height: 2;
        font-size: 11px; } }
    .footer .policy li::after {
      content: "|";
      display: inline-block;
      margin: 0 1em; }
    .footer .policy li:last-child::after {
      display: none; }
    .footer .policy a {
      color: #666; }
  .footer .copy {
    text-align: center;
    padding: 30px 0;
    font-size: 10px;
    background: #006194;
    color: #fff; }
    @media screen and (max-width: 767px) {
      .footer .copy {
        padding: 18px 0; } }

.index .main {
  position: relative; }

.index .section {
  width: 100%;
  min-height: 100vh; }
  .index .section.is-show .section-content {
    opacity: 1; }
  .index .section__catch {
    font-size: 7vh;
    letter-spacing: 0.4em;
    line-height: 1.6;
    font-feature-settings: "palt";
    box-sizing: border-box;
    padding-left: 0.4em; }

.index .section-content {
  margin: 0 auto;
  text-align: center; }

.index .wrap {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center; }

.index .sec-top {
  position: relative;
  top: 0px;
  margin-bottom: 50px; }

.index .kv {
  position: relative;
  width: 100%;
  height: calc(100vh - 100px - 8vh);
  padding-top: 8vh;
  z-index: 10;
  color: #fff; }
  .index .kv__ttl {
    text-align: center;
    font-size: 3vh;
    letter-spacing: 0.2em;
    margin: 0 0 8vh;
    font-weight: bold; }
    @media screen and (max-width: 767px) {
      .index .kv__ttl {
        font-size: 14px; } }
  .index .kv__catch {
    margin-bottom: 7vh;
    font-size: 7vh;
    letter-spacing: 0.4em;
    line-height: 1.6;
    font-feature-settings: "palt";
    box-sizing: border-box;
    padding-left: 0.4em; }
    @media screen and (max-width: 767px) {
      .index .kv__catch {
        font-size: 30px; } }
  .index .kv__scroll {
    position: absolute;
    bottom: 132px;
    left: 0;
    right: 0;
    margin: auto;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 0.15em; }
    .index .kv__scroll::after {
      content: "";
      display: block;
      width: 2px;
      height: 50px;
      background: #fff;
      position: absolute;
      top: 22px;
      left: 0;
      right: 0;
      margin: auto; }
    @media screen and (max-width: 767px) {
      .index .kv__scroll {
        display: none; } }

.index .kv-slide {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%; }
  .index .kv-slide .slide-bg__inner {
    width: 100%;
    height: calc(100vh - 100px);
    position: relative;
    background-repeat: no-repeat;
    background-position: center center;
    -webkit-background-size: cover;
    background-size: cover; }

.index .slide-item1 {
  background: url(../img/index/kv01_pc.png) no-repeat center; }

@media screen and (max-width: 767px) {
  .index .slide-item1 {
    background: url(../img/index/kv01_sp.png) no-repeat center; } }

.index .slide-item2 {
  background: url(../img/index/kv02_pc.png) no-repeat center; }

@media screen and (max-width: 767px) {
  .index .slide-item2 {
    background: url(../img/index/kv02_sp.png) no-repeat center; } }

.index .slide-item3 {
  background: url(../img/index/kv03_pc.png) no-repeat center; }

@media screen and (max-width: 767px) {
  .index .slide-item3 {
    background: url(../img/index/kv03_sp.png) no-repeat center; } }

.index .slide-item4 {
  background: url(../img/index/kv04_pc.png) no-repeat center; }

@media screen and (max-width: 767px) {
  .index .slide-item4 {
    background: url(../img/index/kv04_sp.png) no-repeat center; } }

.index .news {
  width: 700px;
  margin: -70px auto 50px;
  position: relative;
  z-index: 10;
  padding: 27px 40px 32px;
  box-sizing: border-box;
  background: #00b9ef;
  color: #fff; }
  @media screen and (max-width: 767px) {
    .index .news {
      width: calc(100% - 40px);
      margin: 25px auto;
      padding: 20px; } }
  .index .news__ttl {
    margin-bottom: 30px;
    font-size: 18px;
    font-weight: bold;
    letter-spacing: 0.1em; }
    @media screen and (max-width: 767px) {
      .index .news__ttl {
        margin-bottom: 0;
        padding-bottom: 17px;
        font-size: 16px;
        text-align: left;
        border-bottom: solid 2px rgba(255, 255, 255, 0.4); } }
  .index .news .more {
    text-align: right; }
    @media screen and (max-width: 767px) {
      .index .news .more {
        margin-top: 30px; } }
    .index .news .more a {
      position: relative;
      display: inline-block;
      font-size: 12px;
      color: #fff;
      letter-spacing: 0.15em;
      padding-right: 27px; }
      @media screen and (max-width: 767px) {
        .index .news .more a {
          padding: 0; } }
      .index .news .more a::before {
        content: "";
        position: absolute;
        bottom: -7px;
        right: 6px;
        display: block;
        width: 1px;
        height: 22px;
        background: #fff; }
        @media screen and (max-width: 767px) {
          .index .news .more a::before {
            display: none; } }
      .index .news .more a::after {
        content: "";
        position: absolute;
        bottom: -7px;
        right: 0px;
        display: block;
        width: 6px;
        height: 1px;
        transform: rotate(-45deg);
        transform-origin: 0;
        background: #fff; }
        @media screen and (max-width: 767px) {
          .index .news .more a::after {
            display: none; } }

.index .news-block {
  display: flex;
  text-align: left;
  padding: 20px 0;
  box-sizing: border-box;
  border-top: solid 2px rgba(255, 255, 255, 0.4); }
  @media screen and (max-width: 767px) {
    .index .news-block {
      flex-direction: column;
      padding: 20px 0 0;
      border: none; } }
  .index .news-block__ttl {
    margin-right: 38px; }
    @media screen and (max-width: 767px) {
      .index .news-block__ttl {
        margin: 0 0 10px; } }
    .index .news-block__ttl .date {
      display: block;
      margin-bottom: 8px;
      font-size: 15px;
      letter-spacing: 0.1em; }
      @media screen and (max-width: 767px) {
        .index .news-block__ttl .date {
          display: inline-block;
          font-size: 12px; } }
    .index .news-block__ttl .cate {
      display: block;
      width: 120px;
      font-size: 12px;
      line-height: 20px;
      text-align: center;
      color: #fff;
      border-radius: 6px; }
      @media screen and (max-width: 767px) {
        .index .news-block__ttl .cate {
          display: inline-block;
          width: 80px;
          margin-left: 10px;
          font-size: 10px; } }
      .index .news-block__ttl .cate--seminar {
        background: #e76762; }
      .index .news-block__ttl .cate--release {
        background: #00be75; }
      .index .news-block__ttl .cate--media {
        background: #f1be0d; }
      .index .news-block__ttl .cate--update {
        background: #f1975a; }
      .index .news-block__ttl .cate--announce {
        background: #91c0de; }
  .index .news-block__text {
    line-height: 1.6; }
    @media screen and (max-width: 767px) {
      .index .news-block__text {
        font-size: 16px; } }
    .index .news-block__text a {
      color: #fff; }

.index .section2 {
  display: flex; }
  .index .section2 .wrap {
    width: 100%;
    background: url(../img/index/bg_sec02_pc.png) no-repeat center;
    background-size: cover; }
    @media screen and (max-width: 767px) {
      .index .section2 .wrap {
        background: url(../img/index/bg_sec02_sp.png) no-repeat center;
        background-size: cover; } }
  .index .section2 .section__catch {
    margin-bottom: 7vh;
    color: #006098; }
    @media screen and (max-width: 767px) {
      .index .section2 .section__catch {
        font-size: 30px; } }

.index .section3 {
  display: flex; }
  .index .section3 .wrap {
    width: 100%;
    background: url(../img/index/bg_sec03_pc.png) no-repeat center;
    background-size: cover; }
    @media screen and (max-width: 767px) {
      .index .section3 .wrap {
        background: url(../img/index/bg_sec03_sp.png) no-repeat center;
        background-size: cover; } }

.index .potential {
  color: #fff; }
  @media screen and (max-width: 767px) {
    .index .potential {
      padding: 25px 0; } }
  .index .potential__ttl {
    margin-bottom: 60px;
    font-size: 20px;
    letter-spacing: 0.2em;
    font-weight: bold; }
    @media screen and (max-width: 767px) {
      .index .potential__ttl {
        margin-bottom: 30px;
        font-size: 12px; } }

.index .potential-block-wrap {
  display: flex;
  flex-wrap: wrap;
  width: calc(100vw - 100px);
  max-width: 960px;
  margin: 0 auto; }
  @media screen and (max-width: 767px) {
    .index .potential-block-wrap {
      width: 100%;
      flex-direction: column; } }

.index .potential-block {
  width: 50%;
  margin-bottom: 80px; }
  @media screen and (max-width: 767px) {
    .index .potential-block {
      width: 100%;
      margin-bottom: 40px; } }
  .index .potential-block__ttl {
    margin-bottom: 24px;
    font-size: 24px;
    font-weight: bold; }
    @media screen and (max-width: 767px) {
      .index .potential-block__ttl {
        margin-bottom: 20px;
        font-size: 16px; } }
  .index .potential-block__text {
    font-size: 35px;
    font-weight: 600; }
    @media screen and (max-width: 767px) {
      .index .potential-block__text {
        font-size: 25px; } }
    .index .potential-block__text .f-raleway {
      font-size: 54px; }
      @media screen and (max-width: 767px) {
        .index .potential-block__text .f-raleway {
          font-size: 37px; } }
  .index .potential-block:nth-of-type(1) .potential-block__ttl:before {
    content: "";
    display: inline-block;
    width: 36px;
    height: 36px;
    margin-right: 10px;
    vertical-align: middle;
    background: url(../img/index/icon_money.png) no-repeat center;
    background-size: contain; }
    @media screen and (max-width: 767px) {
      .index .potential-block:nth-of-type(1) .potential-block__ttl:before {
        width: 23px;
        height: 23px; } }
  .index .potential-block:nth-of-type(2) .potential-block__ttl:before {
    content: "";
    display: inline-block;
    width: 36px;
    height: 36px;
    margin-right: 10px;
    vertical-align: middle;
    background: url(../img/index/icon_company.png) no-repeat center;
    background-size: contain; }
    @media screen and (max-width: 767px) {
      .index .potential-block:nth-of-type(2) .potential-block__ttl:before {
        width: 23px;
        height: 23px; } }
  .index .potential-block:nth-of-type(3) .potential-block__ttl:before {
    content: "";
    display: inline-block;
    width: 36px;
    height: 36px;
    margin-right: 10px;
    vertical-align: middle;
    background: url(../img/index/icon_man.png) no-repeat center;
    background-size: contain; }
    @media screen and (max-width: 767px) {
      .index .potential-block:nth-of-type(3) .potential-block__ttl:before {
        width: 23px;
        height: 23px; } }
  .index .potential-block:nth-of-type(4) .potential-block__ttl:before {
    content: "";
    display: inline-block;
    width: 36px;
    height: 36px;
    margin-right: 10px;
    vertical-align: middle;
    background: url(../img/index/icon_entry.png) no-repeat center;
    background-size: contain; }
    @media screen and (max-width: 767px) {
      .index .potential-block:nth-of-type(4) .potential-block__ttl:before {
        width: 23px;
        height: 23px; } }

.index .section4 {
  display: flex; }
  .index .section4 .wrap {
    width: 100%;
    background: url(../img/index/bg_sec04_pc.png) no-repeat center;
    background-size: cover; }
    @media screen and (max-width: 767px) {
      .index .section4 .wrap {
        background: url(../img/index/bg_sec04_sp.png) no-repeat center;
        background-size: cover; } }
  .index .section4 .section__catch {
    margin-bottom: 7vh;
    color: #006098; }
    @media screen and (max-width: 767px) {
      .index .section4 .section__catch {
        font-size: 30px; } }

.index .section5 {
  display: flex; }
  .index .section5 .wrap {
    width: 100%;
    background: url(../img/index/bg_sec05_pc.png) no-repeat center;
    background-size: cover; }
    @media screen and (max-width: 767px) {
      .index .section5 .wrap {
        background: url(../img/index/bg_sec05_sp.png) no-repeat center;
        background-size: cover; } }
  .index .section5 .section__catch {
    margin-bottom: 7vh;
    color: #fff; }
    @media screen and (max-width: 767px) {
      .index .section5 .section__catch {
        font-size: 30px; } }

.business .ttl {
  margin-bottom: 40px;
  color: #fff;
  font-size: 22px;
  letter-spacing: 0.1em;
  text-align: center;
  line-height: 50px; }
  @media screen and (max-width: 767px) {
    .business .ttl {
      margin-bottom: 25px;
      font-size: 16px;
      line-height: 30px; } }
  .business .ttl--naby {
    background: #006194; }
  .business .ttl--light-blue {
    background: #00b9ef; }
  .business .ttl--orange {
    background: #f39800; }
  .business .ttl--pink {
    background: #ea596c; }
  .business .ttl--light-green {
    background: #62c44c; }
  .business .ttl--green {
    background: #0ca491; }

.business .figure {
  padding: 50px 0px; }
  @media screen and (max-width: 767px) {
    .business .figure {
      padding: 25px 0; } }
  .business .figure .row {
    width: 690px;
    margin: 0 auto;
    display: flex;
    justify-content: flex-end; }
    .business .figure .row--1 {
      box-sizing: border-box;
      padding-left: 100px; }
      @media screen and (max-width: 767px) {
        .business .figure .row--1 {
          padding-left: 48px; } }
    @media screen and (max-width: 767px) {
      .business .figure .row {
        width: 100%; } }

.business .cell-block-wrap {
  display: flex;
  color: #00b9ef;
  width: calc(100% - 100px); }
  @media screen and (max-width: 767px) {
    .business .cell-block-wrap {
      width: calc(100% - 48px); } }

.business .cell {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  color: #00b9ef;
  text-align: center;
  border: solid 1px #00b9ef;
  box-sizing: border-box;
  margin: 10px;
  padding: 13px 20px;
  line-height: 1.6;
  width: 100%; }
  @media screen and (max-width: 767px) {
    .business .cell {
      margin: 4px;
      font-size: 14px;
      min-width: inherit;
      padding: 10px 5px; }
      .business .cell img {
        width: 100%; } }
  .business .cell--rl {
    writing-mode: vertical-rl;
    min-width: inherit;
    padding: 48px 0;
    width: 80px; }
    @media screen and (max-width: 767px) {
      .business .cell--rl {
        padding: 80px 0;
        width: 40px; } }
  .business .cell--btn {
    background: #fff;
    border: solid 2px #00b9ef;
    border-radius: 8px;
    font-weight: bold;
    color: #00b9ef;
    box-shadow: 0 2px 4px rgba(102, 102, 102, 0.2); }
    .business .cell--btn--orange {
      color: #f39800;
      border: solid 2px #f39800; }
    .business .cell--btn--pink {
      color: #ea596c;
      border: solid 2px #ea596c; }
    .business .cell--btn--light-green {
      color: #62c44c;
      border: solid 2px #62c44c; }
    .business .cell--btn--green {
      color: #0ca491;
      border: solid 2px #0ca491; }
  .business .cell img {
    display: block; }

.business .cell-block {
  display: flex;
  flex-wrap: wrap;
  width: 50%; }

.business .sec-index-wrap {
  padding: 50px 0 30px; }
  @media screen and (max-width: 767px) {
    .business .sec-index-wrap {
      padding: 25px 0 0; } }

.business .sec-index {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start; }
  .business .sec-index .cell-block {
    width: 400px;
    margin-bottom: 40px; }
    @media screen and (max-width: 767px) {
      .business .sec-index .cell-block {
        margin-bottom: 25px;
        width: 100%; } }
    .business .sec-index .cell-block__text {
      font-size: 16px;
      line-height: 1.6; }
  .business .sec-index .cell--btn {
    margin: 0 0 30px;
    padding: 0;
    width: 100%;
    min-width: inherit;
    height: 70px;
    position: relative; }
    .business .sec-index .cell--btn::after {
      content: "";
      position: absolute;
      right: 18px;
      width: 8px;
      height: 8px;
      border-top: 2px solid #00b9ef;
      border-right: 2px solid #00b9ef;
      transform: rotate(45deg); }
      @media screen and (max-width: 767px) {
        .business .sec-index .cell--btn::after {
          right: 8px;
          width: 5px;
          height: 5px; } }
    @media screen and (max-width: 767px) {
      .business .sec-index .cell--btn {
        height: 50px; }
        .business .sec-index .cell--btn img {
          width: auto;
          max-width: 220px;
          max-height: 37px; } }
    .business .sec-index .cell--btn--orange::after {
      border-top: 2px solid #f39800;
      border-right: 2px solid #f39800; }
    .business .sec-index .cell--btn--pink::after {
      border-top: 2px solid #ea596c;
      border-right: 2px solid #ea596c; }
    .business .sec-index .cell--btn--light-green::after {
      border-top: 2px solid #62c44c;
      border-right: 2px solid #62c44c; }
    .business .sec-index .cell--btn--green::after {
      border-top: 2px solid #0ca491;
      border-right: 2px solid #0ca491; }

.business .case__ttl {
  margin-bottom: 50px;
  font-size: 30px;
  line-height: 1.5;
  text-align: center; }
  @media screen and (max-width: 767px) {
    .business .case__ttl {
      font-feature-settings: "palt";
      margin-bottom: 25px;
      font-size: 20px; } }
  .business .case__ttl--orange {
    color: #f39800; }
  .business .case__ttl--pink {
    color: #ea596c; }
  .business .case__ttl--light-green {
    color: #62c44c; }
  .business .case__ttl--green {
    color: #0ca491; }
  .business .case__ttl--mb0 {
    margin-bottom: 0; }

.business .case__sub-ttl {
  margin: 10px 0 70px;
  text-align: center;
  font-size: 24px;
  font-weight: bold;
  line-height: 1.6; }
  @media screen and (max-width: 767px) {
    .business .case__sub-ttl {
      margin: 5px 0 30px;
      font-size: 16px; } }

.business .case__detail {
  display: flex; }
  @media screen and (max-width: 767px) {
    .business .case__detail {
      flex-direction: column; } }
  @media screen and (max-width: 767px) {
    .business .case__detail--hiroshima {
      flex-direction: column-reverse; } }
  .business .case__detail__img {
    width: 460px;
    text-align: center; }
    @media screen and (max-width: 767px) {
      .business .case__detail__img {
        width: 252px;
        margin: 0 auto 20px; }
        .business .case__detail__img img {
          width: 100%; } }
    .business .case__detail__img--hiroshima {
      text-align: left;
      margin-bottom: 45px; }
      @media screen and (max-width: 767px) {
        .business .case__detail__img--hiroshima {
          width: 100%; } }
      .business .case__detail__img--hiroshima .case__detail__cap {
        width: 300px;
        margin: 10px 25px 25px;
        font-size: 14px;
        line-height: 2; }
        @media screen and (max-width: 767px) {
          .business .case__detail__img--hiroshima .case__detail__cap {
            width: calc(100% - 40px);
            margin: 16px auto 20px;
            line-height: 1.5;
            font-size: 16px; } }
      .business .case__detail__img--hiroshima .case__detail__photo {
        width: 300px;
        margin: 0 25px; }
        @media screen and (max-width: 767px) {
          .business .case__detail__img--hiroshima .case__detail__photo {
            display: block;
            width: calc(100% - 40px);
            margin: 0 auto; } }
  .business .case__detail__block {
    width: 400px;
    margin-bottom: 50px; }
    .business .case__detail__block--mb0 {
      margin-bottom: 0; }
    @media screen and (max-width: 767px) {
      .business .case__detail__block {
        width: auto; } }
  .business .case__detail__ttl {
    padding-left: 15px;
    margin-bottom: 30px;
    font-size: 24px;
    font-weight: bold;
    font-feature-settings: "palt";
    line-height: 1.4; }
    .business .case__detail__ttl--orange {
      font-size: 20px;
      border-left: solid 4px #f39800; }
    .business .case__detail__ttl--pink {
      font-size: 20px;
      border-left: solid 4px #ea596c; }
    .business .case__detail__ttl--light-green {
      font-size: 20px;
      border-left: solid 4px #62c44c; }
    .business .case__detail__ttl--green {
      border-left: solid 4px #0ca491; }
    @media screen and (max-width: 767px) {
      .business .case__detail__ttl {
        margin-bottom: 25px;
        padding-left: 10px;
        font-size: 16px; } }
  .business .case__detail__text {
    font-size: 16px;
    line-height: 1.6; }
    .business .case__detail__text .indent {
      display: inline-block;
      padding-left: 2em;
      text-indent: -2em; }
  .business .case__detail__note {
    margin-top: 1.6em;
    font-size: 14px;
    line-height: 1.6; }

.business .point {
  width: 700px;
  margin: 0 auto;
  padding: 50px 0;
  box-sizing: border-box; }
  .business .point--mb {
    margin-bottom: 80px; }
    @media screen and (max-width: 767px) {
      .business .point--mb {
        margin-bottom: 25px; } }
  @media screen and (max-width: 767px) {
    .business .point {
      width: calc(100% - 40px);
      padding: 25px 0; } }
  .business .point__ttl {
    margin-bottom: 32px;
    font-size: 24px;
    font-weight: bold;
    text-align: center; }
    @media screen and (max-width: 767px) {
      .business .point__ttl {
        margin-bottom: 25px;
        font-size: 16px; } }
    .business .point__ttl--pink {
      color: #ea596c; }
    .business .point__ttl--light-green {
      color: #62c44c; }
    .business .point__ttl--green {
      color: #0ca491; }
  .business .point__detail {
    background: #fff;
    padding: 30px 0;
    box-sizing: border-box; }
    @media screen and (max-width: 767px) {
      .business .point__detail {
        padding: 25px 0; } }
    .business .point__detail__list {
      width: 590px;
      margin: 0 auto 30px;
      height: 90px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 24px;
      font-weight: bold;
      line-height: 1.4;
      border-radius: 8px;
      text-align: center;
      box-sizing: border-box;
      color: #fff;
      font-feature-settings: "palt"; }
      @media screen and (max-width: 767px) {
        .business .point__detail__list {
          width: calc(100% - 40px);
          height: auto;
          min-height: 60px;
          margin: 0 auto 25px;
          padding: 8px;
          font-size: 14px; } }
      .business .point__detail__list--pink {
        background: #ea596c; }
      .business .point__detail__list--light-green {
        background: #62c44c; }
      .business .point__detail__list--green {
        background: #0ca491; }
      .business .point__detail__list:last-child {
        margin-bottom: 0; }

.business .point-summary {
  position: relative;
  padding: 130px 0 50px;
  text-align: center;
  font-size: 24px;
  line-height: 1.4;
  font-weight: bold; }
  @media screen and (max-width: 767px) {
    .business .point-summary {
      padding: 75px 20px 25px;
      font-size: 16px; } }
  .business .point-summary::before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    margin: auto;
    width: 50px;
    height: 83px;
    background: url(../img/business/icon_arrow_lg.png) no-repeat center;
    background-size: contain; }
    @media screen and (max-width: 767px) {
      .business .point-summary::before {
        width: 31px;
        height: 52px; } }
  .business .point-summary__big {
    display: inline-block;
    margin-top: 15px;
    font-size: 30px; }
    @media screen and (max-width: 767px) {
      .business .point-summary__big {
        margin-top: 10px;
        font-size: 20px; } }
    .business .point-summary__big--light-green {
      color: #62c44c; }

.business .scroll-link {
  margin-bottom: 80px;
  padding: 0 10px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between; }
  @media screen and (max-width: 767px) {
    .business .scroll-link {
      margin-bottom: 30px;
      padding: 0;
      flex-direction: column; } }
  .business .scroll-link .btn--scroll {
    width: 400px;
    color: #fff;
    text-align: center;
    font-size: 20px;
    background: #b5b5b5;
    border-radius: 6px;
    letter-spacing: 0;
    font-feature-settings: "palt";
    padding-bottom: 28px; }
    @media screen and (max-width: 767px) {
      .business .scroll-link .btn--scroll {
        width: 100%;
        margin-bottom: 14px;
        font-size: 16px;
        padding: 8px 8px 28px; }
        .business .scroll-link .btn--scroll:last-child {
          margin-bottom: 0; } }
    .business .scroll-link .btn--scroll::before {
      display: none; }
    .business .scroll-link .btn--scroll::after {
      content: "";
      position: absolute;
      right: 0;
      left: 0;
      bottom: -20px;
      width: 8px;
      height: 8px;
      border-top: 2px solid #fff;
      border-right: 2px solid #fff;
      transform: rotate(135deg); }
    @media screen and (min-width: 768px) {
      .business .scroll-link .btn--scroll--3 {
        width: 260px;
        padding-left: 0;
        padding-right: 0; } }

.business .merit {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between; }
  .business .merit__block {
    width: 400px;
    margin-bottom: 50px; }
    @media screen and (max-width: 767px) {
      .business .merit__block {
        margin-bottom: 25px; } }
    .business .merit__block:last-child {
      margin-bottom: 0; }
  .business .merit__ttl {
    margin-bottom: 30px;
    padding: 16px;
    text-align: center;
    font-size: 20px;
    font-weight: bold;
    color: #fff; }
    @media screen and (max-width: 767px) {
      .business .merit__ttl {
        margin-bottom: 20px;
        font-size: 16px;
        padding: 8px; } }
    .business .merit__ttl--orange {
      background: #fff;
      color: #f39800;
      border: 1px solid #f39800; }
    .business .merit__ttl--light-green {
      background: #62c44c; }
  .business .merit__detail {
    margin-bottom: 40px;
    font-size: 18px;
    line-height: 1.4;
    font-feature-settings: "palt"; }
    @media screen and (max-width: 767px) {
      .business .merit__detail {
        margin-bottom: 25px;
        font-size: 16px; }
        .business .merit__detail .note {
          font-size: 12px; } }
    .business .merit__detail:last-child {
      margin-bottom: 0; }
    .business .merit__detail__ttl {
      margin-bottom: 20px; }
      @media screen and (max-width: 767px) {
        .business .merit__detail__ttl {
          margin-bottom: 10px; } }
    .business .merit__detail__text {
      padding-left: 1em; }
  .business .merit__list {
    margin-bottom: 20px;
    padding: 18px;
    font-size: 18px;
    line-height: 1.4;
    font-weight: bold;
    background: #eee;
    border-radius: 8px;
    text-align: center;
    font-feature-settings: "palt"; }
    @media screen and (max-width: 767px) {
      .business .merit__list {
        padding: 14px;
        font-size: 16px; }
        .business .merit__list .note {
          font-size: 12px; } }
    .business .merit__list:last-child {
      margin-bottom: 0; }
  .business .merit__note {
    margin-top: 20px;
    font-size: 14px;
    line-height: 1.7; }
    @media screen and (max-width: 767px) {
      .business .merit__note {
        font-size: 12px; } }
  .business .merit .fc-blue {
    color: #006194; }
  .business .merit .fc-orange {
    color: #f39800; }

.business .illust {
  text-align: center; }
  .business .illust__ttl {
    margin-bottom: 40px;
    font-size: 24px;
    font-weight: bold; }
    @media screen and (max-width: 767px) {
      .business .illust__ttl {
        margin-bottom: 20px;
        font-size: 16px; } }
    .business .illust__ttl--pink {
      color: #ea596c; }
    .business .illust__ttl--light-green {
      color: #62c44c; }
  .business .illust__text {
    width: 670px;
    margin: 0 auto;
    font-size: 24px;
    line-height: 1.5;
    text-align: left; }
    @media screen and (max-width: 767px) {
      .business .illust__text {
        width: 100%;
        font-size: 14px; } }
  .business .illust__img {
    margin-bottom: 50px; }
    @media screen and (max-width: 767px) {
      .business .illust__img {
        margin-bottom: 25px; } }
    @media screen and (max-width: 767px) {
      .business .illust__img img {
        width: 100%; } }

.business .city {
  width: 700px;
  margin: 0 auto 80px;
  padding: 50px 0; }
  @media screen and (max-width: 767px) {
    .business .city {
      width: calc(100% - 40px);
      margin: 0 auto 40px;
      padding: 25px 0; } }
  .business .city__ttl {
    margin-bottom: 32px;
    text-align: center;
    font-size: 24px;
    font-weight: bold; }
    @media screen and (max-width: 767px) {
      .business .city__ttl {
        margin-bottom: 20px;
        font-size: 16px; } }
    .business .city__ttl--light-green {
      color: #62c44c; }
  .business .city__detail {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between; }
  .business .city__list {
    width: 320px;
    margin-bottom: 20px;
    text-align: center;
    color: #fff;
    font-size: 20px;
    line-height: 50px;
    font-weight: bold; }
    @media screen and (max-width: 767px) {
      .business .city__list {
        width: 48%;
        margin-bottom: 15px;
        font-size: 16px;
        line-height: 30px; } }
    .business .city__list--light-green {
      background: #62c44c; }
    .business .city__list:last-child {
      margin-bottom: 0; }

/*privacy_policy*/
.privacy_policy .main, .security_policy .main, .product_security .main {
  padding-top: 55px; }
  .privacy_policy .main .bg-gray, .security_policy .main .bg-gray, .product_security .main .bg-gray {
    background-color: #eee;
    padding-bottom: 5px; }
  .privacy_policy .main .intro, .security_policy .main .intro, .product_security .main .intro {
    width: 700px; }
    @media screen and (max-width: 767px) {
      .privacy_policy .main .intro, .security_policy .main .intro, .product_security .main .intro {
        width: calc(100% - 40px); } }

.privacy_policy .contents, .security_policy .contents, .product_security .contents {
  width: 700px;
  margin: 0 auto 45px;
  background-color: #ffffff; }
  @media screen and (max-width: 767px) {
    .privacy_policy .contents, .security_policy .contents, .product_security .contents {
      width: calc(100% - 40px); } }
  .privacy_policy .contents__blocks, .security_policy .contents__blocks, .product_security .contents__blocks {
    padding: 50px 50px 20px 50px;
    margin-bottom: 50px; }
    @media screen and (max-width: 767px) {
      .privacy_policy .contents__blocks, .security_policy .contents__blocks, .product_security .contents__blocks {
        padding: 25px 20px 0px 20px;
        margin-bottom: -25px; } }
  .privacy_policy .contents__block, .security_policy .contents__block, .product_security .contents__block {
    line-height: 22px; }
  .privacy_policy .contents__block p, .privacy_policy .contents__block ul, .privacy_policy .contents__block h2, .security_policy .contents__block p, .security_policy .contents__block ul, .security_policy .contents__block h2, .product_security .contents__block p, .product_security .contents__block ul, .product_security .contents__block h2 {
    padding-bottom: 25px; }
  .privacy_policy .contents__block p, .privacy_policy .contents__block ul, .security_policy .contents__block p, .security_policy .contents__block ul, .product_security .contents__block p, .product_security .contents__block ul {
    font-size: 16px; }
  .privacy_policy .contents__block h2, .security_policy .contents__block h2, .product_security .contents__block h2 {
    font-size: 18px; }
  .privacy_policy .contents__block .bold, .security_policy .contents__block .bold, .product_security .contents__block .bold {
    font-weight: bold; }
  .privacy_policy .contents__block--lightblue, .security_policy .contents__block--lightblue, .product_security .contents__block--lightblue {
    color: #00b9ef; }
  .privacy_policy .contents__block ul li, .security_policy .contents__block ul li, .product_security .contents__block ul li {
    text-indent: -1em;
    padding-left: 1em; }
  .privacy_policy .contents__block ul li:before, .security_policy .contents__block ul li:before, .product_security .contents__block ul li:before {
    content: "●";
    color: #bae3f9;
    font-size: 10px;
    padding-right: 5px; }

.privacy_policy .low-wrap, .security_policy .low-wrap, .product_security .low-wrap {
  width: 860px;
  margin: 0 auto; }
  @media screen and (max-width: 767px) {
    .privacy_policy .low-wrap, .security_policy .low-wrap, .product_security .low-wrap {
      width: calc(100% - 40px); } }
  .privacy_policy .low-wrap .btn.margin, .security_policy .low-wrap .btn.margin, .product_security .low-wrap .btn.margin {
    margin-bottom: 30px; }
    @media screen and (max-width: 767px) {
      .privacy_policy .low-wrap .btn.margin, .security_policy .low-wrap .btn.margin, .product_security .low-wrap .btn.margin {
        margin-bottom: 25px; } }

.privacy_policy .btn--light-blue, .security_policy .btn--light-blue, .product_security .btn--light-blue {
  color: #00b9ef !important;
  border: solid 1px #00b9ef !important;
  width: 260px;
  text-align: center;
  font-weight: bold; }
  @media screen and (max-width: 767px) {
    .privacy_policy .btn--light-blue, .security_policy .btn--light-blue, .product_security .btn--light-blue {
      width: 220px;
      padding-right: 9%;
      padding-left: 1%;
      font-weight: bold; } }

.privacy_policy .btn--light-blue::before, .privacy_policy .btn--light-blue::after, .security_policy .btn--light-blue::before, .security_policy .btn--light-blue::after, .product_security .btn--light-blue::before, .product_security .btn--light-blue::after {
  background: #00b9ef; }

.security_policy .main .contents__0 h2 {
  color: #333; }

.security_policy .main .contents__0 img {
  display: none; }
  @media screen and (max-width: 767px) {
    .security_policy .main .contents__0 img {
      display: inline;
      width: 40%;
      padding-bottom: 5%; } }

.security_policy .main .contents__1 p {
  border-bottom: solid 2px #eee;
  margin-bottom: 5%; }
  @media screen and (max-width: 767px) {
    .security_policy .main .contents__1 p {
      margin-bottom: 25px; } }

@media screen and (max-width: 767px) {
  .product_security .main .contents__1 {
    padding-top: 0px !important;
    border-top: none !important; } }

.product_security .main .contents__block {
  border-top: solid 2px #eee;
  padding-top: 25px; }
  .product_security .main .contents__block a {
    color: #00b9ef; }
  @media screen and (max-width: 767px) {
    .product_security .main .contents__block h2 {
      color: #333; } }

.product_security .main .contents__0 {
  border-top: none;
  padding-top: 0%; }

/*sitemap*/
.sitemap .main {
  padding-top: 55px; }
  .sitemap .main .bg-gray {
    background-color: #eee;
    padding-bottom: 50px; }
    @media screen and (max-width: 767px) {
      .sitemap .main .bg-gray {
        padding-bottom: 25px; } }
  .sitemap .main .intro {
    width: 800px;
    padding-top: 50px; }
    @media screen and (max-width: 767px) {
      .sitemap .main .intro {
        width: calc(100% - 40px);
        padding-top: 25px; } }

.sitemap .contents {
  width: 800px;
  margin: 0 auto;
  background-color: #ffffff;
  overflow: hidden; }
  @media screen and (max-width: 767px) {
    .sitemap .contents {
      width: calc(100% - 40px); } }
  .sitemap .contents__blocks {
    padding: 0px 50px 0px 50px; }
    @media screen and (max-width: 767px) {
      .sitemap .contents__blocks {
        padding: 0px 20px 25px 20px;
        margin-bottom: -25px; } }
  .sitemap .contents__block {
    line-height: 22px;
    border-bottom: 1px solid #eee; }
    @media screen and (max-width: 767px) {
      .sitemap .contents__block {
        border-bottom: none; } }
  .sitemap .contents__block.last {
    line-height: 22px;
    border-bottom: none; }
  .sitemap .contents__block p, .sitemap .contents__block ul, .sitemap .contents__block h2 {
    padding-bottom: 25px; }
  @media screen and (max-width: 767px) {
    .sitemap .contents__block ul {
      padding-bottom: 10px; } }
  .sitemap .contents__block p, .sitemap .contents__block ul {
    font-size: 16px; }
  .sitemap .contents__block h2 {
    font-size: 18px; }
  .sitemap .contents__block .bold {
    font-weight: bold; }
  .sitemap .contents__block--lightblue {
    color: #00b9ef; }
  .sitemap .contents__block ul li {
    padding-left: 10px; }
    @media screen and (max-width: 767px) {
      .sitemap .contents__block ul li {
        font-size: 14px; } }
  .sitemap .contents__block ul li img {
    width: 5px;
    padding-right: 5px; }
  .sitemap .contents__block ul li:before {
    /*content: "＞";
      font-weight: bold;
      color: #00b9ef;
      font-size: 12px;
      padding-right: 5px;*/ }

.sitemap .content {
  margin-top: 50px; }
  @media screen and (max-width: 767px) {
    .sitemap .content {
      margin-top: 0px; } }
  @media screen and (max-width: 767px) {
    .sitemap .content__rightbox, .sitemap .content__leftbox h2 {
      padding-top: 25px; } }
  .sitemap .content__rightbox {
    float: left;
    width: 300px;
    padding-left: 50px; }
    @media screen and (max-width: 767px) {
      .sitemap .content__rightbox {
        float: none;
        padding-left: 0px;
        border-bottom: 1px solid #eee;
        width: 100%; } }
  .sitemap .content__leftbox {
    float: left;
    width: 350px; }
    @media screen and (max-width: 767px) {
      .sitemap .content__leftbox {
        float: none;
        border-bottom: 1px solid #eee;
        width: 100%; } }
  .sitemap .content__leftbox.last h2:first-child {
    padding-bottom: 50px;
    border-bottom: 1px solid #eee;
    margin-bottom: 50px; }
    @media screen and (max-width: 767px) {
      .sitemap .content__leftbox.last h2:first-child {
        padding-bottom: 25px;
        border-bottom: none;
        margin-bottom: 0px; } }
  .sitemap .content li {
    padding-bottom: 20px; }
    @media screen and (max-width: 767px) {
      .sitemap .content li {
        padding-bottom: 15px; } }
  .sitemap .content .clear {
    clear: both; }

.lower .intro_short {
  width: 700px;
  margin: 0 auto; }
  @media screen and (max-width: 767px) {
    .lower .intro_short {
      width: calc(100% - 40px);
      margin: 0 auto; } }
  .lower .intro_short__ttl {
    font-size: 30px;
    color: #00b9ef;
    line-height: 1.5;
    font-feature-settings: "palt"; }
    @media screen and (max-width: 767px) {
      .lower .intro_short__ttl {
        margin-bottom: 25px;
        font-size: 19px; } }
  .lower .intro_short__text {
    font-size: 18px;
    line-height: 1.6; }
    @media screen and (max-width: 767px) {
      .lower .intro_short__text {
        font-size: 16px; } }

.lower .kv {
  position: relative;
  margin-bottom: 50px; }
  @media screen and (max-width: 767px) {
    .lower .kv {
      margin-bottom: 25px; } }
  .lower .kv__ttl--2l {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    text-align: center;
    height: 3em;
    letter-spacing: 0.3em;
    font-size: 45px;
    color: #fff;
    line-height: 1.4; }
    @media screen and (max-width: 767px) {
      .lower .kv__ttl--2l {
        font-size: 25px; } }

.lower .kv2 {
  position: relative;
  margin: 0; }

.is-block {
  display: block;
  width: 250px;
  margin: 30px 0 0 calc(100% - 250px); }
  @media screen and (max-width: 767px) {
    .is-block {
      width: 200px;
      margin: 30px 20px 0 calc(100% - 200px); } }

.company .books__ttl {
  margin: 0 0 30px calc(100% - 460px);
  font-size: 26px;
  line-height: 1.5;
  color: #00b9ef; }
  @media screen and (max-width: 767px) {
    .company .books__ttl {
      font-feature-settings: "palt";
      margin: 0 auto 18px;
      text-align: center;
      font-size: 20px; } }
  .company .books__ttl--light-blue {
    color: #00b9ef; }
  .company .books__ttl--mb0 {
    margin-bottom: 0; }

.company .books__sub-ttl {
  margin: 10px 0 70px;
  text-align: center;
  font-size: 24px;
  font-weight: bold;
  line-height: 1.6; }
  @media screen and (max-width: 767px) {
    .company .books__sub-ttl {
      margin: 5px 0 30px;
      font-size: 16px; } }

.company .books__detail {
  display: flex;
  margin-bottom: 130px; }
  @media screen and (max-width: 767px) {
    .company .books__detail {
      flex-direction: column;
      margin-bottom: 40px; } }
  .company .books__detail__img {
    width: 400px;
    text-align: center; }
    @media screen and (max-width: 767px) {
      .company .books__detail__img {
        width: 100%;
        margin: 0 auto 20px; }
        .company .books__detail__img img {
          width: 60%; } }
  .company .books__detail__auth {
    width: 400px;
    text-align: left;
    font-size: 16px;
    line-height: 1.6; }
    @media screen and (max-width: 767px) {
      .company .books__detail__auth {
        width: 100%;
        margin: 0 auto 20px; } }
    .company .books__detail__auth dl {
      display: flex; }
      .company .books__detail__auth dl dt {
        width: 40px; }
      .company .books__detail__auth dl dd {
        width: 300px; }
        .company .books__detail__auth dl dd:before {
          content: ":";
          margin: 0 0.2em; }
    .company .books__detail__auth li:first-child {
      margin-top: 45px; }
    .company .books__detail__auth li a {
      color: #0e49bd; }
  .company .books__detail__desc {
    width: 460px;
    text-align: left;
    font-size: 16px;
    line-height: 1.6; }
    @media screen and (max-width: 767px) {
      .company .books__detail__desc {
        width: 100%;
        margin: 0 auto; } }
    .company .books__detail__desc a {
      color: #0e49bd; }
    .company .books__detail__desc h3 {
      font-size: 20px;
      color: #00b9ef;
      margin: 35px 0 30px; }
    .company .books__detail__desc ul {
      width: 460px;
      list-style-position: inside; }
      @media screen and (max-width: 767px) {
        .company .books__detail__desc ul {
          width: 100%; } }
      .company .books__detail__desc ul li {
        list-style: none;
        width: 400px;
        padding-left: 1em;
        text-indent: -1em; }
        @media screen and (max-width: 767px) {
          .company .books__detail__desc ul li {
            width: 100%; } }
        .company .books__detail__desc ul li:before {
          content: "";
          width: 8px;
          height: 8px;
          display: inline-block;
          background-color: #000;
          border-radius: 50%;
          margin-right: 1em; }
    .company .books__detail__desc dl {
      width: 340px;
      display: flex; }
      @media screen and (max-width: 767px) {
        .company .books__detail__desc dl {
          width: 100%; } }
      .company .books__detail__desc dl dt {
        width: 80px; }
      .company .books__detail__desc dl dd {
        width: 260px; }
  .company .books__detail__block {
    width: 400px;
    margin-bottom: 50px; }
    .company .books__detail__block--mb0 {
      margin-bottom: 0; }
    @media screen and (max-width: 767px) {
      .company .books__detail__block {
        width: auto; } }
  .company .books__detail__ttl {
    padding-left: 15px;
    margin-bottom: 30px;
    font-size: 24px;
    font-weight: bold;
    font-feature-settings: "palt";
    line-height: 1.4; }
    @media screen and (max-width: 767px) {
      .company .books__detail__ttl {
        margin-bottom: 25px;
        padding-left: 10px;
        font-size: 16px; } }
  .company .books__detail__text {
    font-size: 16px;
    line-height: 1.6; }
    .company .books__detail__text .indent {
      display: inline-block;
      padding-left: 2em;
      text-indent: -2em; }
  .company .books__detail__note {
    margin-top: 1.6em;
    font-size: 14px;
    line-height: 1.6; }

.company .main {
  margin: 0;
  padding: 0;
  position: relative; }
  .company .main .second {
    margin: 0 0 30px; }
  .company .main .third {
    margin: 0 0 50px; }
    @media screen and (max-width: 767px) {
      .company .main .third {
        margin: 0 0 18px; } }

.company .bg-gray {
  background: #eee; }

.company .low-wrap {
  width: 700px;
  margin: 0 auto; }
  @media screen and (max-width: 767px) {
    .company .low-wrap {
      width: calc(100% - 40px); } }

.company .low-wrap--long {
  width: 860px;
  margin: 0 auto; }
  @media screen and (max-width: 767px) {
    .company .low-wrap--long {
      width: calc(100% - 40px); } }

.ttl {
  margin-bottom: 40px;
  color: #fff;
  font-size: 22px;
  letter-spacing: 0.1em;
  text-align: center;
  line-height: 50px; }
  @media screen and (max-width: 767px) {
    .ttl {
      margin-bottom: 25px;
      font-size: 16px;
      line-height: 30px; } }
  .ttl--light-blue {
    background: #00b9ef; }
  .ttl--orange {
    background: #f39800; }
  .ttl--pink {
    background: #ea596c; }
  .ttl--light-green {
    background: #62c44c; }
  .ttl--green {
    background: #0ca491; }

.figure {
  padding-top: 20px; }
  @media screen and (max-width: 767px) {
    .figure {
      padding: 25px 0 0; } }
  .figure .inner {
    width: 100%; }
    .figure .inner img {
      width: 100%; }
    @media screen and (max-width: 767px) {
      .figure .inner {
        width: 100%; }
        .figure .inner img {
          width: 100%; } }

.message {
  padding-top: 50px; }
  @media screen and (max-width: 767px) {
    .message {
      padding: 35px 0 0; } }
  .message .inner {
    width: 100%;
    font-size: 16px;
    line-height: 1.6; }
    .message .inner p {
      margin-bottom: 40px; }
      @media screen and (max-width: 767px) {
        .message .inner p {
          margin-bottom: 32px; } }

.mission {
  padding-top: 0; }
  @media screen and (max-width: 767px) {
    .mission {
      padding: 0 0 0; } }
  .mission .inner {
    width: 100%;
    font-size: 16px;
    line-height: 1.6; }
    .mission .inner p {
      margin-bottom: 40px; }
      @media screen and (max-width: 767px) {
        .mission .inner p {
          margin-bottom: 32px; } }
    .mission .inner .decla {
      width: 100%;
      font-weight: bold;
      font-feature-settings: "palt";
      text-align: center;
      padding-bottom: 50px; }
      @media screen and (max-width: 767px) {
        .mission .inner .decla {
          padding-bottom: 22px; } }
      .mission .inner .decla h2 {
        color: #00b9ef;
        font-size: 27px;
        margin: 0;
        padding: 55px 0 20px;
        border-bottom: 1px solid #bbbbbb; }
        @media screen and (max-width: 767px) {
          .mission .inner .decla h2 {
            font-size: 16px;
            padding: 22px 0 12px; } }
      .mission .inner .decla .desc {
        color: #444444;
        font-size: 34px;
        line-height: 1.5;
        margin: 50px 0 0;
        padding: 0 0 45px; }
        @media screen and (max-width: 767px) {
          .mission .inner .decla .desc {
            font-size: 18px;
            margin: 0;
            padding: 27px 0 20px; } }
      .mission .inner .decla .desc__short {
        color: #444444;
        font-size: 34px;
        line-height: 1;
        margin: 50px 0 0;
        padding: 0 0 45px; }
        .mission .inner .decla .desc__short span {
          font-size: 24px; }
        @media screen and (max-width: 767px) {
          .mission .inner .decla .desc__short {
            font-size: 18px;
            margin: 0;
            padding: 27px 0 20px; }
            .mission .inner .decla .desc__short span {
              margin: -5px 0 0;
              font-size: 12px; } }
      .mission .inner .decla dl {
        width: 700px;
        margin-bottom: 30px;
        color: #fff;
        background-color: #00b9ef;
        vertical-align: middle;
        border-radius: 5px;
        font-family: "Raleway","游ゴシック体", YuGothic, "游ゴシック", "Yu Gothic", "メイリオ", sans-serif; }
        @media screen and (max-width: 767px) {
          .mission .inner .decla dl {
            width: 100%;
            margin-bottom: 15px; } }
      .mission .inner .decla dt {
        width: auto;
        margin: 0 20px 0 5px;
        font-size: 26px;
        text-align: center;
        display: inline-block;
        font-weight: normal; }
        @media screen and (max-width: 767px) {
          .mission .inner .decla dt {
            margin: 0 8px 0 0;
            font-size: 13px; } }
      .mission .inner .decla .is-long {
        width: auto;
        margin: 0 12px 0 0;
        font-size: 26px;
        text-align: center;
        display: inline-block;
        font-weight: normal; }
        @media screen and (max-width: 767px) {
          .mission .inner .decla .is-long {
            margin: 0 5px 0 0;
            font-size: 13px; } }
      .mission .inner .decla dd:before {
        content: "";
        width: 1px;
        height: 40px;
        background: #fff;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        margin: auto;
        display: block;
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
        -webkit-transform-origin: 0;
        transform-origin: 0; }
        @media screen and (max-width: 767px) {
          .mission .inner .decla dd:before {
            height: 20px; } }
      .mission .inner .decla dd {
        position: relative;
        width: calc(100% - 60px - 1em);
        margin: 0;
        padding: 6px 0 10px 0px;
        font-size: 20px;
        text-align: left;
        display: inline-block;
        font-weight: normal; }
        @media screen and (max-width: 767px) {
          .mission .inner .decla dd {
            width: calc(100% - 30px - 1em);
            padding: 8px 0 7px 0;
            font-size: 10px; } }
        .mission .inner .decla dd span {
          margin: 0 0.5em 0 1em;
          font-size: 27px;
          font-weight: bold;
          font-family: "游ゴシック体", YuGothic, "游ゴシック", "Yu Gothic", "メイリオ", sans-serif; }
          @media screen and (max-width: 767px) {
            .mission .inner .decla dd span {
              font-size: 13px; } }

.profile {
  padding-bottom: 35px; }
  .profile .intro__ttl {
    margin: 0;
    padding: 50px 0; }
    @media screen and (max-width: 767px) {
      .profile .intro__ttl {
        padding: 25px 0; } }
  .profile .inner {
    width: 100%;
    background-color: #fff; }
    .profile .inner img {
      width: 100%; }
    .profile .inner .table {
      width: 600px;
      margin: 0 auto;
      line-height: 1.6; }
      @media screen and (max-width: 767px) {
        .profile .inner .table {
          width: 88%; } }
      .profile .inner .table tr {
        font-size: 16px;
        border-bottom: 1px solid #e5e5e5; }
      .profile .inner .table tr:last-child {
        border-bottom: none; }
      .profile .inner .table th {
        width: 130px;
        margin: 30px 0;
        font-weight: bold;
        vertical-align: top;
        display: inline-block; }
        @media screen and (max-width: 767px) {
          .profile .inner .table th {
            width: 100%;
            margin: 20px 0; } }
      .profile .inner .table td {
        font-feature-settings: "palt";
        width: 460px;
        margin: 30px 0;
        display: inline-block; }
        @media screen and (max-width: 767px) {
          .profile .inner .table td {
            width: 100%;
            margin: 0 0 20px; }
            .profile .inner .table td > div > a {
              color: #00b9ef; } }
        .profile .inner .table td .bus__cont--first li {
          line-height: 1.6;
          font-size: 16px;
          margin: 0 0 30px 0;
          padding: 0 0 0 1em; }
          .profile .inner .table td .bus__cont--first li a {
            color: #00b9ef; }
        .profile .inner .table td .bus__cont--first li:last-child {
          margin: 0; }
        .profile .inner .table td .bus__cont--first li:before {
          content: "";
          width: 10px;
          height: 10px;
          display: inline-block;
          background-color: #bfbfbf;
          border-radius: 50%;
          margin: 0 5px 0 -1em; }
        .profile .inner .table td .bus__cont--second li {
          line-height: 1.6;
          font-size: 16px;
          margin: 0;
          padding: 0 0 0 1em; }
        .profile .inner .table td .bus__cont--second li:before {
          content: "";
          width: 8px;
          height: 8px;
          display: inline-block;
          background-color: #aadeed;
          border-radius: 50%;
          margin: 0 5px 0 -1em; }
  .profile .capt {
    width: 100%;
    height: auto;
    line-height: 1.6;
    font-size: 16px;
    margin: 30px 0 100px; }
    @media screen and (max-width: 767px) {
      .profile .capt {
        margin: 25px 0 50px; } }
  .profile .inner_cont {
    padding: 55px 0; }
    @media screen and (max-width: 767px) {
      .profile .inner_cont {
        padding: 20px 0; } }
  .profile .root {
    width: 600px;
    margin: 0 auto;
    border-bottom: 1px solid #e5e5e5; }
    @media screen and (max-width: 767px) {
      .profile .root {
        width: 88%; } }
    .profile .root h2 {
      font-size: 18px;
      line-height: 1.6;
      color: #00b9ef;
      margin: 30px 0 25px; }
    .profile .root .desc {
      font-size: 16px;
      line-height: 1.6;
      margin-bottom: 30px; }
  .profile .root:last-child {
    margin: 0 auto;
    border-bottom: none; }

.act_date {
  width: 100%;
  font-size: 15px;
  text-align: right;
  margin-bottom: 150px; }
  @media screen and (max-width: 767px) {
    .act_date {
      margin-bottom: 70px; } }

.map {
  position: relative;
  width: 32px;
  height: 20px;
  margin: 15px 0 0;
  padding: 2px 24px 4px;
  color: #fff;
  font-weight: bold;
  border-radius: 3px;
  background-color: #00b9ef;
  display: inline-block; }
  @media screen and (max-width: 767px) {
    .map {
      width: 28px;
      padding: 5px 12px 4px;
      font-size: 14px; } }

.map:after {
  position: absolute;
  top: 5px;
  left: 90px;
  width: 15px;
  height: 14px;
  content: url("../img/company/link.png");
  display: inline-block; }
  @media screen and (max-width: 767px) {
    .map:after {
      left: 60px; } }

.access {
  position: relative;
  width: 100%; }
  .access div {
    position: absolute;
    left: 352px;
    bottom: 0;
    color: #00b9ef;
    font-size: 12px; }
  .access div:hover {
    text-decoration: underline solid #00b9ef; }

.item {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between; }
  .item .card {
    width: 320px; }
    @media screen and (max-width: 767px) {
      .item .card {
        width: 48%; } }
    .item .card img {
      width: 320px; }
      @media screen and (max-width: 767px) {
        .item .card img {
          width: 100%; } }

.message .rel {
  width: 320px;
  margin: 20px 0 50px 390px; }
  @media screen and (max-width: 767px) {
    .message .rel {
      width: 235px;
      margin: 25px 0 25px calc(100% - 235px); } }
  .message .rel a {
    text-decoration: none; }
    .message .rel a .item {
      margin: 30px 0 0;
      padding: 0; }
      @media screen and (max-width: 767px) {
        .message .rel a .item {
          margin: 20px 0 0; } }
      .message .rel a .item p {
        font-size: 14px;
        line-height: 1.4;
        color: #00b9ef;
        padding: 7px 12px 6px 42px;
        border: 1px solid #00b9ef; }
        @media screen and (max-width: 767px) {
          .message .rel a .item p {
            font-size: 12px;
            line-height: 1.2;
            padding: 8px 5px 7px 15px; } }
      .message .rel a .item p:after {
        width: 30px;
        margin: 0 0 0 1em;
        content: url("../img/company/btn.png"); }
        @media screen and (max-width: 767px) {
          .message .rel a .item p:after {
            width: 21px;
            margin: 0 0 0 0.5em;
            content: url("../img/company/btn_sp.png"); } }

.mission .rel {
  width: 171px;
  margin: 20px 0 50px 529px; }
  @media screen and (max-width: 767px) {
    .mission .rel {
      width: 235px;
      margin: 25px 0 25px calc(100% - 235px); } }
  .mission .rel a {
    text-decoration: none; }
    .mission .rel a .item {
      margin: 30px 0 0;
      padding: 0; }
      @media screen and (max-width: 767px) {
        .mission .rel a .item {
          margin: 20px 0 0; } }
      .mission .rel a .item p {
        font-size: 14px;
        line-height: 1.4;
        color: #00b9ef;
        padding: 7px 12px 6px 42px;
        border: 1px solid #00b9ef; }
        @media screen and (max-width: 767px) {
          .mission .rel a .item p {
            font-size: 12px;
            line-height: 1.2;
            padding: 8px 5px 7px 15px; } }
      .mission .rel a .item p:after {
        width: 30px;
        margin: 0 0 0 1em;
        content: url("../img/company/btn.png"); }
        @media screen and (max-width: 767px) {
          .mission .rel a .item p:after {
            width: 21px;
            margin: 0 0 0 0.5em;
            content: url("../img/company/btn_sp.png"); } }

.bigdata .data-wrap {
  padding: 50px 0;
  width: 860px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap; }
  @media screen and (max-width: 767px) {
    .bigdata .data-wrap {
      padding: 25px 0;
      width: calc(100% - 40px); } }

.bigdata .data {
  width: 400px;
  margin-bottom: 70px; }
  @media screen and (max-width: 767px) {
    .bigdata .data {
      width: 100%;
      margin-bottom: 50px; } }
  @media screen and (min-width: 768px) {
    .bigdata .data:nth-child(3), .bigdata .data:nth-child(4) {
      margin-bottom: 0; } }
  @media screen and (max-width: 767px) {
    .bigdata .data:nth-child(4) {
      margin-bottom: 0; } }
  .bigdata .data__ttl {
    margin-bottom: 50px;
    line-height: 50px;
    font-size: 22px;
    font-weight: bold;
    color: #fff;
    background: #00b9ef;
    text-align: center;
    letter-spacing: 0.1em; }
    @media screen and (max-width: 767px) {
      .bigdata .data__ttl {
        margin-bottom: 25px;
        font-size: 16px;
        line-height: 30px; } }
  .bigdata .data__img {
    width: 380px;
    margin: 0 auto 30px; }
    @media screen and (max-width: 767px) {
      .bigdata .data__img {
        width: 100%;
        margin: 0 auto 18px; } }
    .bigdata .data__img img {
      width: 100%; }
  .bigdata .data__cap {
    display: block;
    text-align: right;
    margin-top: 30px;
    font-size: 12px;
    color: #00b9ef; }
    @media screen and (max-width: 767px) {
      .bigdata .data__cap {
        margin-top: 18px; } }
  .bigdata .data__text {
    font-size: 16px;
    line-height: 1.6;
    letter-spacing: 0.05em; }

.history .kv {
  margin-bottom: 0; }

.history .slide-area {
  padding: 50px 0 120px;
  width: 960px;
  margin: 0 auto 90px; }
  @media screen and (max-width: 767px) {
    .history .slide-area {
      padding: 25px 0 60px;
      width: calc(100% - 40px);
      margin: 0 auto 25px; } }
  .history .slide-area__ttl {
    margin-bottom: 15px;
    font-size: 24px;
    font-weight: bold;
    color: #676767;
    letter-spacing: 0.2em; }
    @media screen and (max-width: 767px) {
      .history .slide-area__ttl {
        font-size: 12px; } }
  .history .slide-area__text {
    margin-bottom: 35px;
    font-size: 30px;
    font-weight: 600;
    color: #00b9ef;
    letter-spacing: 0.1em; }
    @media screen and (max-width: 767px) {
      .history .slide-area__text {
        margin-bottom: 25px;
        font-size: 20px; } }

.history .slides {
  width: 820px;
  margin: 0 auto; }
  @media screen and (max-width: 767px) {
    .history .slides {
      width: 100%; } }
  .history .slides img {
    width: 100%; }

.history .slick-prev.slick-disabled,
.history .slick-prev.slick-disabled:before,
.history .slick-next.slick-disabled,
.history .slick-next.slick-disabled:before {
  display: none !important; }

.history .slick-prev,
.history .slick-next {
  width: 32px;
  height: 32px;
  z-index: 10; }

.history .slick-next {
  right: -55px; }
  @media screen and (max-width: 767px) {
    .history .slick-next {
      right: -10px; } }

.history .slick-prev {
  left: -55px; }
  @media screen and (max-width: 767px) {
    .history .slick-prev {
      left: -10px; } }

.history .slick-prev:before {
  content: "";
  display: block;
  width: 16px;
  height: 16px;
  border-right: solid 2px #676767;
  border-bottom: solid 2px #676767;
  transform: rotate(135deg);
  right: 0;
  left: 0;
  margin: auto; }
  @media screen and (max-width: 767px) {
    .history .slick-prev:before {
      width: 8px;
      height: 8px; } }

.history .slick-next:before {
  content: "";
  display: block;
  width: 16px;
  height: 16px;
  border-right: solid 2px #676767;
  border-bottom: solid 2px #676767;
  transform: rotate(-45deg);
  right: 0;
  left: 0;
  margin: auto; }
  @media screen and (max-width: 767px) {
    .history .slick-next:before {
      width: 8px;
      height: 8px; } }

.history .slick-dots {
  bottom: -70px; }
  @media screen and (max-width: 767px) {
    .history .slick-dots {
      bottom: -50px; } }

.history .slick-dots li {
  margin: 0 50px; }
  @media screen and (max-width: 767px) {
    .history .slick-dots li {
      margin: 0 20px; } }

.history .slick-dots li button:before {
  font-size: 20px; }
  @media screen and (max-width: 767px) {
    .history .slick-dots li button:before {
      font-size: 10px; } }

.history .slick-dots li.slick-active button:before {
  opacity: 1;
  color: #00b9ef; }

.history .slick-dots li button:before {
  opacity: 1;
  color: #dcdddd; }

.history .intro {
  width: 700px;
  margin: 0 auto 50px; }
  @media screen and (max-width: 767px) {
    .history .intro {
      width: calc(100% - 40px);
      margin: 0 auto 5px; } }

.history .text-area {
  padding: 35px 0 10px;
  width: 700px;
  margin: 0 auto;
  box-sizing: border-box; }
  @media screen and (max-width: 767px) {
    .history .text-area {
      width: calc(100% - 40px); } }
  .history .text-area__block {
    margin-bottom: 50px;
    border-bottom: solid 1px #bfbfbf; }
    @media screen and (max-width: 767px) {
      .history .text-area__block {
        margin-bottom: 40px; } }
    .history .text-area__block:last-child {
      margin-bottom: 0; }
  .history .text-area__ttl {
    padding-left: 18px;
    box-sizing: border-box;
    margin-bottom: 30px;
    font-size: 24px;
    font-weight: bold;
    color: #00b9ef;
    line-height: 1.4;
    border-left: solid 4px #b5b5b5; }
    @media screen and (max-width: 767px) {
      .history .text-area__ttl {
        margin-bottom: 25px;
        padding-left: 14px;
        font-size: 16px; } }
  .history .text-area__text {
    margin-bottom: 20px;
    max-height: 4.8em;
    overflow: hidden; }
    @media screen and (max-width: 767px) {
      .history .text-area__text {
        margin-bottom: 5px; } }
    .history .text-area__text.active {
      max-height: inherit;
      overflow: auto; }
    .history .text-area__text p {
      margin-bottom: 20px;
      font-size: 14px;
      line-height: 1.6; }
      .history .text-area__text p:last-child {
        margin-bottom: 0; }
  .history .text-area__more {
    position: relative;
    color: #666;
    letter-spacing: 0.15em;
    font-size: 12px;
    padding: 18px 25px;
    text-align: right;
    box-sizing: border-box; }
    .history .text-area__more:hover {
      cursor: pointer;
      opacity: .7; }
    .history .text-area__more::before {
      content: "";
      position: absolute;
      top: 0;
      bottom: 0px;
      right: 8px;
      margin: auto;
      display: block;
      width: 1px;
      height: 22px;
      background: #666; }
    .history .text-area__more::after {
      content: "";
      position: absolute;
      top: 0;
      bottom: -20px;
      right: 2px;
      margin: auto;
      display: block;
      width: 6px;
      height: 1px;
      transform: rotate(-45deg);
      transform-origin: 0;
      background: #666; }
    .history .text-area__more.active::after {
      top: -20px;
      bottom: 0;
      transform: rotate(45deg); }

.management .kv {
  margin-bottom: 0; }

.management-wrap {
  width: 800px;
  margin: 0 auto;
  padding: 50px 0; }
  @media screen and (max-width: 767px) {
    .management-wrap {
      width: calc(100% - 40px);
      padding: 25px 0; } }

.management__ttl {
  padding: 0 50px 50px;
  font-size: 30px;
  font-weight: bold;
  color: #00b9ef; }
  @media screen and (max-width: 767px) {
    .management__ttl {
      padding: 0 0 25px;
      font-size: 20px; } }

.management__block {
  margin-bottom: 50px;
  display: flex;
  justify-content: space-between;
  padding: 30px;
  background: #fff;
  box-sizing: border-box; }
  @media screen and (max-width: 767px) {
    .management__block {
      flex-direction: column;
      margin-bottom: 25px;
      padding: 20px; } }
  .management__block:last-child {
    margin-bottom: 0; }
  .management__block .img {
    width: 350px; }
    @media screen and (max-width: 767px) {
      .management__block .img {
        width: 100%;
        margin-bottom: 15px; } }
    .management__block .img img {
      width: 100%; }
  .management__block .profile {
    width: 360px; }
    @media screen and (max-width: 767px) {
      .management__block .profile {
        width: 100%; } }
    .management__block .profile__ttl {
      margin-bottom: 30px;
      font-size: 18px;
      font-weight: bold; }
      @media screen and (max-width: 767px) {
        .management__block .profile__ttl {
          font-size: 16px;
          margin-bottom: 15px; } }
    .management__block .profile__text {
      font-size: 16px;
      line-height: 1.6; }

.management .management-list {
  width: 800px;
  margin: 0 auto;
  padding: 50px 0 0; }
  @media screen and (max-width: 767px) {
    .management .management-list {
      width: calc(100% - 40px);
      padding: 25px 0 0; } }
  .management .management-list__ttl {
    margin-bottom: 30px;
    padding: 10px 20px;
    color: #fff;
    font-size: 18px;
    background: #00b9ef; }
    @media screen and (max-width: 767px) {
      .management .management-list__ttl {
        margin-bottom: 20px;
        font-size: 14px; } }
  .management .management-list__block-area {
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap; }
    @media screen and (max-width: 767px) {
      .management .management-list__block-area {
        margin-bottom: 0;
        flex-direction: column; } }
    .management .management-list__block-area:last-child {
      margin-bottom: 0; }
  .management .management-list .block {
    margin-bottom: 30px;
    width: 50%;
    font-size: 16px;
    line-height: 1.6; }
    @media screen and (max-width: 767px) {
      .management .management-list .block {
        margin-bottom: 20px;
        width: 100%;
        font-size: 14px; } }
    .management .management-list .block__name {
      font-weight: bold; }

.casestudy .kv--lower {
  margin-bottom: 0; }

.casestudy .case {
  padding: 50px 0; }
  @media screen and (max-width: 767px) {
    .casestudy .case {
      padding: 25px 0; } }
  .casestudy .case--lower {
    padding: 0; }
  .casestudy .case__ttl {
    margin-bottom: 30px;
    padding: 13px;
    font-size: 24px;
    font-weight: bold;
    color: #fff;
    background: #00b9ef;
    text-align: center; }
    @media screen and (max-width: 767px) {
      .casestudy .case__ttl {
        margin-bottom: 15px;
        padding: 10px;
        font-size: 16px;
        line-height: 1.3; } }
  .casestudy .case__cat {
    width: 100%;
    text-align: center;
    vertical-align: bottom;
    color: #00b9ef;
    font-size: 24px;
    font-weight: bold;
    border: 1px solid #00b9ef;
    margin-bottom: 30px;
    padding: 9px 0 7px; }
    @media screen and (max-width: 767px) {
      .casestudy .case__cat {
        font-size: 16px;
        padding: 14px 0 14px; } }
  .casestudy .case__block {
    margin-bottom: 80px;
    display: flex;
    justify-content: space-between; }
    @media screen and (max-width: 767px) {
      .casestudy .case__block {
        margin-bottom: 40px;
        flex-direction: column; } }
    .casestudy .case__block:last-child {
      margin-bottom: 0; }
  .casestudy .case__img {
    width: 430px; }
    @media screen and (max-width: 767px) {
      .casestudy .case__img {
        width: 100%;
        margin-bottom: 15px; } }
    .casestudy .case__img img {
      display: block;
      width: 100%; }
  .casestudy .case__text-area {
    width: 400px; }
    @media screen and (max-width: 767px) {
      .casestudy .case__text-area {
        width: 100%; } }
  .casestudy .case__text-ttl {
    margin-bottom: 25px;
    font-size: 25px;
    font-weight: bold;
    color: #00b9ef;
    line-height: 1.4;
    font-feature-settings: "palt"; }
    @media screen and (max-width: 767px) {
      .casestudy .case__text-ttl {
        text-align: center;
        margin-bottom: 15px;
        font-size: 20px; } }
  .casestudy .case__text-detail {
    margin-bottom: 40px;
    font-size: 16px;
    line-height: 1.6; }
    @media screen and (max-width: 767px) {
      .casestudy .case__text-detail {
        margin-bottom: 20px; } }
  .casestudy .case__text-btn {
    text-align: right; }
  .casestudy .case__detail {
    font-size: 16px;
    line-height: 1.6; }
    .casestudy .case__detail dt {
      width: 6em;
      display: inline-block; }
    .casestudy .case__detail dd {
      display: inline-block; }

.casestudy .profile {
  margin-bottom: 50px;
  display: flex;
  justify-content: space-between; }
  @media screen and (max-width: 767px) {
    .casestudy .profile {
      margin-bottom: 25px;
      flex-direction: column; } }
  @media screen and (min-width: 768px) {
    .casestudy .profile--vertical {
      width: 560px;
      margin: 0 auto 50px; } }
  .casestudy .profile__img {
    width: 50%; }
    @media screen and (max-width: 767px) {
      .casestudy .profile__img {
        width: 100%; } }
    .casestudy .profile__img img {
      display: block;
      width: 100%; }
  .casestudy .profile__text {
    display: flex;
    align-items: flex-end;
    width: 50%;
    padding: 30px;
    box-sizing: border-box;
    color: #fff;
    background: #00b9ef; }
    @media screen and (max-width: 767px) {
      .casestudy .profile__text {
        width: 100%;
        padding: 15px 20px; } }
  .casestudy .profile__position {
    font-size: 16px;
    line-height: 1.6;
    margin-bottom: 25px; }
    @media screen and (max-width: 767px) {
      .casestudy .profile__position {
        margin-bottom: 15px; } }
  .casestudy .profile__name {
    font-size: 18px; }
    @media screen and (max-width: 767px) {
      .casestudy .profile__name {
        font-size: 16px; } }

.casestudy .casestudy-block {
  margin-bottom: 50px; }
  @media screen and (max-width: 767px) {
    .casestudy .casestudy-block {
      margin-bottom: 25px; } }
  .casestudy .casestudy-block:last-child {
    margin-bottom: 0; }
  .casestudy .casestudy-block--2 {
    display: flex;
    justify-content: space-between; }
    @media screen and (max-width: 767px) {
      .casestudy .casestudy-block--2 {
        flex-direction: column; } }
  .casestudy .casestudy-block__img {
    width: 430px;
    text-align: center; }
    @media screen and (max-width: 767px) {
      .casestudy .casestudy-block__img {
        width: 100%;
        margin-bottom: 25px; }
        .casestudy .casestudy-block__img img {
          width: 100%; }
        .casestudy .casestudy-block__img--1 {
          width: 200px;
          margin: 0 auto 25px; } }
    .casestudy .casestudy-block__img--2 {
      box-sizing: border-box;
      padding-left: 30px;
      text-align: left; }
      @media screen and (max-width: 767px) {
        .casestudy .casestudy-block__img--2 {
          margin: 25px auto 0;
          padding: 0;
          display: flex;
          justify-content: space-between; } }
      .casestudy .casestudy-block__img--2 .img {
        margin-bottom: 30px; }
        @media screen and (max-width: 767px) {
          .casestudy .casestudy-block__img--2 .img {
            margin-bottom: 0;
            width: 48%; }
            .casestudy .casestudy-block__img--2 .img img {
              width: 100%; } }
  .casestudy .casestudy-block__text-area {
    width: 400px; }
    @media screen and (max-width: 767px) {
      .casestudy .casestudy-block__text-area {
        width: 100%; } }
  .casestudy .casestudy-block__ttl {
    margin-bottom: 30px;
    font-size: 24px;
    line-height: 1.4;
    color: #00b9ef;
    padding-left: 19px;
    box-sizing: border-box;
    border-left: solid 4px #b5b5b5; }
    @media screen and (max-width: 767px) {
      .casestudy .casestudy-block__ttl {
        margin-bottom: 25px;
        font-size: 16px;
        padding-left: 10px;
        border-left: solid 3px #b5b5b5; } }
  .casestudy .casestudy-block__text {
    font-size: 16px;
    line-height: 1.6; }

.casestudy .casestudy-photo-list {
  width: 550px;
  margin: 0 auto 50px;
  padding-top: 30px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  font-size: 0; }
  @media screen and (max-width: 767px) {
    .casestudy .casestudy-photo-list {
      width: 100%;
      padding: 0;
      margin: 0 auto 25px; } }
  .casestudy .casestudy-photo-list li {
    margin-bottom: 30px; }
    @media screen and (max-width: 767px) {
      .casestudy .casestudy-photo-list li {
        width: 48%;
        margin-bottom: 4%; }
        .casestudy .casestudy-photo-list li:nth-child(3), .casestudy .casestudy-photo-list li:nth-child(4) {
          margin-bottom: 0; }
        .casestudy .casestudy-photo-list li img {
          width: 100%; } }

.platform .platform-block-wrap {
  width: 700px;
  margin: 0 auto; }
  @media screen and (max-width: 767px) {
    .platform .platform-block-wrap {
      width: calc(100% - 40px); } }

.platform .platform-block {
  padding: 50px 0 0;
  margin-bottom: 50px; }
  @media screen and (max-width: 767px) {
    .platform .platform-block {
      padding: 25px 0 0;
      margin-bottom: 25px; } }
  .platform .platform-block--movie {
    background: #fffeee; }
  .platform .platform-block--line {
    background: #eff5ed; }
  .platform .platform-block--lower {
    padding: 50px 0;
    margin-top: -50px; }
    @media screen and (max-width: 767px) {
      .platform .platform-block--lower {
        padding: 25px 0;
        margin-top: -25px; } }
    .platform .platform-block--lower .platform-block__text {
      font-size: 30px;
      margin-bottom: 30px;
      text-align: left; }
      @media screen and (max-width: 767px) {
        .platform .platform-block--lower .platform-block__text {
          font-size: 20px;
          margin-bottom: 25px; } }
    .platform .platform-block--lower .platform-block__img {
      margin-bottom: 0; }
    .platform .platform-block--lower .platform-block__img--line {
      margin-bottom: 15px; }
  .platform .platform-block__ttl {
    margin-bottom: 30px;
    padding: 13px;
    font-size: 24px;
    font-weight: bold;
    color: #fff;
    text-align: center; }
    @media screen and (max-width: 767px) {
      .platform .platform-block__ttl {
        margin-bottom: 20px;
        font-size: 16px;
        padding: 7px; } }
    .platform .platform-block__ttl--movie {
      background: #f39800; }
    .platform .platform-block__ttl--line {
      background: #62c44c; }
  .platform .platform-block__img {
    position: relative;
    width: 665px;
    margin: 0 auto 40px; }
    @media screen and (max-width: 767px) {
      .platform .platform-block__img {
        width: 100%;
        margin: 0 auto 25px; } }
    .platform .platform-block__img--2 {
      margin: 0 auto 20px; }
      @media screen and (max-width: 767px) {
        .platform .platform-block__img--2 {
          margin: 0 auto 17px; } }
    .platform .platform-block__img img {
      width: 100%; }
  .platform .platform-block__cap {
    position: absolute;
    text-align: center;
    bottom: -15px;
    left: 0;
    right: 0;
    font-size: 16px;
    line-height: 1.6; }
  .platform .platform-block__text {
    font-size: 24px;
    font-weight: bold;
    line-height: 1.6;
    text-align: center; }
    @media screen and (max-width: 767px) {
      .platform .platform-block__text {
        font-size: 16px;
        font-feature-settings: "palt"; } }
    .platform .platform-block__text--movie {
      color: #f39800; }
    .platform .platform-block__text--line {
      color: #62c44c; }

.platform .platform-case {
  width: 700px;
  margin: 0 auto 50px; }
  @media screen and (max-width: 767px) {
    .platform .platform-case {
      width: calc(100% - 40px);
      margin: 0 auto 40px; } }
  .platform .platform-case__ttl {
    margin-bottom: 30px;
    font-size: 24px;
    line-height: 1.4;
    padding-left: 20px;
    box-sizing: border-box; }
    @media screen and (max-width: 767px) {
      .platform .platform-case__ttl {
        margin-bottom: 25px;
        font-size: 16px;
        padding-left: 10px; } }
    .platform .platform-case__ttl--movie {
      border-left: solid 6px #f39800; }
      @media screen and (max-width: 767px) {
        .platform .platform-case__ttl--movie {
          border-left: solid 4px #f39800; } }
    .platform .platform-case__ttl--line {
      border-left: solid 6px #62c44c; }
      @media screen and (max-width: 767px) {
        .platform .platform-case__ttl--line {
          border-left: solid 4px #62c44c; } }
  .platform .platform-case__text {
    font-size: 16px;
    line-height: 1.6; }
    .platform .platform-case__text .list li::before {
      content: "";
      display: inline-block;
      width: 10px;
      height: 10px;
      margin: 0 3px;
      border-radius: 20px;
      background: #bfbfbf; }
  .platform .platform-case__img {
    width: 500px;
    margin: 30px auto 0; }
    @media screen and (max-width: 767px) {
      .platform .platform-case__img {
        width: 100%;
        margin: 25px auto 0; } }
    .platform .platform-case__img img {
      width: 100%; }
  .platform .platform-case__note {
    margin-top: 20px;
    font-size: 14px;
    line-height: 1.8;
    font-feature-settings: "palt"; }
    .platform .platform-case__note--line li {
      padding-left: 2.5em;
      text-indent: -2.5em; }

.platform .platform-list {
  width: 700px;
  margin: 0 auto 50px; }
  @media screen and (max-width: 767px) {
    .platform .platform-list {
      width: calc(100% - 40px);
      margin: 0 auto 25px; } }
  .platform .platform-list__ttl {
    margin-bottom: 30px;
    padding: 10px 20px;
    color: #fff;
    font-size: 18px; }
    .platform .platform-list__ttl--movie {
      background: #f39800; }
    .platform .platform-list__ttl--movie-black {
      background: #fff;
      color: #000;
      border: 1px solid #f39800; }
  .platform .platform-list__text {
    font-size: 16px;
    line-height: 1.6; }
  .platform .platform-list .service-link {
    width: 700px;
    padding: 30px 0 0; }
    @media screen and (max-width: 767px) {
      .platform .platform-list .service-link {
        width: 100%;
        padding: 25px 0 0; } }

.platform .point {
  margin-bottom: 50px;
  padding: 50px 0; }
  @media screen and (max-width: 767px) {
    .platform .point {
      padding: 25px 0;
      margin-bottom: 25px; } }
  .platform .point--movie {
    background: #fffeee; }
  .platform .point__wrap {
    width: 700px;
    margin: 0 auto; }
    @media screen and (max-width: 767px) {
      .platform .point__wrap {
        width: calc(100% - 40px); } }
  .platform .point__inner {
    background: #fff;
    padding: 30px;
    box-sizing: border-box; }
    @media screen and (max-width: 767px) {
      .platform .point__inner {
        padding: 25px 20px; } }
  .platform .point__ttl {
    text-align: center;
    font-size: 24px;
    color: #f39800;
    margin: 0 auto 30px; }
    @media screen and (max-width: 767px) {
      .platform .point__ttl {
        margin-bottom: 25px; } }
  .platform .point__list {
    width: 590px;
    margin: 0 auto; }
    @media screen and (max-width: 767px) {
      .platform .point__list {
        width: 100%; } }
    .platform .point__list__ttl {
      margin-bottom: 30px;
      background: #f39800;
      color: #fff;
      text-align: center;
      font-size: 24px;
      line-height: 1.8;
      border-radius: 20px; }
      @media screen and (max-width: 767px) {
        .platform .point__list__ttl {
          margin-bottom: 20px;
          font-size: 16px; } }
    .platform .point__list__text {
      width: 495px;
      margin: 0 auto 25px;
      font-size: 16px;
      line-height: 1.6; }
      @media screen and (max-width: 767px) {
        .platform .point__list__text {
          width: 100%; } }
    .platform .point__list:last-child .point__list__text {
      margin-bottom: 0; }

.platform .flow {
  width: 700px;
  margin: 0 auto; }
  @media screen and (max-width: 767px) {
    .platform .flow {
      width: calc(100% - 40px); } }
  .platform .flow__block {
    margin-bottom: 50px; }
    @media screen and (max-width: 767px) {
      .platform .flow__block {
        margin-bottom: 40px; } }
    .platform .flow__block:last-child {
      margin-bottom: 0; }
  .platform .flow__img {
    display: block;
    margin-bottom: 30px; }
    @media screen and (max-width: 767px) {
      .platform .flow__img {
        margin-bottom: 15px; }
        .platform .flow__img img {
          width: 100%; } }
  .platform .flow__detail {
    width: 670px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between; }
    @media screen and (max-width: 767px) {
      .platform .flow__detail {
        width: 100%;
        flex-direction: column; } }
    .platform .flow__detail__ttl {
      box-sizing: border-box;
      width: 230px;
      height: 40px;
      border: solid 1px #f39800;
      text-align: center;
      font-size: 18px;
      color: #f39800;
      line-height: 2.2; }
      @media screen and (max-width: 767px) {
        .platform .flow__detail__ttl {
          width: 100%;
          margin-bottom: 15px;
          font-size: 16px;
          height: 30px;
          line-height: 1.8; } }
    .platform .flow__detail__text {
      width: 425px;
      font-size: 15px;
      line-height: 1.8; }
      @media screen and (max-width: 767px) {
        .platform .flow__detail__text {
          width: 100%;
          font-size: 16px; } }
      .platform .flow__detail__text--center {
        width: 100%;
        text-align: center; }
        @media screen and (max-width: 767px) {
          .platform .flow__detail__text--center {
            text-align: left; } }

.platform .sample {
  width: 700px;
  margin: 0 auto;
  text-align: center; }
  @media screen and (max-width: 767px) {
    .platform .sample {
      width: calc(100% - 40px); } }
  @media screen and (max-width: 767px) {
    .platform .sample__img img {
      width: 100%; } }
  .platform .sample__text {
    margin-top: 20px;
    font-size: 16px;
    line-height: 1.6; }
    @media screen and (max-width: 767px) {
      .platform .sample__text {
        text-align: left; } }

.data .intro {
  margin-bottom: 75px; }
  @media screen and (max-width: 767px) {
    .data .intro {
      margin-bottom: 40px; } }

.data .data-area {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between; }

.data .data-block {
  width: 400px;
  margin-bottom: 75px; }
  @media screen and (max-width: 767px) {
    .data .data-block {
      margin-bottom: 40px; } }
  @media screen and (min-width: 768px) {
    .data .data-block:nth-child(7), .data .data-block:nth-child(8) {
      margin-bottom: 0; } }
  @media screen and (max-width: 767px) {
    .data .data-block:last-child {
      margin-bottom: 0; } }
  .data .data-block__img {
    margin-bottom: 30px; }
    @media screen and (max-width: 767px) {
      .data .data-block__img {
        margin-bottom: 25px; }
        .data .data-block__img img {
          width: 100%; } }
  .data .data-block__text {
    font-size: 16px;
    line-height: 1.6; }

.page-news {
  /* content */ }
  .page-news .news-kv {
    background: #eee;
    margin-bottom: 0px;
    padding-bottom: 50px; }
    @media screen and (max-width: 767px) {
      .page-news .news-kv {
        padding-bottom: 25px; } }
  .page-news .news_detail-kv {
    margin-bottom: 0px;
    padding-bottom: 10px; }
    @media screen and (max-width: 767px) {
      .page-news .news_detail-kv {
        padding-bottom: 25px; } }
  .page-news .content {
    margin-bottom: 50px;
    padding-bottom: 50px;
    background: #eee;
    /* newsArea */ }
    @media screen and (max-width: 767px) {
      .page-news .content {
        padding: 0 20px;
        margin-bottom: 30px; } }
    .page-news .content__inner {
      width: 900px;
      margin: 0 auto;
      overflow: hidden; }
      @media screen and (max-width: 767px) {
        .page-news .content__inner {
          width: 100%;
          padding: 0 0 0; } }
    .page-news .content__title {
      font-size: 30px;
      font-weight: bold;
      color: #00b9ef;
      margin-bottom: 50px;
      line-height: 1.5; }
      @media screen and (max-width: 767px) {
        .page-news .content__title {
          text-align: left;
          font-size: 20px;
          margin-bottom: 25px; } }
    .page-news .content .newsArea {
      width: 660px;
      float: left;
      background: #fff; }
      @media screen and (max-width: 767px) {
        .page-news .content .newsArea {
          width: auto;
          padding: 25px 20px 0;
          float: none;
          padding-bottom: 30px;
          /* margin-bottom: 40px; */ } }
      @media screen and (max-width: 767px) {
        .page-news .content .newsArea__title {
          font-size: 16px;
          font-weight: bold;
          line-height: 1.5;
          margin-bottom: 25px;
          color: #444; } }
      .page-news .content .newsArea ul {
        overflow: hidden; }
        .page-news .content .newsArea ul li {
          float: left;
          font-size: 13px;
          color: #fff;
          width: 110px;
          height: 40px;
          line-height: 40px;
          text-align: center;
          cursor: pointer; }
          @media screen and (max-width: 767px) {
            .page-news .content .newsArea ul li {
              font-size: 16px;
              margin-bottom: 10px; }
              .page-news .content .newsArea ul li:nth-child(1) {
                width: 100%; }
              .page-news .content .newsArea ul li:nth-child(2) {
                width: 48.5%;
                margin-right: 3%; }
              .page-news .content .newsArea ul li:nth-child(3) {
                width: 48.5%; }
              .page-news .content .newsArea ul li:nth-child(4) {
                width: 48.5%;
                margin-right: 3%; }
              .page-news .content .newsArea ul li:nth-child(5) {
                width: 48.5%; }
              .page-news .content .newsArea ul li:nth-child(6) {
                width: 48.5%; } }
          .page-news .content .newsArea ul li#all {
            background: #00b9ef; }
            @media screen and (max-width: 767px) {
              .page-news .content .newsArea ul li#all {
                background: #006da6; } }
          .page-news .content .newsArea ul li#seminar {
            background: #e76762; }
          .page-news .content .newsArea ul li#release {
            background: #00be75; }
          .page-news .content .newsArea ul li#media {
            background: #f1be0d; }
          .page-news .content .newsArea ul li#update {
            background: #f1975a; }
          .page-news .content .newsArea ul li#announce {
            background: #91c0de; }
      .page-news .content .newsArea__inner {
        width: 560px;
        margin: 40px 50px 50px; }
        @media screen and (max-width: 767px) {
          .page-news .content .newsArea__inner {
            width: 100%;
            margin: 30px 0 0; } }
        @media screen and (max-width: 767px) {
          .page-news .content .newsArea__inner--2 {
            margin: 0; } }
      .page-news .content .newsArea .newsList {
        overflow: hidden;
        font-size: 12px;
        border-bottom: 2px solid #e5e5e5;
        padding-bottom: 25px;
        margin-bottom: 25px; }
        @media screen and (max-width: 767px) {
          .page-news .content .newsArea .newsList.p-nomg {
            margin: 0; } }
        @media screen and (max-width: 767px) {
          .page-news .content .newsArea .newsList--2 {
            padding-top: 25px; } }
        @media screen and (max-width: 767px) {
          .page-news .content .newsArea .newsList--3 {
            padding-top: 0px; } }
        .page-news .content .newsArea .newsList__date {
          float: left;
          width: 100px;
          margin: 6px 25px 0 0; }
          @media screen and (max-width: 767px) {
            .page-news .content .newsArea .newsList__date {
              display: inline-block;
              width: auto;
              font-size: 16px;
              padding-top: 2px;
              float: none;
              margin: 0 20px 10px 0; } }
        .page-news .content .newsArea .newsList__cat {
          float: left;
          width: 120px;
          color: #fff;
          text-align: center;
          margin: 0 20px 0 0;
          padding: 7px 0;
          border-radius: 5px; }
          .page-news .content .newsArea .newsList__cat.bg-seminar {
            background: #e76762; }
          .page-news .content .newsArea .newsList__cat.bg-release {
            background: #00be75; }
          .page-news .content .newsArea .newsList__cat.bg-media {
            background: #f1be0d; }
          .page-news .content .newsArea .newsList__cat.bg-update {
            background: #f1975a; }
          .page-news .content .newsArea .newsList__cat.bg-announce {
            background: #91c0de; }
          @media screen and (max-width: 767px) {
            .page-news .content .newsArea .newsList__cat {
              display: inline-block;
              width: 55%;
              font-size: 12px;
              float: none;
              padding: 5px 0px;
              margin: 0 0 4px 0;
              vertical-align: top; } }
        .page-news .content .newsArea .newsList__text {
          float: left;
          width: 286px;
          padding-top: 2px;
          line-height: 1.5; }
          @media screen and (max-width: 767px) {
            .page-news .content .newsArea .newsList__text {
              width: 100%;
              font-size: 16px;
              line-height: 1.5;
              float: none;
              margin: 0 0 0 0; } }
    .page-news .content .sideNav {
      float: right;
      width: 160px;
      font-size: 12px;
      padding: 30px 25px 40px;
      background: #fff; }
      @media screen and (max-width: 767px) {
        .page-news .content .sideNav {
          width: auto;
          float: none;
          display: block;
          padding: 0 20px 14px;
          margin-bottom: 30px; } }
      .page-news .content .sideNav__title {
        font-size: 14px;
        font-weight: bold;
        color: #000;
        margin-bottom: 20px; }
        @media screen and (max-width: 767px) {
          .page-news .content .sideNav__title {
            font-size: 16px; } }
      .page-news .content .sideNav ul {
        margin-bottom: 30px; }
        @media screen and (max-width: 767px) {
          .page-news .content .sideNav ul.p-nomg {
            margin: 0; } }
        .page-news .content .sideNav ul li {
          margin-bottom: 16px; }
          @media screen and (max-width: 767px) {
            .page-news .content .sideNav ul li {
              font-size: 16px; } }
          .page-news .content .sideNav ul li a {
            color: #00b9ef;
            text-decoration: underline; }

/* page-news */
.contentsWrap {
  width: 1000px;
  margin: 0 auto;
  background: #eee;
  /* mainBlock */ }
  @media screen and (max-width: 767px) {
    .contentsWrap {
      width: 100%;
      margin: 0 auto; } }
  .contentsWrap .news-kv {
    background: #eee;
    margin-bottom: 0px;
    padding-bottom: 50px; }
    @media screen and (max-width: 767px) {
      .contentsWrap .news-kv {
        padding-bottom: 40px; } }
  .contentsWrap .mainBlock {
    width: 720px;
    float: left;
    margin-right: 25px; }
    @media screen and (max-width: 767px) {
      .contentsWrap .mainBlock {
        width: 95%;
        float: none;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 20px; } }
    .contentsWrap .mainBlock .blockTtl2 {
      width: 640px;
      height: 80px;
      padding: 0 40px;
      display: table-cell;
      border-bottom: 3px solid #f3f3f3;
      background: #fff;
      font-size: 172%;
      font-weight: normal;
      line-height: 1.3;
      color: #00568d;
      text-align: left;
      vertical-align: middle; }
    .contentsWrap .mainBlock .mainInner {
      margin: 0 auto 30px;
      padding: 30px 40px 40px;
      background: #fff;
      font-size: 1.2em; }
      .contentsWrap .mainBlock .mainInner p {
        line-height: 2; }
      .contentsWrap .mainBlock .mainInner .dateBlock {
        margin: 0 auto 15px;
        text-align: right;
        color: #333333;
        font-size: 93%; }

/* contentsWrap */
.clearfix {
  min-height: 1px;
  display: block; }
  .clearfix:before {
    content: " ";
    display: table; }
  .clearfix:after {
    content: " ";
    display: table; }

.contact .bg-gray {
  margin-bottom: 50px;
  padding: 50px 0; }
  @media screen and (max-width: 767px) {
    .contact .bg-gray {
      margin-bottom: 25px;
      padding: 25px 0; } }

.contact .contact-table {
  width: 100%;
  margin-bottom: 50px;
  text-align: left; }
  @media screen and (max-width: 767px) {
    .contact .contact-table {
      margin-bottom: 0; } }
  .contact .contact-table__ttl {
    width: 30%;
    max-width: 258px;
    font-size: 20px;
    font-weight: bold;
    padding: 30px;
    box-sizing: border-box;
    border-bottom: solid 1px #ccc;
    vertical-align: middle; }
    @media screen and (max-width: 767px) {
      .contact .contact-table__ttl {
        display: block;
        width: 100%;
        max-width: inherit;
        padding: 0;
        border: none;
        font-size: 16px; } }
    .contact .contact-table__ttl .ex {
      display: inline-block;
      font-size: 12px;
      font-weight: normal;
      color: #ff0000; }
    .contact .contact-table__ttl .note {
      font-size: 14px; }
  .contact .contact-table__ttl-half {
    width: 30%;
    max-width: 258px;
    font-size: 20px;
    font-weight: bold;
    padding: 30px;
    box-sizing: border-box;
    border-bottom: solid 1px #ccc;
    vertical-align: middle; }
    @media screen and (max-width: 767px) {
      .contact .contact-table__ttl-half {
        display: block;
        width: 100%;
        max-width: inherit;
        padding: 0;
        border: none;
        font-size: 16px; } }
    .contact .contact-table__ttl-half .ex {
      display: inline-block;
      font-size: 12px;
      font-weight: normal;
      color: #ff0000; }
    .contact .contact-table__ttl-half .note {
      font-size: 14px; }
  .contact .contact-table__detail {
    width: 70%;
    max-width: 602px;
    padding: 30px 0 30px;
    box-sizing: border-box;
    border-bottom: solid 1px #ccc;
    font-size: 16px; }
    @media screen and (max-width: 767px) {
      .contact .contact-table__detail {
        margin-bottom: 40px;
        padding: 15px 0 15px;
        display: block;
        width: 100%;
        max-width: inherit; } }
  .contact .contact-table__detail-half {
    width: 20%;
    max-width: 602px;
    padding: 30px 0 30px;
    box-sizing: border-box;
    border-bottom: solid 1px #ccc;
    font-size: 16px; }
    @media screen and (max-width: 767px) {
      .contact .contact-table__detail-half {
        margin-bottom: 40px;
        padding: 15px 0 15px;
        display: block;
        width: 100%;
        max-width: inherit; } }
  .contact .contact-table .inputGroup-wrap {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap; }
  .contact .contact-table .inputGroup {
    width: 49%;
    background-color: #fff;
    display: block;
    margin: 1% 0;
    position: relative; }
    @media screen and (max-width: 767px) {
      .contact .contact-table .inputGroup {
        width: 100%; } }
    .contact .contact-table .inputGroup label {
      padding: 16px 16px 16px 52px;
      width: 100%;
      display: block;
      text-align: left;
      color: #3C454C;
      cursor: pointer;
      position: relative;
      z-index: 2;
      transition: color 200ms ease-in;
      overflow: hidden;
      box-sizing: border-box; }
      .contact .contact-table .inputGroup label:before {
        width: 10px;
        height: 10px;
        border-radius: 50%;
        content: '';
        background-color: #00bbf4;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%) scale3d(1, 1, 1);
        transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1);
        opacity: 0;
        z-index: -1; }
      .contact .contact-table .inputGroup label:after {
        width: 32px;
        height: 32px;
        content: '';
        border: 2px solid #D1D7DC;
        background-color: #fff;
        background-image: url("../img/common/check.png");
        background-repeat: no-repeat;
        background-position: center;
        border-radius: 50%;
        z-index: 2;
        position: absolute;
        left: 10px;
        top: 50%;
        transform: translateY(-50%);
        cursor: pointer;
        transition: all 200ms ease-in;
        box-sizing: border-box; }
    .contact .contact-table .inputGroup input:checked ~ label {
      color: #fff; }
      .contact .contact-table .inputGroup input:checked ~ label:before {
        transform: translate(-50%, -50%) scale3d(56, 56, 1);
        opacity: 1; }
      .contact .contact-table .inputGroup input:checked ~ label:after {
        background-color: #006194;
        border-color: #006194; }
    .contact .contact-table .inputGroup input {
      margin: 0;
      width: 32px;
      height: 32px;
      order: 1;
      z-index: 2;
      position: absolute;
      left: 10px;
      top: 50%;
      transform: translateY(-50%);
      cursor: pointer;
      visibility: hidden; }
      @media screen and (max-width: 767px) {
        .contact .contact-table .inputGroup input {
          width: 16px;
          height: 16px;
          left: 5px; } }
  .contact .contact-table input[type=text],
  .contact .contact-table textarea {
    outline: none;
    border: 0;
    padding: 16px;
    font-size: 16px;
    color: #444;
    border: solid 1px #ccc;
    width: 100%;
    border-radius: 3px;
    box-shadow: inset 1px 4px 9px -6px rgba(0, 0, 0, 0.5);
    box-sizing: border-box; }
    .contact .contact-table input[type=text]:focus,
    .contact .contact-table textarea:focus {
      border: solid 1px #00bbf4; }
  .contact .contact-table .name input {
    width: 80px; }
  .contact .contact-table .telbox input {
    width: 100%; }
  .contact .contact-table .formsub {
    font-size: 12px;
    display: inline-block;
    margin-top: 10px; }

.contact .submitBox input[type="submit"] {
  -webkit-appearance: none;
  display: block;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  border: none;
  background-color: #ff3574;
  -webkit-box-shadow: 0 3px #d02d60;
  -moz-box-shadow: 0 3px #d02d60;
  box-shadow: 0 3px #d02d60;
  width: 40%;
  height: auto;
  padding: 10px 0;
  margin: 0 auto;
  font-size: 115%;
  color: #fff;
  font-weight: bold;
  text-align: center;
  letter-spacing: 0.05em;
  cursor: pointer; }
  @media screen and (max-width: 767px) {
    .contact .submitBox input[type="submit"] {
      width: 100%; } }

.contact .intro a {
  color: #00b9ef; }

.contact .intro p {
  line-height: 1.5;
  font-size: 16px; }

.contact .intro ul {
  padding-left: 2em;
  line-height: 1.5; }

.contact .intro ul li {
  font-size: 16px;
  list-style: disc; }

.contact .bg-white {
  background-color: white;
  padding: 30px 40px 40px;
  font-size: 1.2em; }
  @media screen and (max-width: 767px) {
    .contact .bg-white {
      padding: 1px; } }
  .contact .bg-white tr {
    border-bottom: 1px solid #e5e5e5; }
  .contact .bg-white th {
    width: 130px;
    margin: 30px 0;
    font-weight: bold;
    vertical-align: top;
    display: inline-block;
    text-align: left; }
    @media screen and (max-width: 767px) {
      .contact .bg-white th {
        width: 100%;
        margin: 30px 0 0; } }
  .contact .bg-white td {
    font-feature-settings: "palt";
    width: 460px;
    margin: 30px 0;
    line-height: 1.5;
    display: inline-block; }
    @media screen and (max-width: 767px) {
      .contact .bg-white td {
        width: 100%;
        word-break: break-all; } }
  .contact .bg-white a {
    color: #00b9ef; }
  .contact .bg-white .ttl--light-blue {
    margin: 50px 10px; }

.contact .rogo-img {
  width: 100%;
  height: auto;
  margin: 50px 10px 100px; }
  @media screen and (max-width: 767px) {
    .contact .rogo-img {
      margin: 50px 10px; } }

.contact .rogo-img img {
  width: auto;
  margin: 4px 15px;
  margin: 0;
  padding: 3% 12%;
  border: 2px solid #00b9ef;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(102, 102, 102, 0.2); }
  @media screen and (max-width: 767px) {
    .contact .rogo-img img {
      width: 130px;
      margin: 5px 0; } }

.contact .title {
  font-size: 20px; }

.contact .data {
  margin: 55px 21px;
  line-height: 1.2em; }

.contact .title-2 {
  font-size: 20px;
  padding-top: 120px; }

.contact .hrs-data {
  margin: -45px 0 70px; }
  @media screen and (max-width: 767px) {
    .contact .hrs-data {
      width: 100%; } }

.contact .list-data {
  margin: 55px 21px;
  padding: 0 0 55px 1em;
  font-size: 14px;
  line-height: 1.5;
  list-style: disc; }

.form-area-index #name_box {
  font-size: 0; }

.form-area-index #name_box .subttl {
  width: 4%;
  display: inline-block;
  font-size: 20px;
  font-weight: bold;
  box-sizing: border-box;
  vertical-align: middle; }
  @media screen and (max-width: 767px) {
    .form-area-index #name_box .subttl {
      width: 5%;
      font-size: 16px;
      margin-right: 3%; } }

.form-area-index #name_box .subttl:last-of-type {
  padding-left: 3%; }

.form-area-index #name_box input {
  width: 43%; }
  @media screen and (max-width: 767px) {
    .form-area-index #name_box input {
      width: 40%; } }

.form-area-index #name_box input:last-of-type {
  margin-left: 4%; }

.form-area-index .contentValue {
  font-size: 16px; }

.form-area-index .confirm-title {
  display: none;
  text-align: left;
  font-size: 26px;
  font-weight: bold;
  margin-bottom: 20px;
  margin-top: -30px; }

.form-area-index .back-btn {
  -webkit-appearance: none;
  border-radius: 3px;
  border: none;
  background-color: #a9a9a9;
  box-shadow: 0 3px #797979;
  width: 40%;
  height: auto;
  padding: 14px 0;
  margin: 10px auto 0 auto;
  font-size: 115%;
  color: #fff;
  font-weight: bold;
  text-align: center;
  letter-spacing: 0.05em;
  cursor: pointer;
  display: none; }

.en .header__logo {
  z-index: 30; }

.en .header__inner {
  padding: 20px 0 0; }

.en .footer__bottom {
  padding-top: 0; }

@media screen and (min-width: 768px) {
  .en .footer__nav {
    width: 500px; } }

@media screen and (max-width: 767px) {
  .en .pagetop-area {
    width: 50px;
    right: 20px;
    background: none; } }

.en .kv--en-index {
  background: url(../img/en/mainVisua01_bg.jpg) no-repeat center;
  background-size: cover;
  height: 470px; }
  .en .kv--en-index .kv__ttl {
    width: 351px;
    height: 366px;
    position: absolute;
    top: 52px;
    left: 50%;
    right: inherit;
    bottom: inherit;
    margin-left: 80px; }
    @media screen and (max-width: 767px) {
      .en .kv--en-index .kv__ttl {
        width: 80%;
        height: auto;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto;
        display: flex;
        align-items: center;
        justify-content: center; }
        .en .kv--en-index .kv__ttl img {
          width: 100%;
          max-width: 351px; } }

@media screen and (max-width: 767px) {
  .en .kv--ps .kv__ttl {
    height: 3em; } }

.en .en-menu {
  width: 860px;
  margin: 0 auto 80px; }
  @media screen and (max-width: 767px) {
    .en .en-menu {
      width: calc(100% - 40px);
      margin-bottom: 40px; } }
  .en .en-menu ul {
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px; }
    @media screen and (max-width: 767px) {
      .en .en-menu ul {
        flex-wrap: wrap; } }
  .en .en-menu li {
    width: 30%;
    text-align: center;
    background: #eee;
    font-size: 18px;
    font-weight: bold;
    background: #00b9ef;
    border-radius: 6px;
    border: solid 1px #00b9ef; }
    @media screen and (max-width: 767px) {
      .en .en-menu li {
        width: 100%;
        margin-bottom: 10px; } }
    .en .en-menu li a {
      display: block;
      color: #fff;
      padding: 30px;
      box-sizing: border-box; }
      @media screen and (max-width: 767px) {
        .en .en-menu li a {
          padding: 20px; } }
      .en .en-menu li a:hover {
        text-decoration: none; }
  .en .en-menu .jp {
    text-align: center; }

.en .sec {
  padding: 50px 0;
  margin: 0 auto; }
  @media screen and (max-width: 767px) {
    .en .sec {
      padding: 25px 0; } }

.en .intro {
  text-align: left;
  text-justify: inherit; }

.en .list li {
  margin-bottom: 20px;
  padding-left: 1em;
  text-indent: -1em; }
  .en .list li::before {
    content: "";
    width: 10px;
    height: 10px;
    display: inline-block;
    background-color: #bfbfbf;
    border-radius: 50%;
    margin: 0 4px; }
  .en .list li a {
    text-indent: 0; }

.en .list-s li {
  position: relative;
  padding-left: 1em; }
  .en .list-s li::before {
    position: absolute;
    top: 8px;
    left: 0;
    content: "";
    width: 8px;
    height: 8px;
    display: inline-block;
    background-color: #aadeed;
    border-radius: 50%;
    margin: 0 4px; }
  .en .list-s li a {
    text-indent: 0; }

.en .listbox {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between; }
  .en .listbox li {
    margin-bottom: 1.25%;
    padding: 20px;
    box-sizing: border-box;
    font-size: 14px;
    color: #00b9ef;
    font-weight: bold;
    width: 19%;
    background: #edf6fc;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center; }
    @media screen and (max-width: 767px) {
      .en .listbox li {
        width: 49%;
        margin-bottom: 2%; } }

.en .text-area {
  width: 860px;
  margin: 0 auto 50px;
  text-align: left;
  text-justify: inherit; }
  @media screen and (max-width: 767px) {
    .en .text-area {
      width: calc(100% - 40px);
      margin: 0 auto 25px; } }
  .en .text-area:last-child {
    margin: 0 auto; }
  .en .text-area .wrap {
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px; }
    @media screen and (max-width: 767px) {
      .en .text-area .wrap {
        flex-direction: column; } }
    .en .text-area .wrap .logo-btn {
      width: 250px;
      background: #fff;
      border: solid 2px #00b9ef;
      border-radius: 8px;
      font-weight: bold;
      color: #00b9ef;
      box-shadow: 0 2px 4px rgba(102, 102, 102, 0.2);
      box-sizing: border-box;
      padding: 10px; }
      @media screen and (max-width: 767px) {
        .en .text-area .wrap .logo-btn {
          margin-bottom: 10px;
          width: 100%; } }
      .en .text-area .wrap .logo-btn a {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center; }
    .en .text-area .wrap .text {
      width: 580px; }
      @media screen and (max-width: 767px) {
        .en .text-area .wrap .text {
          width: 100%; } }
  .en .text-area__ttl {
    padding-left: 18px;
    box-sizing: border-box;
    margin-bottom: 30px;
    font-size: 24px;
    font-weight: bold;
    color: #00b9ef;
    line-height: 1.4;
    border-left: solid 4px #b5b5b5; }
    @media screen and (max-width: 767px) {
      .en .text-area__ttl {
        margin-bottom: 25px;
        font-size: 16px;
        padding-left: 10px;
        border-left: solid 3px #b5b5b5; } }
  .en .text-area__detail {
    margin-bottom: 1em;
    font-size: 14px;
    line-height: 1.6; }
    .en .text-area__detail:last-child {
      margin-bottom: 0; }
    .en .text-area__detail.highlight {
      color: #00b9ef;
      font-size: 16px;
      font-weight: bold; }
    .en .text-area__detail a {
      color: #00b9ef; }
      .en .text-area__detail a.blank {
        display: inline-block; }
        .en .text-area__detail a.blank::after {
          width: 15px;
          height: 14px;
          content: url(../img/company/link.png);
          display: inline-block;
          margin: 0 8px;
          font-size: 0; }

.en .table {
  width: 860px;
  margin: 0 auto; }
  @media screen and (max-width: 767px) {
    .en .table {
      width: calc(100% - 40px);
      display: block; } }
  .en .table th {
    padding: 30px 0;
    font-size: 16px;
    width: 200px;
    font-weight: bold;
    vertical-align: top;
    border-bottom: 1px solid #e5e5e5; }
    @media screen and (max-width: 767px) {
      .en .table th {
        width: 100%;
        display: block;
        border: none;
        padding: 20px 0; } }
  .en .table td {
    line-height: 1.6;
    vertical-align: top;
    padding: 30px 0;
    font-size: 16px;
    border-bottom: 1px solid #e5e5e5; }
    @media screen and (max-width: 767px) {
      .en .table td {
        width: 100%;
        display: block;
        padding: 0 0 20px; } }
    .en .table td a {
      color: #00b9ef; }
    .en .table td .map {
      margin-bottom: 30px;
      font-size: 14px;
      color: #fff;
      width: auto; }
      .en .table td .map::after {
        left: inherit;
        right: -25px;
        top: 0;
        bottom: 0;
        margin: auto;
        font-size: 0; }
      .en .table td .map:hover {
        text-decoration: none; }
    .en .table td .mb15 {
      margin-bottom: 15px; }

.en .service li {
  margin-bottom: 20px;
  font-size: 16px;
  font-weight: bold; }
  .en .service li li {
    margin-bottom: 0px; }
  .en .service li a {
    font-size: 14px;
    font-weight: normal; }

.en.contact .select {
  overflow: hidden;
  width: 100%;
  margin: 0 auto 1em;
  text-align: center;
  box-sizing: border-box; }
  .en.contact .select select {
    width: 100%;
    cursor: pointer;
    text-indent: 0.01px;
    font-size: 16px;
    text-overflow: ellipsis;
    border: none;
    outline: none;
    background: transparent;
    background-image: none;
    box-shadow: none;
    -webkit-appearance: none;
    appearance: none;
    padding: 16px 38px 16px 16px;
    box-sizing: border-box;
    color: #666; }
    .en.contact .select select::-ms-expand {
      display: none; }
  .en.contact .select.select01 {
    position: relative;
    border: 1px solid #bbbbbb;
    border-radius: 3px;
    background: #ffffff; }
    .en.contact .select.select01::before {
      position: absolute;
      top: 1.4em;
      right: 0.9em;
      width: 0;
      height: 0;
      padding: 0;
      content: '';
      border-left: 6px solid transparent;
      border-right: 6px solid transparent;
      border-top: 6px solid #666666;
      pointer-events: none; }

.en.contact .contact-table .telbox input {
  width: 100%; }

.seminar {
  text-align: left; }
  .seminar .kv {
    width: 100%;
    height: 150px;
    background: #006098; }
  .seminar .sec {
    margin-bottom: 50px; }
    @media screen and (max-width: 767px) {
      .seminar .sec {
        margin-bottom: 25px; } }
  .seminar .pd-wrap {
    padding: 50px 0; }
    @media screen and (max-width: 767px) {
      .seminar .pd-wrap {
        padding: 25px 0; } }
  .seminar .mainInner {
    border-bottom: solid 1px #ccc; }
    .seminar .mainInner a {
      color: #00b9ef; }
    .seminar .mainInner p {
      margin-bottom: 30px;
      font-size: 16px;
      line-height: 1.6; }
      @media screen and (max-width: 767px) {
        .seminar .mainInner p {
          margin-bottom: 20px;
          font-size: 14px; } }
  .seminar .fs-big {
    font-size: 18px; }
    @media screen and (max-width: 767px) {
      .seminar .fs-big {
        font-size: 16px; } }
  .seminar .fw-bold {
    font-weight: bold; }
  .seminar .ta-center {
    text-align: center; }
  .seminar .txtNrml {
    font-size: 12px; }
  .seminar .imgBox img {
    display: block;
    width: 100%; }
  .seminar .ttlCopy02 {
    margin-bottom: 30px;
    font-size: 24px;
    line-height: 1.4;
    color: #333;
    padding-left: 19px;
    box-sizing: border-box;
    border-left: solid 6px #00b9ef; }
    @media screen and (max-width: 767px) {
      .seminar .ttlCopy02 {
        margin-bottom: 25px;
        font-size: 16px;
        padding-left: 10px;
        border-left: solid 3px #00b9ef; } }
  .seminar .boderwrapBlue p:last-child {
    margin-bottom: 0; }
  .seminar .boderwrapBlue .imgBox img {
    display: block;
    max-width: 600px;
    margin: 20px auto; }
  .seminar .txtStrngBlue {
    margin-bottom: 30px;
    padding: 10px 20px;
    color: #fff;
    font-size: 18px;
    background: #00b9ef; }
    @media screen and (max-width: 767px) {
      .seminar .txtStrngBlue {
        margin-bottom: 20px;
        font-size: 14px; } }
    .seminar .txtStrngBlue--tag {
      display: inline-block;
      margin: 0 10px 0 0;
      padding: 5px 10px;
      font-size: 14px; }
      @media screen and (max-width: 767px) {
        .seminar .txtStrngBlue--tag {
          font-size: 12px; } }
  .seminar .flexbox {
    margin-bottom: 30px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between; }
    @media screen and (max-width: 767px) {
      .seminar .flexbox {
        flex-direction: column; } }
    @media screen and (max-width: 767px) {
      .seminar .flexbox .list {
        margin-bottom: 20px; } }
    .seminar .flexbox .list li {
      position: relative;
      padding-left: 1em;
      font-size: 16px;
      margin-bottom: 20px;
      line-height: 1.6; }
      @media screen and (max-width: 767px) {
        .seminar .flexbox .list li {
          margin-bottom: 10px;
          font-size: 14px; } }
      .seminar .flexbox .list li::before {
        position: absolute;
        content: "";
        width: 10px;
        height: 10px;
        left: 0;
        top: 6px;
        display: inline-block;
        background-color: #00b9ef;
        border-radius: 50%; }
      .seminar .flexbox .list li:last-child {
        margin-bottom: 0; }
    .seminar .flexbox .imgBox {
      width: 180px;
      padding: 0 20px;
      box-sizing: border-box; }
      @media screen and (max-width: 767px) {
        .seminar .flexbox .imgBox {
          margin: 0 auto; } }
      .seminar .flexbox .imgBox img {
        width: 100%; }
  .seminar .lineTable01 {
    width: 100%;
    background: #fff;
    margin-bottom: 20px; }
    .seminar .lineTable01.end {
      position: relative; }
      .seminar .lineTable01.end::after {
        display: flex;
        align-items: center;
        justify-content: center;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        position: absolute;
        content: "終了しました。";
        color: #fff;
        background: rgba(0, 0, 0, 0.5);
        font-size: 20px; }
    .seminar .lineTable01__ttl {
      padding: 30px;
      font-size: 20px;
      font-weight: bold;
      border-bottom: solid 1px #ccc;
      vertical-align: middle; }
      @media screen and (max-width: 767px) {
        .seminar .lineTable01__ttl {
          display: block;
          padding: 20px 20px 0;
          border-bottom: none;
          font-size: 14px; } }
    .seminar .lineTable01__text {
      padding: 20px 20px 20px 0;
      font-size: 16px;
      line-height: 1.6;
      border-bottom: solid 1px #ccc;
      vertical-align: middle; }
      @media screen and (max-width: 767px) {
        .seminar .lineTable01__text {
          display: block;
          padding: 20px;
          font-size: 14px; } }
  .seminar .contactBtn a {
    -webkit-appearance: none;
    display: block;
    border-radius: 3px;
    border: none;
    background-color: #ff3574;
    box-shadow: 0 3px #d02d60;
    width: 40%;
    height: auto;
    padding: 10px 0;
    margin: 0 auto;
    font-size: 115%;
    color: #fff;
    font-weight: bold;
    text-align: center;
    letter-spacing: 0.05em;
    cursor: pointer; }
    @media screen and (max-width: 767px) {
      .seminar .contactBtn a {
        width: 80%; } }
