@charset "UTF-8";

.casestudy {
  .kv {
    &--lower {
      margin-bottom: 0;
    }
  }
  .case {
    padding: 50px 0;
    @include sp {
      padding: 25px 0;
    }
    &--lower {
      padding: 0;
    }
    &__ttl {
      margin-bottom: 30px;
      padding: 13px;
      font-size: 24px;
      font-weight: bold;
      color: #fff;
      background: #00b9ef;
      text-align: center;
      @include sp {
        margin-bottom: 15px;
        padding: 10px;
        font-size: 16px;
        line-height: 1.3;
      }
    }
    &__cat {
      width: 100%;
      text-align:center;
      vertical-align: bottom;
      color: #00b9ef;
      font-size: 24px;
      font-weight: bold;
      border: 1px solid #00b9ef;
      margin-bottom: 30px;
      padding: 9px 0 7px;
      @include sp {
        font-size: 16px;
        padding: 14px 0 14px;
      }
    }
    &__block {
      margin-bottom: 80px;
      display: flex;
      justify-content: space-between;
      @include sp {
        margin-bottom: 40px;
        flex-direction: column;
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
    &__img {
      width: 430px;
      @include sp {
        width: 100%;
        margin-bottom: 15px;
      }
      img {
        display: block;
        width: 100%;
      }
    }
    &__text-area {
      width: 400px;
      @include sp {
        width: 100%;
      }
    }
    &__text-ttl {
      margin-bottom: 25px;
      font-size: 25px;
      font-weight: bold;
      color: #00b9ef;
      line-height: 1.4;
      font-feature-settings : "palt";
      @include sp {
        text-align: center;
        margin-bottom: 15px;
        font-size: 20px;
      }
    }
    &__text-detail {
      margin-bottom: 40px;
      font-size: 16px;
      line-height: 1.6;
      @include sp {
        margin-bottom: 20px;
      }
    }
    &__text-btn {
      text-align: right;
    }
    &__detail{
      font-size: 16px;
      line-height: 1.6;
      dt {
        width: 6em;
        display: inline-block;
      }
      dd {
        display: inline-block;
      }
    }
  }
  .profile {
    margin-bottom: 50px;
    display: flex;
    justify-content: space-between;
    @include sp {
      margin-bottom: 25px;
      flex-direction: column;
    }
    &--vertical {
      @include pc {
        width: 560px;
        margin: 0 auto 50px;
      }
    }
    &__img {
      width: 50%;
      @include sp {
        width: 100%;
      }
      img {
        display: block;
        width: 100%;
      }
    }
    &__text {
      display: flex;
      align-items: flex-end;
      width: 50%;
      padding: 30px;
      box-sizing: border-box;
      color: #fff;
      background: #00b9ef;
      @include sp {
        width: 100%;
        padding: 15px 20px;
      }
    }
    &__position {
      font-size: 16px;
      line-height: 1.6;
      margin-bottom: 25px;
      @include sp {
        margin-bottom: 15px;
      }
    }
    &__name {
      font-size: 18px;
      @include sp {
        font-size: 16px;
      }
    }
  }
  .casestudy-block {
    margin-bottom: 50px;
    @include sp {
      margin-bottom: 25px;
    }
    &:last-child {
      margin-bottom: 0;
    }
    &--2 {
      display: flex;
      justify-content: space-between;
      @include sp {
        flex-direction: column;
      }
    }
    &__img {
      width: 430px;
      text-align: center;
      @include sp {
        width: 100%;
        margin-bottom: 25px;
        img {
          width: 100%;
        }
        &--1 {
          width: 200px;
          margin: 0 auto 25px;
        }
      }
      &--2 {
        box-sizing: border-box;
        padding-left: 30px;
        text-align: left;
        @include sp {
          margin: 25px auto 0;
          padding: 0;
          display: flex;
          justify-content: space-between;
        }
        .img {
          margin-bottom: 30px;
          @include sp {
            margin-bottom: 0;
            width: 48%;
            img {
              width: 100%;
            }
          }
        }
      }
    }
    &__text-area {
      width: 400px;
      @include sp {
        width: 100%;
      }
    }
    &__ttl {
      margin-bottom: 30px;
      font-size: 24px;
      line-height: 1.4;
      color: #00b9ef;
      padding-left: 19px;
      box-sizing: border-box;
      border-left: solid 4px #b5b5b5;
      @include sp {
        margin-bottom: 25px;
        font-size: 16px;
        padding-left: 10px;
        border-left: solid 3px #b5b5b5;
      }
    }
    &__text {
      font-size: 16px;
      line-height: 1.6;
    }
  }
  .casestudy-photo-list {
    width: 550px;
    margin: 0 auto 50px;
    padding-top: 30px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    font-size: 0;
    @include sp {
      width: 100%;
      padding: 0;
      margin: 0 auto 25px;
    }
    li {
      margin-bottom: 30px;
      @include sp {
        width: 48%;
        margin-bottom: 4%;
        &:nth-child(3),
        &:nth-child(4) {
          margin-bottom: 0;
        }
        img {
          width: 100%;
        }
      }
    }
  }
}


