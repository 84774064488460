@charset "UTF-8";

.bigdata {
  .data-wrap {
    padding: 50px 0;
    width: 860px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    @include sp {
      padding: 25px 0;
      width: calc(100% - 40px);
    }
  }
  .data {
    width: 400px;
    margin-bottom: 70px;
    @include sp {
      width: 100%;
      margin-bottom: 50px;
    }
    @include pc {
      &:nth-child(3),
      &:nth-child(4) {
        margin-bottom: 0;
      }
    }
    @include sp {
      &:nth-child(4) {
        margin-bottom: 0;
      }
    }
    &__ttl {
      margin-bottom: 50px;
      line-height: 50px;
      font-size: 22px;
      font-weight: bold;
      color: #fff;
      background: #00b9ef;
      text-align: center;
      letter-spacing: 0.1em;
      @include sp {
        margin-bottom: 25px;
        font-size: 16px;
        line-height: 30px;
      }
    }
    &__img {
      width: 380px;
      margin: 0 auto 30px;
      @include sp {
        width: 100%;
        margin: 0 auto 18px;
      }
      img {
        width: 100%;
      }
    }
    &__cap {
      display: block;
      text-align: right;
      margin-top: 30px;
      font-size: 12px;
      color: #00b9ef;
      @include sp {
        margin-top: 18px;
      }
    }
    &__text {
      font-size: 16px;
      line-height: 1.6;
      letter-spacing: 0.05em;
    }
  }
}


