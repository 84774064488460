@charset "UTF-8";

/*privacy_policy*/
.privacy_policy , .security_policy , .product_security {
  .main {
    padding-top: 55px;
    .bg-gray {
      background-color: #eee;
      padding-bottom: 5px;
    }
    .intro {
      width: 700px;
      @include sp {
        width: calc(100% - 40px);
      }
    }
  }
  .contents {
    width: 700px;
    margin: 0 auto 45px;
    background-color: #ffffff;
    @include sp {
      width: calc(100% - 40px);
    }
    &__blocks {
      padding: 50px 50px 20px 50px;
      margin-bottom: 50px;
      @include sp {
      padding: 25px 20px 0px 20px;
      margin-bottom: -25px;
      }
    }
    &__block {
      line-height: 22px;
    }
    &__block{
      p , ul , h2 {
        padding-bottom: 25px;
      }
      p , ul{
        font-size: 16px;
      }
      h2 {
        font-size: 18px;
      }
      .bold {
        font-weight: bold;
      }
    }
    &__block--lightblue {
      color: #00b9ef;
    }
    &__block ul li {
      text-indent: -1em;
      padding-left: 1em;
    }
    &__block ul li:before {
      content: "●";
      color: #bae3f9;
      font-size: 10px;
      padding-right: 5px;
    }
  }
  .low-wrap {
    width: 860px;
    margin: 0 auto;
    @include sp {
      width: calc(100% - 40px);
    }
    .btn.margin {
      margin-bottom: 30px;
      @include sp {
        margin-bottom: 25px;
      }
    }
  }
  .btn--light-blue {
    color: #00b9ef !important;
    border: solid 1px #00b9ef !important;
    width: 260px;
    text-align: center;
    font-weight: bold;
    @include sp {
    width: 220px;
    padding-right: 9%;
    padding-left: 1%;
    font-weight: bold;
    }
  }
  .btn--light-blue::before, .btn--light-blue::after {
      background: #00b9ef;
  }
}

.security_policy .main .contents {
  &__0 {
    h2 {
      color: #333;
    }
    img {
    display: none;
    @include sp {
      display: inline;
      width: 40%;
      padding-bottom: 5%;
    }
  }

  }
  &__1 p {
    border-bottom: solid 2px #eee;
    margin-bottom: 5%;
  @include sp {
    margin-bottom: 25px;
  }
  }
}
.product_security .main .contents {
  &__1 {
    @include sp {
      padding-top: 0px !important;
      border-top: none !important;
    }
  }
  &__block {
    border-top: solid 2px #eee;
    padding-top: 25px;
    a {
      color: #00b9ef;
        }
    h2 {
      @include sp {
        color: #333;
      }
    }
  }
  &__0{
    border-top: none;
    padding-top: 0%;
  }

}
/*sitemap*/
.sitemap {
  .main {
    padding-top: 55px;
    .bg-gray {
      background-color: #eee;
      padding-bottom: 50px;
      @include sp {
        padding-bottom: 25px;
      }
    }
    .intro {
      width: 800px;
      padding-top: 50px;
      @include sp {
        width: calc(100% - 40px);
        padding-top: 25px;
      }
    }
  }
  .contents {
    width: 800px;
    margin: 0 auto;
    background-color: #ffffff;
    overflow: hidden;
    @include sp {
      width: calc(100% - 40px);
    }
    &__blocks {
      padding: 0px 50px 0px 50px;
      @include sp {
      padding: 0px 20px 25px 20px;
      margin-bottom: -25px;
      }
    }
    &__block {
      line-height: 22px;
      border-bottom: 1px solid #eee;
      @include sp {
        border-bottom: none;
      }
    }
    &__block.last {
      line-height: 22px;
      border-bottom: none;
    }
    &__block {
      p , ul , h2 {
        padding-bottom: 25px;
      }
      ul {
        @include sp {
          padding-bottom: 10px;
        }
      }
      p , ul{
        font-size: 16px;
      }
      h2 {
        font-size: 18px;
      }
      .bold {
        font-weight: bold;
      }
    }
    &__block--lightblue {
      color: #00b9ef;
    }
    &__block ul li {
      padding-left: 10px;
      @include sp {
        font-size: 14px;
      }

    }
    &__block ul li img {
      width: 5px;
      padding-right: 5px;
    }
    &__block ul li:before {
      /*content: "＞";
      font-weight: bold;
      color: #00b9ef;
      font-size: 12px;
      padding-right: 5px;*/
    }
  }
  .content {
    margin-top: 50px;
    @include sp {
      margin-top: 0px;
    }
    &__rightbox , &__leftbox h2 {
      @include sp {
        padding-top: 25px;
      }
    }
    &__rightbox {
      float: left;
      width: 300px;
      padding-left: 50px;
      @include sp {
        float: none;
        padding-left: 0px;
        border-bottom: 1px solid #eee;
        width: 100%;
      }

    }
    &__leftbox {
      float: left;
      width: 350px;
      @include sp {
        float: none;
        border-bottom: 1px solid #eee;
        width: 100%;
      }

    }
    &__leftbox.last h2:first-child {
      padding-bottom: 50px;
      border-bottom: 1px solid #eee;
      margin-bottom: 50px;
      @include sp {
        padding-bottom: 25px;
        border-bottom: none;
        margin-bottom: 0px;
      }

    }
    li {
      padding-bottom: 20px;
      @include sp {
        padding-bottom: 15px;
      }
    }
    .clear {
      clear: both;
    }
  }
}
